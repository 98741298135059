import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../context";

export default function useRouterTracker(from, urlStringName) {
  const location = useLocation();
  const { dispatchRouter } = useGlobalContext();

  const currentUrlString = location.pathname + location.search;
  // const urlStringRef = useRef(currentUrlString);

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from, [urlStringName]: currentUrlString },
    });
  }, [currentUrlString]);

  // useEffect(() => {
  //   urlStringRef.current = currentUrlString;
  // }, [currentUrlString]);

  return null;
}
