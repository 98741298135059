import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { useField } from "formik";

export default function FormikAutocomplete({
  name,
  label,
  options,
  onChange,
  ...props
}) {
  const [field, meta] = useField(name);
  const errorText = meta.touched && meta.error;

  const value = options.find((option) => option.value === field.value) || null;

  function handleChange(e, value) {
    // console.log("value from FormikAutocomplete handleChange", value);
    const newValue = value?.value || null;
    field.onChange({
      target: {
        name: field.name,
        value: newValue,
      },
    });
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <Box sx={{ width: "100%", border: "none" }}>
      <form noValidate autoComplete="off">
        <FormControl sx={{ border: "none", width: "100%" }}>
          <Autocomplete
            fullWidth
            openOnFocus
            blurOnSelect
            autoHighlight
            disableListWrap
            clearIcon={null}
            value={value}
            onBlur={field.onBlur}
            onChange={handleChange}
            noOptionsText="Nėra pasiūlymų"
            options={options}
            ListboxProps={{ sx: { maxHeight: "10rem" } }}
            getOptionLabel={(option) => {
              return option?.label || null;
            }}
            isOptionEqualToValue={(option, value) => {
              return value.value === option.value;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  error={!!errorText}
                  helperText={errorText}
                  name={name}
                  {...params}
                  label={label}
                  margin="dense"
                />
              );
            }}
            {...props}
          />
        </FormControl>
      </form>
    </Box>
  );
}
