import { useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export default function InvoiceTemplate({
  invoiceData: invoice,
  setInvoiceData,
}) {
  const invoiceRef = useRef(null);

  //   const Container = styled(Paper)({
  //     maxWidth: "80%",
  //     margin: "auto",
  //     marginTop: 20,
  //   });

  //   const Container = styled("div")(() => ({
  //     position: "absolute",
  //     left: "-10000px",
  //     top: "-10000px",
  //     border: "solid",
  //     className: "invoice-print",
  //     // Print styles
  //     "@media print": {
  //       color: "black",
  //       backgroundColor: "white",
  //     },
  //   }));

  // const Header = styled(Box)({
  //   background: "#f0f0f0",
  //   padding: 10,
  // });

  // const InfoContainer = styled(Box)({
  //   display: "flex",
  //   justifyContent: "space-between",
  //   marginBottom: 20,
  // });

  // const InfoBox = styled(Box)({
  //   flex: 1,
  //   padding: 10,
  // });

  const Footer = styled(Typography)({
    marginTop: 20,
    fontSize: "0.8em",
  });

  useEffect(() => {
    if (!!invoice && !!invoiceRef.current) {
      handleDownloadPDF();
      setInvoiceData(null);
    }
  }, [invoice, invoiceRef.current]);

  function handleDownloadPDF() {
    html2canvas(invoiceRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`invoice_${invoice.id}.pdf`);
    });
  }

  return (
    <Box
      //   maxWidth="md"
      ref={invoiceRef}
      sx={{
        position: "absolute",
        left: "-10000px",
        top: "-10000px",
        width: "210mm", // Set the  width to match A4 width
        height: "297mm", // Set the height to match A4 height
        // border: "solid 1px",
        overflow: "visible",
        padding: "1.5cm",
        // className: "invoice-print",
        // "@media print": {
        //   border: "solid 1px",
        //   border: "solid red 7px",
        //   position: "static",
        //   width: "100%", // Use the full width of the page
        //   maxWidth: "210mm", // Set the maximum width to match A4 width
        //   height: "297mm", // Set the height to match A4 height
        //   overflow: "hidden",
        //   margin: "0 auto", // Center the content horizontally
        //   boxSizing: "border-box", // Include padding and border in the element's total width and height
        // },
      }}
    >
      <Box
        sx={{
          //  background: grey[100],
          py: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Typography variant="h5">
              Sąskaita-faktūra Nr. {invoice.invoice_number}
            </Typography>
            <Typography>
              Data: {new Date(invoice.created_at).toLocaleDateString("lt-LT")}
            </Typography>
          </Stack>
          <Box component="img" src="/logo.png" sx={{ height: "48px" }} />
        </Box>
      </Box>
      <Box>
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <Stack>
              <Typography variant="h6">Pardavėjo informacija</Typography>
              <Typography>{invoice?.our_company_info?.company_name}</Typography>
              <Typography>
                {invoice?.our_company_info?.company_address}
              </Typography>
              <Typography>
                Įm. kodas: {invoice?.our_company_info?.company_code}
              </Typography>
              <Typography>
                Tel.: {invoice?.our_company_info?.company_phone}
              </Typography>
              <Typography>
                El. p.: {invoice?.our_company_info?.company_email}
              </Typography>
            </Stack>
          </Grid>
          {invoice?.payment?.client_info?.client_type === "enterprise" && (
            <Grid item xs={6}>
              <Stack>
                <Typography variant="h6">Kliento informacija</Typography>
                <Typography>
                  {invoice.payment.client_info.client_name}
                </Typography>
                <Typography>
                  {invoice.payment.client_info.client_address}
                </Typography>
                <Typography>
                  Įm. kodas: {invoice.payment.client_info.company_code}
                </Typography>
                {!!invoice?.payment?.client_info?.client_phone && (
                  <Typography>
                    Tel.: {invoice.payment.client_info.client_phone}
                  </Typography>
                )}
                {!!invoice?.payment?.client_info?.client_email && (
                  <Typography>
                    El. p.: {invoice.payment.client_info.client_email}
                  </Typography>
                )}
              </Stack>
            </Grid>
          )}
          {invoice?.payment?.client_info?.client_type === "private" && (
            <Grid item xs={6}>
              <Stack>
                <Typography variant="h6">Kliento informacija</Typography>
                <Typography>
                  {invoice.payment.client_info.client_name}
                </Typography>
                <Typography>
                  {invoice.payment.client_info.client_address}
                </Typography>
                {!!invoice?.payment?.client_info?.client_phone && (
                  <Typography>
                    Tel.: {invoice.payment.client_info.client_phone}
                  </Typography>
                )}
                {!!invoice?.payment?.client_info?.client_email && (
                  <Typography>
                    El. p.: {invoice.payment.client_info.client_email}
                  </Typography>
                )}
              </Stack>
            </Grid>
          )}
          {!invoice?.payment?.client_info?.client_type && (
            <Grid item xs={6}>
              <Stack>
                <Typography variant="h6">Kliento informacija</Typography>
                <Typography>Kliento ID: {invoice.user}</Typography>
                <Typography>El. p.: {invoice.email}</Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Table sx={{ mt: "50px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Paslaugos aprašymas</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Trukmė (d.)</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Suma (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {invoice.payment.description}. Paslauga baigia galioti{" "}
                {new Date(invoice.payment.ad_expires_at).toLocaleDateString(
                  "lt-LT"
                )}
                . Skelbimo ID: {invoice.payment.ad}. {invoice.payment.ad_title},{" "}
                {invoice.payment.ad_subtitle}.
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {invoice.payment.duration}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {invoice.payment.amount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Footer>Ačiū, kad naudojatės mūsų paslaugomis!</Footer>
    </Box>
  );
}
