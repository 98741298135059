import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./popup.css";
import "./cluster.css";
import { FeatureGroup, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import { useGlobalContext } from "../../../context";
import customIconUrl from "../../../static/icons/dot.png";
import selectedIconUrl from "../../../static/icons/selected_dot.png";
import PopupContent from "./PopupContent";

export const customIcon = L.icon({
  iconUrl: customIconUrl,
  iconSize: [19, 19],
  iconAnchor: [10, 10],
});

export const selectedIcon = L.icon({
  iconUrl: selectedIconUrl,
  iconSize: [19, 19],
  iconAnchor: [10, 10],
});

const clusterIcon = L.icon({
  iconUrl: selectedIconUrl,
  iconSize: [1, 1],
  iconAnchor: [1, 1],
});

function createClusterCustomIcon(cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "marker-cluster custom-cluster-icon",
    iconSize: L.point(33, 33, true),
  });
}

function AdGroup() {
  const { state, dispatch, routerState, dispatchRouter } = useGlobalContext();
  const adGroupRef = useRef(null);
  const markerRef = useRef(null);
  const map = useMap();
  const clusterMarkerRef = useRef(null);
  const [childMarkerIds, setChildMarkerIds] = useState([]);
  const [clusterPosition, setClusterPosition] = useState(null);
  const [showClusterPopup, setShowClusterPopup] = useState(false);

  // const adCardsIds = state.adCards.map((ad) => ad.id).toString();
  const clusterAds = state.adCards.filter((ad) =>
    childMarkerIds.includes(ad.id)
  );

  useEffect(() => {
    return () => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adBounds: null },
      });
    };
  }, []);

  // console.log("adGroupRef", adGroupRef.current, "state.adCards", state.adCards);
  // get bounds of ad group
  useEffect(() => {
    if (!!adGroupRef.current && !!state.adCards.length) {
      let adBounds = adGroupRef.current.getBounds();
      // console.log("adBounds from AdGroup", adBounds.toBBoxString());
      // dealing with leaflet bug of mixing lat and lng coords
      let northEastObj = {
        lat: Math.max(...Object.values(adBounds._northEast)),
        lng: Math.min(...Object.values(adBounds._northEast)),
      };
      let southWestObj = {
        lat: Math.max(...Object.values(adBounds._southWest)),
        lng: Math.min(...Object.values(adBounds._southWest)),
      };
      adBounds._northEast = northEastObj;
      adBounds._southWest = southWestObj;
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adBounds },
      });
    }
  }, [state.adCards, dispatch]);

  // show popup when when user hovers over ad in ad list
  useEffect(() => {
    if (!!markerRef.current) {
      // console.log("markerPopupOpen from AdGroup", state.markerPopupOpen, marker);
      !!routerState.markerPopupOpen
        ? markerRef.current.openPopup()
        : markerRef.current.closePopup();
    }
  }, [routerState.markerPopupOpen, markerRef]);

  // show cluster popup, when user clicks on cluster and popup position is determined
  useEffect(() => {
    if (showClusterPopup && !!clusterMarkerRef.current) {
      clusterMarkerRef.current.openPopup();
      setShowClusterPopup(false);
    }
  }, [showClusterPopup, clusterMarkerRef]);

  // get cluster popup ready for display
  function handleClusterClick(e) {
    if (!!map && map.getZoom() === map.getMaxZoom()) {
      let childMarkerIds = e.layer
        .getAllChildMarkers()
        .map((ad) => ad.options.id);
      dispatchRouter({
        type: "CHANGE_ROUTER_STATE",
        payload: { hoverOverAd: childMarkerIds[0] },
      });
      setChildMarkerIds(childMarkerIds);
      setClusterPosition(e.latlng);
      setShowClusterPopup(true);
    }
  }

  // console.log("clusterPosition", clusterPosition, "clusterAds", clusterAds);

  return (
    <>
      <FeatureGroup ref={adGroupRef}>
        <MarkerClusterGroup
          chunkedLoading
          spiderfyOnMaxZoom={false}
          maxClusterRadius={30}
          onClick={handleClusterClick}
          iconCreateFunction={createClusterCustomIcon}
        >
          {state.adCards.map((ad) => {
            let position = {
              lat: Math.max(...ad.geometry.coordinates),
              lng: Math.min(...ad.geometry.coordinates),
            };
            return (
              <Marker
                id={ad.id}
                key={"id_" + ad.id}
                position={position}
                clickable={true}
                icon={customIcon}
                zIndexOffset={100}
                eventHandlers={{
                  click: (e) => {
                    dispatchRouter({
                      type: "CHANGE_ROUTER_STATE",
                      payload: { mouseOverAd: null, hoverOverAd: ad.id },
                    });
                  },
                }}
              />
            );
          })}
        </MarkerClusterGroup>
      </FeatureGroup>

      {/* show red dot and popup either on hover over ad list or on ad click on the map */}
      {state.adCards
        .filter((ad) =>
          [routerState.hoverOverAd, routerState.mouseOverAd].includes(ad.id)
        )
        .map((ad) => {
          let position = {
            lat: Math.max(...ad.geometry.coordinates),
            lng: Math.min(...ad.geometry.coordinates),
          };
          return (
            <Marker
              ref={markerRef}
              key={ad.id}
              position={position}
              clickable={true}
              icon={selectedIcon}
              zIndexOffset={1000}
              eventHandlers={{
                mouseover: (e) => {
                  dispatchRouter({
                    type: "CHANGE_ROUTER_STATE",
                    payload: {
                      mouseOverAd: null,
                      hoverOverAd: ad.id,
                      markerPopupOpen: ad.id,
                    },
                  });
                  // e.target.openPopup();
                },
              }}
            >
              <Popup
                minWidth={240}
                maxWidth={240}
                closeButton={false}
                closeOnClick={true}
                style={{ margin: 0 }}
                className="custom-popup"
                autoPan={false}
              >
                <PopupContent adArray={[ad]} />
              </Popup>
            </Marker>
          );
        })}

      {/* show invisible marker and clustered popup when cluster clicked on max zoom*/}
      <>
        {!!clusterPosition && (
          <Marker
            ref={clusterMarkerRef}
            position={clusterPosition}
            icon={clusterIcon}
            zIndexOffset={1000}
          >
            {map.getZoom() === map.getMaxZoom() && !!clusterAds?.length && (
              <Popup
                minWidth={240}
                maxWidth={240}
                closeButton={false}
                closeOnClick={true}
                style={{ margin: 0 }}
                className="custom-popup"
                keepInView={true}
                autoPan={true}
              >
                <PopupContent adArray={clusterAds} />
              </Popup>
            )}
          </Marker>
        )}
      </>
    </>
  );
}

export default AdGroup;
