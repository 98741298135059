import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import * as Yup from "yup";
// import FormikTextfield from "../../../utils/FormikTextfield";
import { useGlobalContext } from "../../../context";
import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { submitInitialEnquiry } from "../fetchOrSendAdData";
// import { listCreateEnquiryMessage } from "../../Dashboard/fetchOrSendDashboardData";
import { useParams } from "react-router-dom";
import FormikTextField from "../../SharedComponents/FormikTextField";
// import FormPreview from "../../AdForm/utils/FormPreview";
import MessageBoard from "../../Dashboard/SharedComponents/MessageBoard";
import MessageBar from "../../Dashboard/SharedComponents/MessageBar";
import CustomAvatar from "../../Layout/CustomAvatar";
import { blue } from "@mui/material/colors";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input";
import CustomPhoneTextField from "../../AdForm/Step1AdInfo/OwnerDetailsPanels/CustomPhoneTextField";

export default function EnquiryForm() {
  const { state, userState, dispatchDashboard, dispatchUser, dashboardState } =
    useGlobalContext();
  const { adId, enquirerId } = useParams();
  const existingEnquiry = !!dashboardState.enquiries.filter(
    (enquiry) => enquiry.ad === +adId && enquiry.enquirer === +enquirerId
  ).length;
  const { isMobile, isMediumOrSmaller } = useCustomMediaQuery();
  const currentUser = userState.currentUser;
  const ad = state.ad;
  const [phoneValue, setPhoneValue] = useState(
    currentUser?.phone ? String(currentUser.phone) : ""
  );

  // let title;
  // switch (ad.properties.ad_type) {
  //   case "Parduodamas butas":
  //     title = "parduodamo buto";
  //     break;
  //   case "Nuomojamas butas":
  //     title = "nuomojamo buto";
  //     break;
  //   case "Parduodamas namas":
  //     title = "parduodamo namo";
  //     break;
  //   default:
  //     title = "skelbimo";
  // }

  // title = `Dėl ${title} (adresu ${ad.properties?.street?.name}
  //       ${ad.properties.house && ` ${ad.properties.house}`}, ${
  //   ad.properties.town.name
  // })`;

  const initialValues = {
    ad: +adId,
    enquirer: +enquirerId,
    message: "",
    name: currentUser?.name || "",
    phone: currentUser?.phone || "",
    email: currentUser?.email || "",
  };

  const validationSchema = Yup.object({
    message: Yup.string()
      .required("Privalomas laukas")
      .max(1000, "Maks. 1000 simbolių"),
    name: Yup.string()
      .required("Privalomas laukas")
      .max(100, "Maks. 100 simbolių"),
    // phone: Yup.string().max(50, "Maks. 50 simbolių"),
    phone: Yup.string()
      .max(20, "Maks. 20 simbolių")
      .test("is-valid-phone", "Netinkamas telefono numeris", (value) => {
        if (value && value.startsWith("+370")) {
          return value.length === 12;
        }
        return true;
      })
      .nullable(),
    email: Yup.string().email("Turi būti tinkamas el. pašto adresas"),
  });

  function handleSubmit(values) {
    submitInitialEnquiry(ad, values, dispatchDashboard, dispatchUser);
  }

  function handleCancel(resetForm) {
    // reset form
    resetForm();
  }

  function handlePhoneChange(value, formik) {
    const newPhoneValue = !!value ? value : "";
    setPhoneValue(newPhoneValue);
    formik.setFieldValue("phone", newPhoneValue);
  }

  // console.log("ad from EnquiryForm", ad);
  // console.log("existingEnquiry from enquiryForm", existingEnquiry);

  return (
    <>
      {existingEnquiry ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            height: "100%",
            border: "none",
          }}
        >
          <Stack
            direction="row"
            sx={{
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              backgroundColor: blue[100],
              // py: 0.5,
              // border: "groove",
            }}
          >
            <CustomAvatar
              userId={ad.properties.owner}
              name={ad.properties.name}
              styleProps={{
                margin: "4px",
                width: "35px",
                height: "35px",
                border: "solid 1px grey",
              }}
            />

            <CustomAvatar
              styleProps={{
                margin: "4px",
                width: "35px",
                height: "35px",
                border: "solid 1px grey",
              }}
            />
          </Stack>
          <MessageBoard />
          <MessageBar />
        </Box>
      ) : (
        <Box sx={{ px: 1, pt: 3, border: "none", height: "100%" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form autoComplete="off" style={{ height: "100%" }}>
                {/* <DialogContent> */}
                <Stack
                  justifyContent="space-between"
                  sx={{
                    border: "none",
                    // flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box sx={{ border: "none" }}>
                    <FormikTextField
                      name="message"
                      label="Žinutės tekstas *"
                      multiline={true}
                      rows={
                        isMediumOrSmaller
                          ? 10
                          : !!Object.keys(formik.errors).length
                          ? 6
                          : 7
                      }
                      inputProps={{
                        style: { fontSize: 14 },
                        spellCheck: false,
                      }}
                      autoFocus={true}
                    />
                    <Grid
                      container
                      spacing={1}
                      // alignItems="center"
                      sx={{ border: "none", mt: 1 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="name"
                          label="Vardas *"
                          variant="outlined"
                          sx={{
                            my: 2,
                            "& .MuiInputLabel-root": {
                              fontSize: 15,
                            },
                            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                              transform: "translate(14px, -6px) scale(0.75)",
                            },
                            "& .MuiInputBase-input": {
                              padding: "12px 14px",
                            },
                          }}
                          inputProps={{
                            style: { fontSize: 15, padding: "12px 14px" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ mb: isMobile ? 2 : 0 }}>
                        <Input
                          international
                          value={phoneValue || ""}
                          onChange={(value) => handlePhoneChange(value, formik)}
                          onBlur={() => formik.setFieldTouched("phone", true)}
                          // placeholder="Telefonas"
                          defaultCountry="LT"
                          countryCallingCodeEditable={false}
                          error={formik.touched.phone && formik.errors.phone}
                          inputComponent={CustomPhoneTextField}
                          variant="outlined"
                          label="Telefonas"
                          sx={{ my: isMobile ? 0 : 2 }}
                          inputProps={{
                            style: { fontSize: 15, padding: "12px 14px" },
                          }}
                        />
                        {/* <FormikTextField
                          name="phone"
                          label="Telefonas"
                          variant="standard"
                          sx={{ mt: 2 }}
                          inputProps={{ style: { fontSize: 14 } }}
                        /> */}
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <FormikTextField
                    name="email"
                    label="El. paštas"
                    variant="standard"
                    sx={{ mt: 2 }}
                    inputProps={{ style: { fontSize: 14 } }}
                  /> */}
                  <Grid container justifyContent="right" sx={{ mb: 1 }}>
                    <Button
                      onClick={() => handleCancel(formik.resetForm)}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      Atšaukti
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      size="small"
                      disabled={
                        formik.isSubmitting ||
                        !!Object.keys(formik.errors).length ||
                        !formik.dirty
                      }
                    >
                      Išsiųsti
                    </Button>
                  </Grid>
                </Stack>
                {/* <FormPreview /> */}
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </>
  );
}
