const storedUserState = sessionStorage.getItem("userState");
const anonymousUserState = {
  authenticationAttempted: false,
  isAuthenticated: false,
  currentUser: null,
  contactAvatars: [],
  snackbar: {
    open: false,
    message: "",
    vertical: "top",
    horizontal: "center",
    severity: "warning",
  },
};

export const initialUserState = storedUserState
  ? JSON.parse(storedUserState)
  : anonymousUserState;

export function userReducer(userState, action) {
  switch (action.type) {
    case "CHANGE_USER_STATE":
      let payload = action.payload;
      userState = { ...userState, ...payload };
      return userState;
    case "LOGOUT_USER":
      sessionStorage.removeItem("userState");
      sessionStorage.removeItem("adFormState");
      return { ...anonymousUserState, authenticationAttempted: true };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
