import React from "react";
import { FormControlLabel, Checkbox, Typography, Stack } from "@mui/material";
import { useField } from "formik";

export default function FormikCheckbox({ name, label, onChange, ...props }) {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error;

  function handleChange(e) {
    // console.log(
    //   "field value from FormikCheckBox",
    //   e.target.checked
    //   // field.value
    // );
    // console.log("checked value from FormikCheckBox", e.target.checked);
    helpers.setValue(e.target.checked);
    // helpers.setValue(!!e.target.checked || "");
    // field.onChange(e);
    if (!!onChange) {
      onChange(e);
    }
  }

  return (
    <Stack>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!field.value}
            name={name}
            onChange={handleChange}
            onBlur={field.onBlur}
          />
        }
        label={<Typography variant="body2">{label}</Typography>}
        labelPlacement="start"
        {...props}
      />
      {errorText && (
        <Typography
          variant="caption"
          color="error"
          textAlign="right"
          // sx={{ border: "groove" }}
        >
          {errorText}
        </Typography>
      )}
    </Stack>
  );
}
