import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useGlobalContext } from "../../context";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { resetPassword } from "./fetchOrSendAccountsData";
// import FormPreview from "../AdForm/utils/FormPreview";
import FormikTextField from "../SharedComponents/FormikTextField";
import { Box, Paper, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import Footer from "../Layout/Footer/Footer";

const initialValues = Object.freeze({
  email: "",
  password: "",
  remember: false,
});

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Turi būti tinkamas el. pašto adresas")
    .required("Privalomas laukas"),
});

export default function PasswordReset({ setOpenLoginDialog, pushTo }) {
  const { dispatchUser, dispatchRouter } = useGlobalContext();
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "passwordReset" },
    });
  }, []);

  async function handleSubmit(values, formikHelpers) {
    try {
      await resetPassword(values, dispatchUser);
      setSendEmailSuccess(true);
    } catch (error) {
      // console.log("error", error);
      // formikHelpers.setErrors({
      //   email: "Vartotojas su tokiu el. paštu neegzistuoja",
      // });
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: grey[200],
        border: "none",
        pt: 13,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          border: "none",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
        }}
        disableGutters
      >
        <Paper elevation={1} sx={{ p: 1, mb: 7 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form noValidate sx={{ width: "100%" }}>
              <Stack justifyContent="center" alignItems="center" sx={{ my: 2 }}>
                <Avatar sx={{ width: 70, height: 70 }} />

                {!sendEmailSuccess && (
                  <>
                    <Typography variant="h6" color="grey">
                      Slaptažodžio keitimo nuoroda
                    </Typography>
                    <Stack
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        my: 4,
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2">
                        Įveskite savo el. paštą ir gausite slaptažodžio keitimo
                        nuorodą, jei toks el. paštas yra užregistruotas
                      </Typography>
                      <FormikTextField name="email" label="El. paštas *" />
                    </Stack>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Siųsti slaptažodžio keitimo nuorodą
                    </Button>
                    <Grid
                      container
                      spacing={1}
                      sx={{ mt: 0 }}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="body2">
                          Prisiminėte slaptažodį?
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Link
                          component={RouterLink}
                          to={"/login"}
                          variant="body2"
                        >
                          Prisijunkite čia
                        </Link>
                      </Grid>
                    </Grid>
                  </>
                )}

                {sendEmailSuccess && (
                  <>
                    <Typography variant="h6" textAlign="center" color="grey">
                      Slaptažodžio keitimo nuoroda išsiųsta
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 4 }}>
                      Jūsų nurodytu el. pašto adresu išsiųsta paskyros
                      slaptažodžio keitimo nuoroda. Patikrinkite savo el. paštą.
                    </Typography>
                  </>
                )}
              </Stack>
              {/* <FormPreview /> */}
            </Form>
          </Formik>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}
