import React, { useEffect } from "react";
import { useState } from "react";
import { useGlobalContext } from "../../context";
import { useParams } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import PhotoList from "./PhotoGalery/PhotoList";
import AdMap from "./AccordionMap/AdMap";
import AccordionDetail from "./AccordionDetail/AccordionDetail";
import AccordionDescription from "./AccordionDescription";
import AccordionLoanCalculator from "./AccordionLoanCalculator/AccordionLoanCalculator";
import AccordionValue from "./AccordionValue/AccordionValue";
import SwipeableCarousel from "./PhotoGalery/SwipeableCarousel";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../utils/defaults";
import ContactInfo from "./ContactInfo";
import BasicAdInfo from "./BasicAdInfo/BasicAdInfo";
import AdStats from "./AdStats";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import AdTitle, { getAdTitle } from "./AdTitle";
import AccordionHistory from "./AccordionHistory/AccordionHistory";
import AccordionMap from "./AccordionMap/AccordionMap";
import AccordionNotes from "./AccordionNotes/AccordionNotes";
import AccordionEnquiry from "./AccordionEnquiry/AccordionEnquiry";
import PhotoModal from "./PhotoGalery/PhotoModal";
import MessageNoteTab from "./MessageNoteTab";
import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import AdToolbar from "./AdToolbar/AdToolbar";
import StarIcon from "@mui/icons-material/Star";
import AdStatusText from "../MainPage/MainListing/AdCardFormats/AdStatusText";
import Footer from "../Layout/Footer/Footer";
import AdDataProvider from "./AdDataProvider";
import ExpiredAd from "./ExpiredAd";
import PageNotFound from "./PageNotFound";
import { getDefaultPhotoUrl } from "../../utils/getDefaultPhotoUrl";
import { Helmet } from "react-helmet";

const Ad = () => {
  const { state, userState, dispatchUser, dispatchRouter } = useGlobalContext();
  const { isSmallOrMobile, isMediumOrSmaller, isMedium } =
    useCustomMediaQuery();
  const { adId } = useParams();

  const [activeSlide, setActiveSlide] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [openFullScreenCarousel, setOpenFullScreenCarousel] = useState(false);
  const [writeMessage, setWriteMessage] = useState(false);

  const ad =
    +state?.ad?.id === +adId
      ? state.ad
      : state?.ads?.find((ad) => +ad?.id === +adId);
  const preferenceScore = state?.adCards?.find(
    (adCard) => adCard?.id === ad?.id
  )?.properties?.preference_score;

  function handleWriteMessage() {
    if (!userState.isAuthenticated) {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });
      return;
    }
    setWriteMessage(true);
  }

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "ad" },
    });
  }, []);

  // console.log("mainAppState from Ad", state);
  // console.log("preference score from Ad", preferenceScore);
  // console.log("ad from Ad Component", ad, adId);

  return (
    <AdDataProvider>
      {ad && (
        <Helmet>
          <title>{`${getAdTitle(ad)} - ${ad.properties.subtitle}`}</title>
        </Helmet>
      )}
      <Box
        sx={{
          border: "none",
          backgroundColor: grey[200],
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pt: 13,
        }}
      >
        <AdToolbar ad={ad} setActiveSlide={setActiveSlide} />
        <Container
          maxWidth={isSmallOrMobile ? "sm" : isMediumOrSmaller ? "md" : "lg"}
          sx={{ pt: 1, pb: 1, display: "flex", justifyContent: "center" }}
          disableGutters
        >
          {(!!state?.adLoading || !state.adLoaded) && !ad && (
            <Box
              sx={{
                position: "absolute",
                // replace 100px with the actual size of a toolbar
                height: "85%",
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!ad && !state.adLoading && state.adLoaded && <PageNotFound />}

          {!!ad && ad.properties.status === "expired" && <ExpiredAd ad={ad} />}
          {!!ad && ad.properties.status !== "expired" && (
            <>
              {/* <Grid container justifyContent="center" xs={12}> */}
              <Container maxWidth="lg" disableGutters>
                <Grid container justifyContent="center">
                  <Grid
                    container
                    sx={{ pl: 1, mb: 1, justifyContent: "start" }}
                  >
                    <BreadcrumbsComponent ad={ad} />
                  </Grid>
                  <Grid container sx={{ pl: 1 }}>
                    <Grid
                      // container
                      item
                      xs={12}
                      lg={8}
                      sx={{ border: "none", display: "flex" }}
                      justifyContent="space-between"
                    >
                      <AdTitle ad={ad} />
                      {!!ad.properties.is_exact && !!preferenceScore && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mr: 0.5,
                          }}
                        >
                          <Typography variant="body2" sx={{ mt: 0.2 }}>
                            {preferenceScore.toFixed(1)}
                          </Typography>
                          <StarIcon
                            color="warning"
                            sx={{ fontSize: 18, mr: 0.2 }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    justifyContent="left"
                    sx={{ border: "none", position: "relative" }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        // height: "auto",
                        aspectRatio: String(ASPECT),
                        border: "none",
                        objectFit: "contain",
                        px: 0.5,
                      }}
                    >
                      {showMap && <AdMap ad={ad} showMap={showMap} />}
                      {!showMap && (
                        <>
                          {!!ad.properties.photos.length && (
                            <Box sx={{ position: "relative" }}>
                              <AdStatusText
                                ad={ad}
                                zIndex={1}
                                variant="body1"
                              />
                              <SwipeableCarousel
                                ad={ad}
                                activeSlide={activeSlide}
                                setActiveSlide={setActiveSlide}
                                open={false}
                                setOpen={setOpenFullScreenCarousel}
                              />
                            </Box>
                          )}
                          {!ad.properties.photos.length && (
                            <Box sx={{ position: "relative" }}>
                              <AdStatusText
                                ad={ad}
                                zIndex={1}
                                variant="body1"
                              />
                              <Box
                                component="img"
                                sx={{
                                  width: "100%",
                                  // height: isMobile ? "auto" : "560px",
                                  height: "auto",
                                  objectFit: "fill",
                                }}
                                src={getDefaultPhotoUrl()}
                                alt="default photo"
                              />
                            </Box>
                          )}
                        </>
                      )}
                    </Box>

                    <PhotoModal
                      ad={ad}
                      activeSlide={activeSlide}
                      setActiveSlide={setActiveSlide}
                      openFullScreenCarousel={openFullScreenCarousel}
                      setOpenFullScreenCarousel={setOpenFullScreenCarousel}
                    />

                    {(ad.properties.photos.length > 1 ||
                      !isMediumOrSmaller) && (
                      <Box
                        sx={{
                          px: 0.5,
                          border: "none",
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <PhotoList
                          ad={ad}
                          activeSlide={activeSlide}
                          setActiveSlide={setActiveSlide}
                          setShowMap={setShowMap}
                        />
                      </Box>
                    )}
                  </Grid>
                  {!isMediumOrSmaller && (
                    <Grid
                      // container
                      container
                      item
                      lg={4}
                      justifyContent="center"
                      alignItems="start"
                      sx={{ border: "none", px: 0.5 }}
                    >
                      <Stack
                        spacing={0}
                        sx={{
                          width: "100%",
                          height: "100%",
                          flexDirection: "column",
                          border: "none",
                        }}
                      >
                        <ContactInfo
                          ad={ad}
                          handleWriteMessage={handleWriteMessage}
                        />
                        <MessageNoteTab
                          ad={ad}
                          writeMessage={writeMessage}
                          setWriteMessage={setWriteMessage}
                        />
                      </Stack>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  // md={8}
                  lg={12}
                  justifyContent="center"
                  sx={{ border: "none", display: "flex", mt: 1 }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    sx={{ px: 0.5, border: "none", flex: 1 }}
                  >
                    <BasicAdInfo
                      ad={ad}
                      showMap={showMap}
                      setShowMap={setShowMap}
                    />
                  </Grid>
                  {!isMediumOrSmaller && (
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      sx={{ px: 0.5, border: "none", flex: 1 }}
                    >
                      <AdStats ad={ad} />
                    </Grid>
                  )}
                </Grid>

                {isMediumOrSmaller && (
                  <Grid
                    container
                    // item
                    // xs={12}
                    sx={{ border: "none", alignItems: "stretch" }}
                  >
                    <Grid container item xs={12} md={6} sx={{ px: 0.5, mt: 1 }}>
                      <ContactInfo
                        ad={ad}
                        handleWriteMessage={handleWriteMessage}
                      />
                    </Grid>
                    {isMedium && (
                      <Grid container item md={6} sx={{ px: 0.5, mt: 1 }}>
                        <AdStats ad={ad} />
                      </Grid>
                    )}
                  </Grid>
                )}

                <Grid container sx={{ border: "none" }}>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    sx={{
                      border: "none",
                    }}
                  >
                    <Grid container sx={{ border: "none", mt: 1, px: 0.5 }}>
                      {isMediumOrSmaller && (
                        <AccordionEnquiry
                          ad={ad}
                          writeMessage={writeMessage}
                          setWriteMessage={setWriteMessage}
                        />
                      )}
                      {isMediumOrSmaller && <AccordionNotes ad={ad} />}

                      <AccordionDetail ad={ad} />
                      {!!ad.properties.description && (
                        <AccordionDescription ad={ad} />
                      )}
                      <AccordionMap ad={ad} />
                      {["Parduodamas butas", "Parduodamas namas"].includes(
                        ad.properties.ad_type
                      ) && <AccordionLoanCalculator ad={ad} />}
                      {ad.properties.is_exact &&
                        !ad.properties.hide_valuation && (
                          <AccordionValue ad={ad} />
                        )}
                      <AccordionHistory ad={ad} />
                    </Grid>
                    {isSmallOrMobile && (
                      <Grid container item xs={12} sx={{ px: 0.5, mt: 1 }}>
                        <AdStats ad={ad} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </>
          )}
        </Container>
        <Footer />
      </Box>
    </AdDataProvider>
  );
};

export default Ad;
