export function getFullImgUrl(str) {
  if (!str) return null;
  if (str.includes("http")) return str;

  let baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://fra1.digitaloceanspaces.com/housemarket-spaces"
      : "http://localhost:8000";

  let url = str.includes("/media/") ? baseUrl + str : baseUrl + "/media/" + str;
  // console.log("url from getFullImgUrl", url);
  return url;
}
