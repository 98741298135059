import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SaleChart = ({ ad, showPricePerSqM, draftPrice }) => {
  const { isMobile } = useCustomMediaQuery();
  // const isFlat = ad.properties.ad_type === "Parduodamas butas";
  const areaMultiplier = ad.properties.area;
  let price =
    ad.properties.status === "draft" ? draftPrice : ad.properties.price;
  price = price / (showPricePerSqM ? areaMultiplier : 1);
  const salePriceEst =
    ad.properties.prediction.sale_price_sqm_pred *
    (showPricePerSqM ? 1 : areaMultiplier);
  const saleMape = ad.properties.prediction.sale_price_mape;

  const saleData = {
    // labels: [0, 0, 0],
    datasets: [
      price
        ? {
            label: "Šio būsto kaina",
            data: [{ x: Math.round(price), y: 0, z: "Šio būsto kaina" }],
            backgroundColor: "rgba(255, 99, 132, 1)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
          }
        : null,
      {
        label: "Tipinės kainos skelbimuose",
        data: [
          {
            x: Math.round(salePriceEst * (1 - saleMape)),
            y: 0,
            z: "Vidurkis - standartinis nuokrypis",
          },
          { x: Math.round(salePriceEst), y: 0, z: "Vidurkis" },
          {
            x: Math.round(salePriceEst * (1 + saleMape)),
            y: 0,
            z: "Vidurkis + standartinis nuokrypis",
          },
        ],
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
      },
    ].filter((dataset) => dataset),
  };

  const saleOptions = {
    maintainAspectRatio: isMobile,
    responsive: true,
    aspectRatio: 2,
    plugins: {
      title: {
        display: false,
        text: `Panašaus būsto kainos rinkoje (${
          showPricePerSqM ? "€/\u33A1" : "€"
        })`,
      },
      tooltip: {
        usePointStyle: true,
        intersect: false, // makes it easier to select tooltips on mobile
        callbacks: {
          title: (toolTipItem) => {
            const dataIndex = toolTipItem[0].dataIndex;
            let title = toolTipItem[0].dataset.data[dataIndex].z;
            return title;
          },
          label: (toolTipItem) => {
            // console.log("toolTipItem", toolTipItem.label);
            // const dataIndex = toolTipItem[0].dataIndex;
            let label = `${toolTipItem.label} ${
              showPricePerSqM ? "€/\u33A1" : "€"
            }`;
            return label;
          },
        },
      },

      legend: {
        position: "bottom",
        labels: { usePointStyle: true },
        onClick: (e, legendItem) => false,
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
      },
      y: {
        display: false,
      },
    },
  };

  // console.log("saleOptions", saleOptions);

  return (
    <Line data={saleData} height={150} width={600} options={saleOptions} />
  );
};

export default SaleChart;
