import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { fetchSelectedAds } from "../fetchOrSendDashboardData";
import MyFavoritesFilterSelectBox from "./MyFavoritesFilterSelectBox";
import AdCard from "../../MainPage/MainListing/AdCard";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { fetchLikedAdIds } from "../../MainPage/fetchOrSendMainAppData";
import { blue } from "@mui/material/colors";
import Spinner from "../../../utils/Spinner";

function MyFavorites() {
  const { state, dispatch, dispatchDashboard, dashboardState, userState } =
    useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const [initialLoad, setInitialLoad] = useState(true);
  const [myFavorites, setMyFavorites] = useState(null);

  useEffect(() => {
    setInitialLoad(false);
  }, []);

  // fetch my favorite ads
  useEffect(() => {
    if (
      userState.isAuthenticated &&
      (!dashboardState.myFavoritesLoaded || !state.likedAdIdsLoaded) &&
      !state.likedAdIdsLoading
    ) {
      fetchSelectedAds(dispatchDashboard, "my_favorites");
      fetchLikedAdIds({}, dispatch);
    }
  }, [
    dashboardState.myFavoritesLoaded,
    dashboardState.selectedAdsLoading,
    state.likedAdIdsLoaded,
    dispatch,
    dispatchDashboard,
    userState.isAuthenticated,
    state.likedAdIdsLoading,
  ]);

  useEffect(() => {
    if (
      !!dashboardState.myFavoritesLoaded &&
      !!state.likedAdIdsLoaded &&
      !!state.likedAds
    ) {
      let myFavorites =
        dashboardState.myFavoritesFilterValue === "all"
          ? dashboardState.myFavorites
          : dashboardState.myFavorites.filter(
              (ad) =>
                ad.properties.ad_type === dashboardState.myFavoritesFilterValue
            );
      myFavorites = myFavorites.filter((ad) =>
        (state?.likedAds || []).includes(ad.id)
      );
      setMyFavorites(myFavorites);
    }
  }, [
    dashboardState.myFavorites,
    dashboardState.myFavoritesFilterValue,
    dashboardState.myFavoritesLoaded,
    state.likedAdIdsLoaded,
    state.likedAds,
  ]);

  return (
    <Container
      maxWidth="md"
      disableGutters
      sx={{ position: "relative", border: "none" }}
    >
      <>
        <Paper
          elevation={1}
          sx={{
            mt: 2,
            mb: 1,
            mx: 0.5,
            p: 1,
            position: "relative",
            backgroundColor: blue[100],
            border: `1px solid lightgrey`,
          }}
        >
          <Box
            justifyContent="space-between"
            alignItems="center"
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            <Typography
              variant={isMobile ? "body2" : "body1"}
              sx={{ m: 1, whiteSpace: "nowrap" }}
            >
              {!dashboardState.myFavoritesLoaded ||
              !state.likedAdIdsLoaded ||
              !myFavorites ||
              !state.likedAds
                ? "Kraunama..."
                : !!myFavorites?.length
                ? `Įsiminti skelbimai: ${myFavorites.length}`
                : `Nėra įsimintų skelbimų`}
              {/* {dashboardState?.myFavorites?.length
                    ? `Įsiminti skelbimai: ${dashboardState.myFavorites.length}`
                    : `Nėra įsimintų skelbimų`} */}
            </Typography>
            <MyFavoritesFilterSelectBox />
          </Box>
        </Paper>

        {(!dashboardState.myFavoritesLoaded || !state.likedAdIdsLoaded) && (
          <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
            <Spinner />
          </Box>
        )}

        {!!myFavorites?.length && (
          <Grid container justifyContent="center">
            {myFavorites.map((ad) => (
              <Grid container item xs={12} key={ad.id} sx={{ my: 1, px: 0.5 }}>
                <AdCard ad={ad} />
              </Grid>
            ))}
          </Grid>
        )}
      </>
    </Container>
  );
}

export default MyFavorites;
