import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import {
  crudAppointment,
  // listCreateEnquiryMessage,
  crudCalendarEntry,
} from "../../fetchOrSendDashboardData";
import useCalendarEntry from "./useCalendarEntry";
import useAppointmentMessage from "./useAppointmentMessage";
import useAppointment from "./useAppointment";

export default function AppointmentAgreeButton({ existingAppointment }) {
  let history = useHistory();
  const { dispatchDashboard } = useGlobalContext();
  let { tabName } = useParams();
  const [acceptChange, setAcceptChange] = useState(false);

  let appointmentDateTime = !!existingAppointment?.appointment
    ? new Date(existingAppointment.appointment)
    : null;

  // console.log("appointmentDateTime from AgreeButton", appointmentDateTime);

  const { appointment } = useAppointment(appointmentDateTime);
  const { calendarEntry } = useCalendarEntry(undefined, appointmentDateTime);
  const { appointmentMessage } = useAppointmentMessage("", appointment);

  const crudAppointmentMethod = !!appointment?.appointment ? "patch" : "delete";
  const crudCalendarMethod = !!appointment?.appointment
    ? !!calendarEntry?.id
      ? "patch"
      : "post"
    : !!calendarEntry?.id && crudAppointmentMethod !== "delete"
    ? "delete"
    : "skip";

  useEffect(() => {
    if (acceptChange) {
      crudAppointment(
        dispatchDashboard,
        appointment,
        crudAppointmentMethod,
        appointmentMessage
      );
      if (crudCalendarMethod !== "skip") {
        crudCalendarEntry(dispatchDashboard, calendarEntry, crudCalendarMethod);
      }
      if (crudAppointmentMethod === "delete") {
        dispatchDashboard({
          type: "CRUD_CALENDAR_ENTRY",
          payload: {
            calendarEntryId: calendarEntry.id,
            calendarEntryObject: null,
            method: "delete",
          },
        });
      }
      setAcceptChange(false);
    }
  }, [
    acceptChange,
    appointment,
    appointmentMessage,
    calendarEntry,
    crudAppointmentMethod,
    crudCalendarMethod,
    dispatchDashboard,
  ]);

  function handleAcceptChange(event) {
    event.stopPropagation();
    history.replace(
      `/dashboard/${tabName}/${existingAppointment.ad}/${existingAppointment.enquirer}`
    );
    setAcceptChange(true);
  }

  // console.log("calendarEntry from AppointmentAgreeButton", calendarEntry);

  return (
    <Button
      startIcon={<CheckIcon fontSize="small" />}
      size="small"
      variant="contained"
      sx={{ minWidth: "15px", ml: 1 }}
      onClick={handleAcceptChange}
    >
      OK
      {/* <CheckIcon fontSize="small" /> */}
    </Button>
  );
}
