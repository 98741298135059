export const initialLoanState = {
  downPercent: 15,
  minDownPercent: 15,
  interestRate: 5,
  fixMonthlyPayment: false,
  duration: 30,
  maxDuration: 30,
};

export function loanReducer(loanState, action) {
  switch (action.type) {
    case "CHANGE_LOAN_STATE":
      let payload = action.payload;
      loanState = { ...loanState, ...payload };
      // console.log("loanState from reducer", loanState);
      return loanState;
    case "CHANGE_LOAN_STATE_TO_INITIAL":
      return initialLoanState;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
