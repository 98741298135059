import React from "react";
import { Box } from "@mui/material";
import { useGlobalContext } from "../../../context";
import TabPanel from "./TabPanel";
import useTab from "./useTab";
import { grey } from "@mui/material/colors";
import Footer from "../../Layout/Footer/Footer";
import useRouterTracker from "../../../hooks/useRouterTracker";
import { Helmet } from "react-helmet";
import { tabArray } from "./DashboardToolBar";

function Dashboard() {
  const { showComponent } = useTab();
  const { routerState } = useGlobalContext();
  useRouterTracker("dashboard", "dashboardUrlString");

  return (
    <>
      {/* <DashboardToolBar /> */}
      <Box
        sx={{
          backgroundColor: grey[200],
          border: "none",
          pt: 13,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Helmet>
          <title>{`Vartotojo sritis - ${
            tabArray.find((tab) => tab.index === routerState.tab).label
          }`}</title>
        </Helmet>
        <TabPanel value={routerState.tab} index={0}>
          {showComponent}
        </TabPanel>
        <TabPanel value={routerState.tab} index={1}>
          {showComponent}
        </TabPanel>
        <TabPanel value={routerState.tab} index={2}>
          {showComponent}
        </TabPanel>
        <TabPanel value={routerState.tab} index={3}>
          {showComponent}
        </TabPanel>
        <TabPanel value={routerState.tab} index={4}>
          {showComponent}
        </TabPanel>
        <TabPanel value={routerState.tab} index={5}>
          {showComponent}
        </TabPanel>
        <Footer />
      </Box>
    </>
  );
}

export default Dashboard;
