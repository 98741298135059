import React from "react";
import { Typography, List } from "@mui/material";

import useDescription from "./useDescription";

function AccordionDetailLand({ ad }) {
  const { land } = useDescription(ad);

  // console.log("land from AccordionDetailLand", land.isLoggingForest);
  const isForest = ad.properties.plot_purpose === "Miškų ūkio";
  const isFarm = ad.properties.plot_purpose === "Žemės ūkio";

  return (
    land && (
      <>
        <Typography variant="subtitle2" sx={{ mt: 0 }}>
          Sklypas ir aplinka
        </Typography>
        <List dense>
          {land.plot}
          {land.plotPurpose}
          {isForest && land.forestType}
          {isForest && land.forestGroup}
          {isForest && land.isLoggingForest}
          {!isForest && land.landPlotCommunications}
          {isFarm && land.fertilityScore}
          {!isForest && land.waterSewage}
          {!isForest && land.recreation}
          {land.waterBodies}
        </List>

        {ad.properties.sale_type.length > 0 && (
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Pardavimo sąlygos
            <List dense>
              {land.auction}
              {land.exchange}
              {land.preferences}
              {land.nonnegotiable}
              {land.noIntermediaries}
            </List>
          </Typography>
        )}

        {ad.properties.rent_type.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Nuomos sąlygos
            </Typography>
            <List dense>
              {land.minRentPeriod}
              {land.preferLongerContracts}
              {land.depositRequired}
              {land.formalContract}
              {land.noRentAgency}
            </List>
          </>
        )}
      </>
    )
  );
}

export default AccordionDetailLand;
