import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../../../context";
import {
  crudAppointment,
  crudCalendarEntry,
} from "../../fetchOrSendDashboardData";
import useCalendarEntry from "./useCalendarEntry";
import useAppointmentMessage from "./useAppointmentMessage";
import useAppointment from "./useAppointment";
import DateTimePickerComponent from "../../../../utils/DateTimePickerComponent";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useBackHandler from "../../../../hooks/useBackHandler";
import { useHistory } from "react-router-dom";

export default function AppointmentDialog({
  openAppointmentDialog,
  setOpenAppointmentDialog,
  initialAppointmentDateTime,
  privateCalendarEntry,
}) {
  const { dispatchDashboard } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  useBackHandler(handleXClose);
  const history = useHistory();
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  const [messageChecked, setMessageChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [calendarError, setCalendarError] = useState(false);

  const [appointmentDateTime, setAppointmentDateTime] = useState(
    initialAppointmentDateTime
  );
  const [appointmentDateTimeChanged, setAppointmentDateTimeChanged] =
    useState(false);

  const [memo, setMemo] = useState();
  const { calendarEntry } = useCalendarEntry(
    memo,
    appointmentDateTime,
    privateCalendarEntry
  );
  const { appointment } = useAppointment(appointmentDateTime);
  const { appointmentMessage } = useAppointmentMessage(message, appointment);

  function handleDelete() {
    setAppointmentDateTime(null);
    setMemo("");
  }

  function handleXClose(event, reason) {
    if (event) {
      event.stopPropagation();
    }
    if (!!reason && reason === "backdropClick") return;
    setOpenAppointmentDialog(false);
  }

  function handleOkClose(event) {
    event.stopPropagation();
    let appointmentCrudMethod = !appointment.id
      ? "post"
      : !appointmentDateTime &&
        !appointment.appointment_owner &&
        !appointment.appointment_enquirer
      ? "delete"
      : "patch";
    const calendarCrudMethod =
      !appointmentDateTime &&
      appointment.appointment_status.includes("canceled")
        ? "delete" // calendarEntry needs to be deleted if appointment is canceled
        : appointmentCrudMethod;
    crudCalendarEntry(dispatchDashboard, calendarEntry, calendarCrudMethod);
    if (!calendarEntry.private && appointmentDateTimeChanged) {
      crudAppointment(
        dispatchDashboard,
        appointment,
        appointmentCrudMethod,
        appointmentMessage
      );
      // setAppointmentMessageToSend(appointmentMessage);
      // return;
      // if (new Date(appointment.appointment) >= today) {
      //   listCreateEnquiryMessage(
      //     dispatchDashboard,
      //     "post",
      //     null,
      //     appointmentMessage
      //   );
      // }
    }
    setOpenAppointmentDialog(false);
  }

  return (
    <Dialog
      open={openAppointmentDialog}
      onClose={(event, reason) => {
        history.goBack();
        handleXClose(event, reason);
      }}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>Keiskite apžiūros laiką</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <DateTimePickerComponent
              initialAppointmentDateTime={initialAppointmentDateTime}
              appointmentDateTime={appointmentDateTime}
              setAppointmentDateTime={setAppointmentDateTime}
              setCalendarError={setCalendarError}
              setAppointmentDateTimeChanged={setAppointmentDateTimeChanged}
            />

            {appointmentDateTimeChanged && !calendarEntry.private && (
              <Grid
                item
                alignItems="start"
                sx={{ border: "none", display: "flex", mt: 1 }}
              >
                <PriorityHighIcon
                  color="warning"
                  sx={{ fontSize: 16, mt: 0.2 }}
                />
                <Typography variant="body2">
                  Apie{" "}
                  {!appointmentDateTime
                    ? "atšauktą "
                    : !!initialAppointmentDateTime
                    ? "pakeistą "
                    : "pasiūlytą "}
                  laiką bus pranešta kontaktui.
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextField
                label="Asmeninė pastaba mano kalendoriuje"
                disabled={!appointmentDateTime}
                value={calendarEntry.memo}
                onChange={(e) => setMemo(e.target.value)}
                fullWidth
                multiline
                rows={2}
                // maxRows={4}
                width="200px"
                inputProps={{ maxLength: 200, spellCheck: false }}
              />
            </Grid>

            {appointmentDateTimeChanged && (
              <Grid container xs={12} justifyContent="right" sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={messageChecked}
                      onChange={(event) =>
                        setMessageChecked(event.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Rašyti papildomą žinutę kontaktui
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
            )}

            {appointmentDateTimeChanged && messageChecked && (
              <TextField
                label="Žinutė kontaktui"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                multiline
                maxRows={4}
                rows={2}
                width="200px"
                inputProps={{ spellCheck: false }}
              />
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mx: 2, mb: 2 }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            Trinti
          </Button>
          <Stack spacing={1} direction="row">
            <Button
              onClick={(event, reason) => {
                history.goBack();
                handleXClose(event, reason);
              }}
              color="primary"
              variant="outlined"
            >
              Atšaukti
            </Button>
            <Button
              onClick={handleOkClose}
              disabled={calendarError}
              color="primary"
              variant="contained"
              autoFocus
            >
              Gerai
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
