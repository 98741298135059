import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, LinearProgress } from "@mui/material";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import AccordionValue from "../../../IndividualAd/AccordionValue/AccordionValue";
import { green } from "@mui/material/colors";
import { useGlobalContext } from "../../../../context";

function PriceComparison({ edit, ad, formik }) {
  const { adFormState } = useGlobalContext();
  const lowerBound =
    ad.properties?.prediction?.sale_price_sqm_pred *
    (1 - ad.properties?.prediction?.sale_price_mape);
  const upperBound =
    ad.properties?.prediction?.sale_price_sqm_pred *
    (1 + ad.properties?.prediction?.sale_price_mape);

  return (
    <Grid container sx={{ mt: 2, justifyContent: "center", pl: 1 }}>
      {!edit && adFormState.estimatingPrice ? (
        <Grid item xs={12} sm={6} alignItems="center">
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundColor: green[100],
              border: "solid lightgrey 1px",
              borderRadius: "5px",
              my: 1,
              pt: 1,
            }}
          >
            <Typography variant="subtitle" component="div" gutterBottom>
              Palaukite... Vertiname panašių būstų kainas.
            </Typography>
            <Typography variant="subtitle" component="div" gutterBottom>
              Tai gali šiek tiek užtrukti.
            </Typography>
            <LinearProgress
              color="success"
              sx={{ height: 4, borderRadius: 5 }}
            />
          </Box>
        </Grid>
      ) : (
        !edit &&
        ad.properties?.prediction && (
          <Grid item xs={12}>
            <AccordionValue ad={ad} draftPrice={formik.values.price} />
          </Grid>
        )
      )}
      {formik.values.price &&
        (formik.values.price / +ad.properties.area < lowerBound ||
          formik.values.price / +ad.properties.area > upperBound) && (
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ px: 2 }}>
              <Typography variant="body1">
                Atrodo, jūsų kaina skiriasi nuo tipinių kainų rinkoje. Ar
                norėtumėte savo skelbime nerodyti kainos palyginimo su tipinės
                kainomis?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ px: 1 }}>
              <FormikCheckbox
                name="hide_valuation"
                label="Nerodyti kainų palyginimo skelbime"
              />
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}

export default PriceComparison;
