import { Box, Dialog } from "@mui/material";
import React, { useEffect } from "react";
import Login from "./Login";
import { useGlobalContext } from "../../context";
import { useLocation, useHistory } from "react-router-dom";
import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import useBackHandler from "../../hooks/useBackHandler";

export default function LoginDialog() {
  const { userState, dispatchUser } = useGlobalContext();
  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();
  const openLoginDialog = userState.openLoginDialog;
  const location = useLocation();
  const history = useHistory();
  const pushTo = location.pathname + location.search;
  useBackHandler(handleCloseDialog);

  function handleCloseDialog() {
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { openLoginDialog: false },
    });
  }

  useEffect(() => {
    if (!!userState.isAuthenticated) handleCloseDialog();
  }, [userState.isAuthenticated]);

  return (
    <Dialog
      open={openLoginDialog}
      onClose={() => {
        history.goBack();
        handleCloseDialog();
      }}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <Box
        sx={{
          position: "relative",
          overflowY: isSmallOrMobile ? "auto" : "hidden",
          border: "none",
          p: 1,
        }}
      >
        {isMobile && (
          <CloseIcon
            onClick={handleCloseDialog}
            color="action"
            sx={{ position: "absolute", right: 7, top: 7 }}
          />
        )}
        <Login pushTo={pushTo} />
      </Box>
    </Dialog>
  );
}
