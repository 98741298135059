import React, { useRef, useState, useEffect } from "react";
import L from "leaflet";
import selectedIconUrl from "../../../static/icons/selected_dot.png";
import customIconUrl from "../../../static/icons/dot.png";
import { TextField, MenuItem, Box, Typography, Stack } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { fetchInfrastructureData } from "../fetchOrSendAdData";
import {
  Circle,
  FeatureGroup,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { DISTANCE_OPTIONS, FEATURE_OPTIONS } from "../../../utils/defaults";
import DisableMapDrag from "../../AdForm/Step1AdInfo/LocationPanels/DisableMapDrag";
import { MapLibreTileLayer } from "../../MainPage/MainMap/MapLibreTileLayer.ts";
import { BiMove } from "react-icons/bi";
import { LiaHandPointer } from "react-icons/lia";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery.js";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const customIcon = L.icon({
  iconUrl: customIconUrl,
  iconSize: [12, 12],
  iconAnchor: [6, 6],
});

const centerMarkerIcon = L.icon({
  iconUrl: selectedIconUrl,
  iconSize: [19, 19],
  iconAnchor: [10, 10],
});

function getPosition(coordinates) {
  let result = {
    lat: Math.max(...coordinates),
    lng: Math.min(...coordinates),
  };
  return result;
}

const AdMap = ({ ad, showMap }) => {
  const { state, dispatch } = useGlobalContext();
  // const ad = state.ad;
  const [category, setCategory] = useState(
    state.amenityCategory || "basic_shops"
  );
  const [distance, setDistance] = useState(state.amenityRadius || 1);
  const [isMapActive, setIsMapActive] = useState(false);
  const mapCenter = getPosition(ad.geometry.coordinates);
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  // const { isMobile } = useCustomMediaQuery();

  useEffect(() => {
    setCategory(state.amenityCategory || "basic_shops");
    setDistance(state.amenityRadius || 1);
  }, [state.amenityCategory, state.amenityRadius]);

  // load infrastructure data
  useEffect(() => {
    if (!ad?.infrastructure && showMap) {
      const form = { pk: ad.id, distance: distance * 1000, category };
      fetchInfrastructureData(form, dispatch);
    }
  }, [ad, showMap]);

  useEffect(() => {
    if (!!mapRef?.current) {
      mapRef.current.invalidateSize();
    }
  }, [mapRef]);

  useEffect(() => {
    if (!!mapRef.current && !!circleRef?.current?.getBounds()) {
      mapRef.current.fitBounds(circleRef.current.getBounds());
    }
  }, [ad, mapRef.current, circleRef.current, distance, category]);

  function handleDistanceChange(e) {
    const distance = e.target.value;
    const form = { pk: ad.id, distance: distance * 1000, category };
    fetchInfrastructureData(form, dispatch);
    setDistance(distance);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { amenityRadius: distance },
    });
  }

  function handleCategoryChange(e) {
    const category = e.target.value;
    const form = { pk: ad.id, distance: distance * 1000, category };
    fetchInfrastructureData(form, dispatch);
    setCategory(category);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { amenityCategory: category },
    });
  }

  // console.log("isMapActive", isMapActive);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        opacity: isMapActive ? 1 : 0.6,
        pb: 0.5,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 5,
          left: 5,
          borderRadius: 5,
          backgroundColor: "Background",
          display: "flex",
          opacity: 0.6,
          zIndex: 1000,
        }}
      >
        {isMapActive ? (
          <BiMove fontSize="40px" color="grey" />
        ) : (
          <LiaHandPointer
            fontSize="40px"
            color="grey"
            onClick={() => setIsMapActive(true)}
          />
        )}
      </Box>

      <MapContainer
        ref={mapRef}
        center={mapCenter}
        zoom={17}
        zoomControl={false}
        maxZoom={18}
        minZoom={7}
        style={{ height: "100%", width: "100%" }}
        // whenCreated={(mapInstance) => {
        //   mapRef.current = mapInstance;
        // }}
      >
        <DisableMapDrag setIsMapActive={setIsMapActive} />
        <ZoomControl position="bottomleft" />
        <MapLibreTileLayer
          attribution={
            '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
          }
          url="https://tiles.stadiamaps.com/styles/osm_bright.json"
        />
        {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          opacity={isMapActive ? 1 : 0.6}
        /> */}

        <Marker
          position={mapCenter}
          icon={centerMarkerIcon}
          zIndexOffset={1000}
        />

        {ad?.infrastructure && (
          <FeatureGroup>
            {ad.infrastructure
              .filter((amenity) => amenity.properties.category === category)
              .filter((amenity) => +amenity.properties.distance <= distance)
              .map((amenity) => (
                <Marker
                  position={getPosition(amenity.geometry.coordinates)}
                  icon={customIcon}
                  zIndexOffset={999}
                  eventHandlers={{
                    mouseover: (e) => e.target.openPopup(),
                  }}
                >
                  <Popup
                    closeButton={false}
                    closeOnClick={true}
                    className="custom-popup"
                  >
                    <Stack sx={{ m: 0.5 }}>
                      <Typography variant="subtitle2" textAlign="center">
                        {amenity.properties.name}
                      </Typography>
                      <Typography variant="caption" textAlign="center">
                        {amenity.properties.distance} km
                      </Typography>
                    </Stack>
                  </Popup>
                </Marker>
              ))}
          </FeatureGroup>
        )}

        <FeatureGroup ref={circleRef}>
          <Circle center={mapCenter} radius={distance * 1000} />
        </FeatureGroup>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "white",
            display: "flex",
            zIndex: 1000,
          }}
        >
          <TextField
            select
            size="small"
            variant="standard"
            value={category}
            onChange={handleCategoryChange}
            InputProps={{ style: { fontSize: 14 } }}
            sx={{ position: "relative", zIndex: 1000 }}
          >
            {FEATURE_OPTIONS.map((option) => {
              return (
                <MenuItem value={option.value} dense>
                  {option.label}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            select
            size="small"
            variant="standard"
            value={distance}
            onChange={handleDistanceChange}
            InputProps={{ style: { fontSize: 14 } }}
            sx={{ position: "relative", zIndex: 1000 }}
          >
            {DISTANCE_OPTIONS.map((option) => {
              return (
                <MenuItem value={option.value} dense>
                  {option.label}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        {!ad.properties.is_exact && (
          <Box
            sx={{
              position: "absolute",
              bottom: 20,
              right: 10,
              backgroundColor: "white",
              display: "flex",
              zIndex: 1000,
              border: "solid 1px grey",
              borderRadius: 1,
              p: 0.5,
              alignItems: "center",
            }}
            gap={0.5}
          >
            <WarningAmberIcon fontSize="small" color="warning" />
            <Typography variant="body2">Apytikslė vieta</Typography>
          </Box>
        )}
      </MapContainer>
    </Box>
  );
};

export default AdMap;
