import React, { useState, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Chip } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import reloadAds from "../../../../utils/getUrlFromParams";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import TownFilter from "./TownFilter";
import TownFilterDataProvider from "./TownFilterDataProvider";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import CustomMenu from "../SharedComponents/CustomMenu";

const emptyLocationParams = {
  town: "",
  suburb: "",
  street: "",
};

export default function TownFilterChip() {
  const { dispatch, state } = useGlobalContext();
  const { isMediumOrSmaller } = useCustomMediaQuery();

  const formikRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  const chipLabel = useMemo(() => {
    let chipLabel = "Gyvenvietė";
    if (!!params?.town && !!state.townOptionsLoaded) {
      let selectedTowns = params.town.split(",");
      let selectedCount = selectedTowns.length;
      chipLabel = state.townOptions.find(
        (town) => Number(town.id) === Number(selectedTowns[0])
      )?.name;
      if (selectedCount > 1) {
        chipLabel += ` + ${selectedCount - 1}`;
      }
    }
    return chipLabel;
  }, [params?.town, state.townOptions, state.townOptionsLoaded]);

  // when municipality changes set town to muni center
  useEffect(() => {
    if (!params?.town && !!state.townOptions?.length) {
      let muniCenter = state.municipalityOptions?.find(
        (option) => option.id.toString() === params.municipality
      )?.town;
      // console.log("municenter", muniCenter);
      if (!!muniCenter) {
        let selectedValue = state.townOptions.find(
          (town) => town.name === muniCenter
        )?.id;
        handleSubmit({ town: selectedValue.toString() });
      }
    }
  }, [params?.municipality, state.townOptions]);

  function handleXClick() {
    handleSubmit({ town: "" });
  }

  function handleSubmit(formikValues) {
    if (formikValues.town !== (params?.town || "")) {
      let newParams = { ...params, ...emptyLocationParams, ...formikValues };
      delete newParams.page;
      reloadAds(newParams, dispatch, history);
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          suburbOptions: [],
          streetOptions: [],
          suburbOptionsLoaded: false,
          streetOptionsLoaded: false,
        },
      });
    }
    setAnchorEl(null);
  }

  return (
    <TownFilterDataProvider>
      {!isMediumOrSmaller && (
        <Chip
          label={chipLabel}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          onDelete={chipLabel === "Gyvenvietė" ? null : handleXClick} // !
          variant="outlined"
          disabled={!state.townOptions.length}
          color={chipLabel !== "Gyvenvietė" || anchorEl ? "primary" : "default"}
          sx={{ borderRadius: "3px", mr: 1 }}
        />
      )}
      <CustomMenu
        formikRef={formikRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <TownFilter formikRef={formikRef} handleSubmit={handleSubmit} />
      </CustomMenu>
    </TownFilterDataProvider>
  );
}
