import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Chip } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import reloadAds from "../../../../utils/getUrlFromParams";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import SuburbFilter from "./SuburbFilter";
import SuburbFilterDataProvider from "./SuburbFilterDataProvider";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import CustomMenu from "../SharedComponents/CustomMenu";

export default function SuburbFilterChip() {
  const { dispatch, state } = useGlobalContext();
  const { isMediumOrSmaller } = useCustomMediaQuery();

  const formikRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  let chipLabel = "Mikrorajonas";
  if (!!params?.suburb && !!state.suburbOptionsLoaded) {
    let selectedSuburbs = params.suburb.split(",");
    let selectedCount = selectedSuburbs.length;
    chipLabel =
      state.suburbOptions.find(
        (suburb) => Number(suburb.id) === Number(selectedSuburbs[0])
      )?.name || "Mikrorajonas";
    if (selectedCount > 1) {
      chipLabel += ` + ${selectedCount - 1}`;
    }
  }

  function handleXClick() {
    handleSubmit({ suburb: "" });
  }

  function handleSubmit(formikValues) {
    if (formikValues.suburb !== (params?.suburb || "")) {
      const newParams = { ...params, ...formikValues, street: "" };
      reloadAds(newParams, dispatch, history);
    }
    setAnchorEl(null);
  }

  // console.log("chipLabel from SuburbFilterChip", chipLabel);

  return (
    <SuburbFilterDataProvider>
      {!isMediumOrSmaller && !!state.suburbOptions.length && (
        <Chip
          label={chipLabel}
          onClick={(e) => setAnchorEl(e?.currentTarget)}
          onDelete={chipLabel === "Mikrorajonas" ? null : handleXClick} // !
          variant="outlined"
          disabled={!state.suburbOptions.length}
          color={chipLabel === "Mikrorajonas" ? "default" : "primary"}
          sx={{ borderRadius: "3px", mr: 1 }}
        />
      )}
      <CustomMenu
        formikRef={formikRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <SuburbFilter formikRef={formikRef} handleSubmit={handleSubmit} />
      </CustomMenu>
    </SuburbFilterDataProvider>
  );
}
