import React, { useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Badge,
  Grid,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CustomAvatar from "../../../Layout/CustomAvatar";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import AppointmentBox from "../Appointment/AppointmentBox";
import { useGlobalContext } from "../../../../context";
import ContactStatusSelector from "../../SharedComponents/ContactStatusSelector";
import useClearAppointmentNotifications from "../../../Layout/Header/Notification/AppointmentNotification/useClearAppointmentNotifications";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { green } from "@mui/material/colors";

export default function ContactList({ openDrawer, setOpenDrawer }) {
  let history = useHistory();
  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();
  useClearAppointmentNotifications();
  const { dashboardState, notificationState } = useGlobalContext();
  let { tabName, adId, enquirerId, extension } = useParams();
  const chatFilterValue =
    dashboardState.myAds.find((myAd) => myAd.id === Number(adId))
      ?.chatFilterValue || "only relevant";

  const openChat = extension === "chat";

  const contacts = dashboardState.adContacts || [];
  const orderedContacts = useMemo(
    () => getOrderedContacts(contacts),
    [contacts, chatFilterValue]
  );

  const newSenders = notificationState.notifications.map(
    (notification) => notification.sender
  );

  function getOrderedContacts(contacts) {
    let orderedContacts = [];
    if (!!contacts?.length) {
      // console.log("contacts from ContactList", contacts, chatFilterValue);
      // alert("getOrderedContacts");
      orderedContacts = contacts
        .filter((contact) => contact.ad === Number(adId))
        .sort((a, b) => a.name.localeCompare(b.name));
      if (chatFilterValue === "only relevant") {
        orderedContacts = orderedContacts.filter(
          (enquiry) =>
            !["blocked", "irrelevant"].includes(enquiry.enquirer_status)
        );
      }
      if (chatFilterValue === "all") {
        orderedContacts = orderedContacts.filter(
          (enquiry) => enquiry.enquirer_status !== "blocked"
        );
      }
      if (chatFilterValue === "only blocked") {
        orderedContacts = orderedContacts.filter(
          (enquiry) => enquiry.enquirer_status === "blocked"
        );
      }
    }
    return orderedContacts;
  }

  // open/close chat window and change url parameters when user selects an enquirer
  function handleContactClick(event, index) {
    let selectedEnquirer = orderedContacts[index].enquirer;
    let chat =
      Number(enquirerId) === selectedEnquirer && openChat ? "" : "/chat";
    history.replace(`/dashboard/${tabName}/${adId}/${selectedEnquirer}${chat}`);
    setOpenDrawer(!chat);
  }

  // console.log("orderedContacts", orderedContacts);
  // console.log("newSenders from ContactList component", newSenders);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        // pt: 3,
        border: "none",
        width: "100%",
        maxHeight: "250px",
        // width: "1000px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {!orderedContacts.length && (
        <Grid container sx={{ py: 2, pl: 1 }}>
          <Typography variant="subtitle2">Pokalbių nėra.</Typography>
        </Grid>
      )}
      {!!orderedContacts.length &&
        orderedContacts.map((enquiry, index) => {
          let enquirerBadge = newSenders.includes(enquiry.enquirer);
          return (
            <ListItem
              key={enquiry.enquirer}
              disablePadding
              sx={{ display: "block", borderBottom: "solid lightgrey 1px" }}
            >
              <ListItemButton
                selected={enquiry.enquirer === Number(enquirerId)}
                onClick={(event) => handleContactClick(event, index)}
                sx={{
                  // minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  // px: 2.5,
                  border: "none",
                  py: openDrawer ? 0.5 : 3,
                  pr: openDrawer ? 1 : 2,
                  "&.Mui-selected": {
                    bgcolor: green[50],
                    "&:hover": {
                      bgcolor: green[50],
                    },
                  },
                }}
                disableRipple={openDrawer}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    variant="dot"
                    color={enquirerBadge ? "secondary" : "default"}
                  >
                    <CustomAvatar
                      userId={enquiry.enquirer}
                      name={enquiry.name}
                      styleProps={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </Badge>
                </ListItemIcon>
                <ListItemText
                  sx={{ opacity: openDrawer ? 1 : 0, border: "none" }}
                >
                  <Box
                    sx={{
                      border: "none",
                      width: "100%",
                      alignItems: "center",
                      display: openDrawer ? "flex" : "none",
                      justifyContent: "space-between",
                      // display: openChat ? "none" : "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    {!isMobile && (
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{
                          opacity:
                            enquiry.enquirer_status === "irrelevant" ? 0.4 : 1,
                          ml: 1,
                          width: isMobile ? "30%" : "30%",
                          border: "none",
                        }}
                      >
                        {enquiry.name}
                      </Typography>
                    )}

                    {!isSmallOrMobile && (
                      <Box
                        sx={{
                          mx: 1,
                          width: isMobile ? "auto" : "30%",
                          justifyContent: "left",
                          border: "none",
                          flexWrap: "wrap",
                        }}
                      >
                        {!!enquiry.phone && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <PhoneIphoneIcon
                              fontSize="small"
                              color="action"
                              sx={{
                                opacity:
                                  enquiry.enquirer_status === "irrelevant"
                                    ? 0.4
                                    : 1,
                                mr: 0.5,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                opacity:
                                  enquiry.enquirer_status === "irrelevant"
                                    ? 0.4
                                    : 1,
                              }}
                            >
                              {formatPhoneNumberIntl(enquiry.phone)}
                            </Typography>
                          </Box>
                        )}

                        {!enquiry.enquirer_active && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                opacity:
                                  enquiry.enquirer_status === "irrelevant"
                                    ? 0.4
                                    : 1,
                              }}
                              color="error"
                            >
                              Neaktyvi paskyra
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <AppointmentBox enquiry={enquiry} />
                    <ContactStatusSelector enquiry={enquiry} index={index} />
                  </Box>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}
