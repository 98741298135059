import React from "react";
import { Grid, Modal, IconButton, Typography, Stack } from "@mui/material";
import SwipeableCarousel from "./SwipeableCarousel";
import { grey } from "@mui/material/colors";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VerticalPhotoList from "./VerticalPhotoList";

export default function PhotoModal({
  ad,
  activeSlide,
  setActiveSlide,
  openFullScreenCarousel,
  setOpenFullScreenCarousel,
}) {
  const { isMobile } = useCustomMediaQuery();

  // console.log("render PhotoModal", ad, openFullScreenCarousel);
  // console.log("activeSlide from PhotoModal", activeSlide);

  return (
    <Modal
      // fullScreen
      onClose={() => setOpenFullScreenCarousel(false)}
      open={openFullScreenCarousel}
      // PaperProps={{
      //   style: {
      //     width: "100%",
      //     maxWidth: 800,
      //   },
      // }}
      BackdropProps={{
        style: {
          backgroundColor: grey[800],
          opacity: 0.99,
        },
      }}
    >
      <>
        <IconButton
          size="large"
          onClick={() => setOpenFullScreenCarousel(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <HighlightOffIcon fontSize="large" sx={{ color: "white" }} />
        </IconButton>
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            // height: "100vh",
            // display: "block",
            display: "flex",
            alignItems: "center",
            border: "none",
          }}
        >
          <Grid
            container
            sx={{ display: "flex", border: "none" }}
            justifyContent="center"
            alignItems="start"
          >
            <Typography color="white" variant="caption">
              Nuotrauka {activeSlide + 1} / {ad.properties.photos.length}
            </Typography>
          </Grid>
          {isMobile ? (
            <VerticalPhotoList
              activeSlide={activeSlide}
              open={openFullScreenCarousel}
              setOpen={setOpenFullScreenCarousel}
            />
          ) : (
            <SwipeableCarousel
              ad={ad}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              open={openFullScreenCarousel}
              setOpen={setOpenFullScreenCarousel}
            />
          )}
        </Stack>
      </>
    </Modal>
  );
}
