import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../../context";
import humanizeDate from "../../../../utils/humanizeDate";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HandshakeIcon from "@mui/icons-material/Handshake";
import EventIcon from "@mui/icons-material/Event";
import CustomAvatar from "../../../Layout/CustomAvatar";
import { defaultPhotoUrl } from "../../../../utils/defaults";
import { useHistory } from "react-router-dom";
import AppointmentDialog from "../../MyAds/Appointment/AppointmentDialog";
import CalendarAgreeButton from "./CalendarAgreeButton";
import CalendarEntryDeleteDialog from "./CalendarEntryDeleteDialog";
import { getFullImgUrl } from "../../../../utils/getFullImgUrl";
import PrivateCalendarEntryChangeDialog from "./PrivateCalendarEntryChangeDialog";
import { Link as RouterLink } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";

export function getAddress(entry) {
  const streetHouseFlat = entry?.street
    ? `
    ${entry?.street}${entry?.street && entry?.house ? ` ${entry?.house}` : ""}${
        entry?.house && entry?.flat ? `-${entry.flat}` : ""
      }, `
    : "";
  const address = `
            ${streetHouseFlat}${entry.town}, ${
    !!entry.suburb ? entry.suburb : entry.municipality
  }`;
  return address;
}

function CalendarEntry({ entry }) {
  const { dashboardState, userState } = useGlobalContext();
  const [openEntryDeleteDialog, setOpenEntryDeleteDialog] = useState(false);
  const [openEntryChangeDialog, setOpenEntryChangeDialog] = useState(false);
  const [openPrivateEntryChangeDialog, setOpenPrivateEntryChangeDialog] =
    useState(false);
  const [showCalendarAgreeButton, setShowCalendarAgreeButton] = useState(false);
  const isEnquirer = entry.enquirer === userState.userId;
  const history = useHistory();

  const appointment = useMemo(
    () =>
      dashboardState.myAppointments.find(
        (apt) =>
          apt.ad === entry.ad &&
          apt.enquirer === entry.enquirer &&
          !entry.private
      ) || null,
    [dashboardState.myAppointments, entry]
  );

  useEffect(() => {
    setShowCalendarAgreeButton(
      !dashboardState.myCalendarEntriesLoading &&
        !dashboardState.myAppointmentsLoading &&
        !!appointment &&
        appointment.appointment !== entry.calendar_date
    );
  }, [appointment, entry.calendar_date]);

  function handleEntryChange() {
    if (!!entry.private) {
      setOpenPrivateEntryChangeDialog(true);
    } else {
      history.replace(`/dashboard/my-calendar/${entry.ad}/${entry.enquirer}`);
      setOpenEntryChangeDialog(true);
    }
  }

  function handleDeleteEntry() {
    if (!!entry.ad && !!entry.enquirer) {
      history.replace(`/dashboard/my-calendar/${entry.ad}/${entry.enquirer}`);
    }
    setOpenEntryDeleteDialog(true);
  }

  // console.log("openEntryChangeDialog", openEntryChangeDialog);
  // console.log(
  //   "calendarEntry, appointmentObject from CalendarEntry",
  //   entry,
  //   appointment
  // );

  // console.log("entry from CalendarEntry", entry);

  return (
    <>
      <Paper key={entry.id} sx={{ my: 1, p: 0.5 }}>
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: 0.5,
            border: "none",
          }}
        >
          <Box sx={{ display: "flex" }}>
            {!entry.private ? (
              <HandshakeIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
            ) : (
              <EventIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
            )}
            <Typography variant="subtitle2" color="primary">
              {!!entry.calendar_date && humanizeDate(entry.calendar_date)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              startIcon={
                <EditIcon
                  color={
                    !entry.private &&
                    (["expired", "deleted", "blocked"].includes(
                      entry.ad_status
                    ) ||
                      !entry.enquirer_active ||
                      !entry.owner_active)
                      ? "disabled"
                      : "action"
                  }
                />
              }
              size="small"
              variant="outlined"
              sx={{ display: "flex", mr: 0.5, borderColor: "lightgrey" }}
              color="inherit"
              onClick={handleEntryChange}
              disabled={
                !entry.private &&
                (["expired", "deleted", "blocked"].includes(entry.ad_status) ||
                  !entry.enquirer_active ||
                  !entry.owner_active)
              }
            >
              <Typography variant="body2">Keisti</Typography>
            </Button>
            <IconButton
              size="small"
              onClick={handleDeleteEntry}
              sx={{ display: "flex" }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
        {showCalendarAgreeButton && (
          <CalendarAgreeButton
            entry={entry}
            existingAppointment={appointment}
            setShowCalendarAgreeButton={setShowCalendarAgreeButton}
          />
        )}
        {!!entry.ad && (
          <>
            <Divider />
            <Grid container sx={{ my: 0.5 }}>
              <Grid
                container
                xs={12}
                // md={6}
                sx={{
                  border: "none",
                  flexWrap: "nowrap",
                }}
              >
                <RouterLink
                  to={`/skelbimas/${entry.slug}/${entry.ad}`}
                  sx={{ textDecoration: "none" }}
                >
                  <Box
                    component="img"
                    sx={{ height: "40px", border: "none" }}
                    src={
                      !!entry.photo
                        ? getFullImgUrl(entry.photo)
                        : defaultPhotoUrl
                    }
                    alt="ad thumbnail"
                  />
                </RouterLink>
                <Stack
                  direction="column"
                  sx={{
                    ml: 1,
                    width: "100%",
                    border: "none",
                    overflowX: "hidden",
                  }}
                >
                  <Typography variant="body2" noWrap sx={{ display: "inline" }}>
                    {entry.ad_type}
                  </Typography>
                  <Typography variant="caption" noWrap>
                    {getAddress(entry)}
                  </Typography>
                </Stack>
              </Grid>

              {((isEnquirer && entry.owner_name) ||
                (!isEnquirer && entry.enquirer_name)) && (
                <Grid
                  container
                  xs={12}
                  // md={6}
                  sx={{
                    border: "none",
                    display: "flex",
                    pt: 1,
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    <CustomAvatar
                      userId={isEnquirer ? entry.owner : entry.enquirer}
                      name={isEnquirer ? entry.owner_name : entry.enquirer_name}
                      styleProps={{
                        height: "30px",
                        width: "30px",
                        mr: 1,
                        border: "solid 1px grey",
                      }}
                    />
                    <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
                      <Typography variant="body2" noWrap>
                        {isEnquirer ? entry.owner_name : entry.enquirer_name}
                      </Typography>
                      <Typography variant="caption" noWrap>
                        {isEnquirer ? entry.owner_type : "Interesantas"}
                      </Typography>
                    </Stack>

                    {((!isEnquirer && !!entry.enquirer_phone) ||
                      (isEnquirer && !!entry.owner_phone)) && (
                      <Box
                        sx={{
                          minWidth: "50%",
                          display: "flex",
                          border: "none",
                          justifyContent: "center",
                          alignItems: "center",
                          ml: 2,
                        }}
                      >
                        <PhoneIphoneIcon
                          // fontSize="small"
                          color="action"
                          sx={{
                            width: "16px",
                            heightL: "16px",
                            mr: 0.5,
                          }}
                        />
                        <Typography noWrap variant="body2">
                          {isEnquirer
                            ? formatPhoneNumberIntl(entry.owner_phone)
                            : formatPhoneNumberIntl(entry.enquirer_phone)}
                        </Typography>
                      </Box>
                    )}
                    {entry.owner_active &&
                      entry.enquirer_active &&
                      ["expired", "deleted", "blocked"].includes(
                        entry.ad_status
                      ) && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{
                            border: "none",
                            minWidth: "50%",
                            textAlign: "center",
                          }}
                        >
                          {entry.ad_status === "expired"
                            ? "Skelbimas nebegalioja"
                            : ""}
                          {entry.ad_status === "deleted"
                            ? "Skelbimas ištrintas"
                            : ""}
                          {entry.ad_status === "blocked"
                            ? "Skelbimas užblokuotas"
                            : ""}
                        </Typography>
                      )}
                    {(!entry.owner_active || !entry.enquirer_active) && (
                      <Typography
                        variant="body2"
                        sx={{
                          border: "none",
                          minWidth: "50%",
                          textAlign: "center",
                        }}
                        color="error"
                      >
                        Neaktyvi paskyra
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {!!entry.memo && (
          <>
            <Divider />
            <Box sx={{ p: 0.5, display: "flex", overflowX: "hidden" }}>
              <EventNoteIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography variant="caption">{entry.memo}</Typography>
            </Box>
          </>
        )}
      </Paper>

      {openEntryChangeDialog && (
        <AppointmentDialog
          openAppointmentDialog={openEntryChangeDialog}
          setOpenAppointmentDialog={setOpenEntryChangeDialog}
          initialAppointmentDateTime={new Date(entry.calendar_date)}
          privateCalendarEntry={!!entry.private ? entry : null}
        />
      )}

      {openPrivateEntryChangeDialog && (
        <PrivateCalendarEntryChangeDialog
          openPrivateEntryChangeDialog={openPrivateEntryChangeDialog}
          setOpenPrivateEntryChangeDialog={setOpenPrivateEntryChangeDialog}
          initialAppointmentDateTime={new Date(entry.calendar_date)}
          initialCalendarEntry={entry}
        />
      )}

      {openEntryDeleteDialog && (
        <CalendarEntryDeleteDialog
          entry={entry}
          open={openEntryDeleteDialog}
          setOpen={setOpenEntryDeleteDialog}
        />
      )}
    </>
  );
}

export default CalendarEntry;
