import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";
import { Button, Stack } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { getLocalDate } from "../../../utils/humanizeDate";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadInvoicePDF } from "../fetchOrSendDashboardData";
import InvoiceTemplate from "./InvoiceTemplate";

export default function MyOrdersTableRow(props) {
  const { orderAd, sum } = props;
  const { dashboardState } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const { isMobile } = useCustomMediaQuery();
  const [invoiceData, setInvoiceData] = useState(null);

  let myOrders = dashboardState.myOrders;
  myOrders.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "none" },
          borderBottom: "none",
        }}
      >
        <TableCell sx={{ width: 16, borderBottom: "none" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 0,
            borderBottom: "none",
          }}
        >
          <Box
            component="img"
            src={getFullImgUrl(orderAd.photo)}
            height="32px"
            width="auto"
            sx={{ my: 0, mx: 1 }}
          />
          <Stack sx={{ width: "100%", position: "relative" }}>
            <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
              {orderAd.ad_title}
            </Typography>
            <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
              {orderAd.ad_subtitle}
            </Typography>
          </Stack>
        </TableCell>
        {/* <TableCell align="center">Kiekis: {quantity}</TableCell> */}
        {(!isMobile || open) && (
          <TableCell
            align="center"
            sx={{ borderBottom: "none", whiteSpace: "nowrap" }}
          >
            Iš viso: {sum} €
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                gutterBottom
                component="div"
              >
                Užsakymai
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      Data
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      Sąskaita-faktūra
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      Paslauga
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      Trukmė (d.)
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      Suma (€)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myOrders
                    .filter((order) => order.ad === orderAd.ad)
                    .map((order) => (
                      <TableRow
                        key={order.id}
                        sx={{
                          "& > *": {
                            borderBottom: "none",
                            borderTop: "solid lightgrey 1px",
                          },
                          borderBottom: "none",
                        }}
                        // sx={{
                        //   borderTop: "solid 1px",
                        // }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ whiteSpace: "nowrap", borderBottom: "none" }}
                        >
                          {getLocalDate(order.created_at)}
                        </TableCell>
                        <TableCell
                          sx={{ whiteSpace: "nowrap", borderBottom: "none" }}
                        >
                          <Button
                            size="small"
                            endIcon={<DownloadIcon fontSize="small" />}
                            onClick={() =>
                              downloadInvoicePDF(order.id, setInvoiceData)
                            }
                          >
                            {order.invoice_number}
                          </Button>
                        </TableCell>
                        <TableCell
                          sx={{ whiteSpace: "nowrap", borderBottom: "none" }}
                        >
                          {order.payment.description}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", borderBottom: "none" }}
                        >
                          {order.payment.duration}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", borderBottom: "none" }}
                        >
                          {order.payment.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  {/* {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
        {!!invoiceData && (
          <InvoiceTemplate
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
          />
        )}
      </TableRow>
    </>
  );
}
