import axiosInstance from "../../../utils/axios";

export function fetchNotifications(dispatchNotification) {
  dispatchNotification({
    type: "CHANGE_NOTIFICATION_STATE",
    payload: { notificationsLoading: true },
  });
  axiosInstance
    .get(`api/notifications/`)
    .then((res) => {
      // console.log("server response: fetch notifications", res.data);
      dispatchNotification({
        type: "CHANGE_NOTIFICATION_STATE",
        payload: { notifications: res.data, notificationsLoaded: true },
      });
    })
    .catch((err) => {
      // console.log("server response: Error fetching notifications", err);
    })
    .finally(() => {
      dispatchNotification({
        type: "CHANGE_NOTIFICATION_STATE",
        payload: { notificationsLoading: false },
      });
    });
}

export function deleteNotifications(
  dispatchNotification,
  notificationId,
  dismissAll
) {
  dispatchNotification({
    type: "CHANGE_NOTIFICATION_STATE",
    payload: { notificationsDeleting: true },
  });
  axiosInstance
    .delete(`api/notifications/${notificationId}/`, {
      params: { dismiss_all: dismissAll },
    })
    .then((res) => {
      // console.log(
      //   "server response: successfully deleted notifications",
      //   res.data
      // );
      dispatchNotification({
        type: "CLEAR_NOTIFICATIONS",
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log("server response: Error while deleting notifications", err);
    })
    .finally(() => {
      dispatchNotification({
        type: "CHANGE_NOTIFICATION_STATE",
        payload: { notificationsDeleting: false },
      });
    });
}

export function fetchLocationData(form, setFetchedLocationData) {
  const url = `api/addresses/get-location/`;
  axiosInstance
    .get(url, { params: form })
    .then((res) => {
      // console.log("server response: fetched location data", res.data);
      setFetchedLocationData(res.data);
    })
    .catch(function (error) {
      // console.log("Failed to fetch location", error);
    });
}

export function searchLocation(form, setSearchOptions) {
  const url = `api/addresses/search/`;
  axiosInstance
    .get(url, { params: form })
    .then((res) => {
      // console.log("server response: location search results", res.data);
      setSearchOptions(JSON.parse(res.data));
    })
    .catch(function (error) {
      // console.log(
      //   "server response: Failed to get location search results",
      //   error
      // );
    });
}

export function registerCookieConsent(form, dispatchUser) {
  const url = `api/policies/cookie-consent/`;
  axiosInstance
    .post(url, form)
    .then((res) => {
      // console.log("server response: cookie consent registered", res.data);
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { cookieConsentGiven: true },
      });
    })
    .catch((error) => {
      // console.log("server response: failed to register cookie consent", error);
    });
}
