import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
// import {StaticDateTimePicker} from "@mui/x-date-pickers/StaticDateTimePicker";

// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

// import {DesktopDateTimePicker} from "@mui/x-date-pickers/DesktopDateTimePicker";
// import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import lt from "date-fns/locale/lt";
import { Badge } from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import {
  // DatePicker,
  // DesktopDatePicker,
  StaticDatePicker,
} from "@mui/x-date-pickers";

function CalendarDatePicker({
  setFilteredEntriesOnGivenDate,
  selectedFilterValue,
}) {
  const { dispatchDashboard, dashboardState } = useGlobalContext();
  const [date, setDate] = useState(dashboardState.calendarDate);
  const [highlightedDays, setHighlightedDays] = useState(
    dashboardState.myCalendarEntries.map(
      // (entry) => new Date(entry.calendar_date).toISOString().split("T")[0]
      (entry) => {
        const date = new Date(entry.calendar_date);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")}`;
      }
    )
  );

  // get filtered calendar entries for selected date
  useEffect(() => {
    let filteredEntries = dashboardState.myCalendarEntries;
    if (!!selectedFilterValue?.ad) {
      filteredEntries = filteredEntries.filter(
        (entry) => entry.ad === selectedFilterValue.ad
      );
    }
    if (!!selectedFilterValue?.owner) {
      filteredEntries = filteredEntries.filter(
        (entry) => entry.owner === selectedFilterValue.owner
      );
    }
    if (!!selectedFilterValue?.enquirer) {
      filteredEntries = filteredEntries.filter(
        (entry) => entry.enquirer === selectedFilterValue.enquirer
      );
    }
    setHighlightedDays(
      filteredEntries.map(
        // (entry) => new Date(entry.calendar_date).toISOString().split("T")[0]
        (entry) => {
          const date = new Date(entry.calendar_date);
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
            2,
            "0"
          )}-${String(date.getDate()).padStart(2, "0")}`;
        }
      )
    );
    let filteredEntriesOnGivenDate = filteredEntries.filter(
      (entry) =>
        new Date(entry.calendar_date).toDateString() === date.toDateString()
    );
    filteredEntriesOnGivenDate.sort((a, b) =>
      a.calendar_date.localeCompare(b.calendar_date)
    );
    setFilteredEntriesOnGivenDate(filteredEntriesOnGivenDate);
  }, [
    date,
    dashboardState.myCalendarEntries,
    dashboardState.updatedCalendarEntry,
    setFilteredEntriesOnGivenDate,
    selectedFilterValue,
  ]);

  function countAppointments(props) {
    const { highlightedDays, day, ...other } = props;
    day.setHours(12);

    const numEntries = highlightedDays.filter(
      (entry) => entry === day.toISOString().split("T")[0]
    ).length;

    return (
      <Badge
        color="secondary"
        badgeContent={numEntries}
        key={day.toString()}
        overlap="circular"
        sx={{
          "& .MuiBadge-badge": {
            fontSize: 9,
            minHeight: 12,
            minWidth: 12,
            height: 12,
            width: 12,
          },
        }}
      >
        <PickersDay day={day} {...other} />
      </Badge>

      // <Badge
      //   key={props.day.toString()}
      //   overlap="circular"
      //   badgeContent={isSelected ? "🌚" : undefined}
      // >
      //   <PickersDay
      //     {...other}
      //     outsideCurrentMonth={outsideCurrentMonth}
      //     day={day}
      //   />
      // </Badge>
    );
  }

  // function countAppointments(day, _value, dayComponentProps) {
  //   let dateTile;
  //   let currentMonth = !dayComponentProps.outsideCurrentMonth;
  //   let calendarDates = filteredCalendarEntries.map(
  //     (entry) => entry.calendar_date
  //   );
  //   calendarDates = calendarDates.map((entry) =>
  //     new Date(entry).toDateString()
  //   );

  //   let numDailyEntries = calendarDates.filter(
  //     (entry) => entry === day.toDateString()
  //   ).length;

  //   dateTile = (
  //     <Badge
  //       color="secondary"
  //       badgeContent={currentMonth ? numDailyEntries : 0}
  //       key={day.toString()}
  //       overlap="circular"
  //       sx={{
  //         "& .MuiBadge-badge": {
  //           fontSize: 9,
  //           minHeight: 12,
  //           minWidth: 12,
  //           height: 12,
  //           width: 12,
  //         },
  //       }}
  //     >
  //       <PickersDay {...dayComponentProps} />
  //     </Badge>
  //   );
  //   return dateTile;
  // }

  function handleChange(newDate) {
    setDate(newDate);
    dispatchDashboard({
      type: "SET_CALENDAR_DATE",
      payload: { calendarDate: newDate },
    });
  }

  // console.log("highlightedDays", highlightedDays);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={lt}>
      <Box
        sx={{
          display: "block",
          border: "none",
        }}
      >
        <StaticDatePicker
          value={date}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          // renderDay={countAppointments}
          inputFormat="yyyy/MM/dd"
          //   disablePast
          //   ampm={false}
          mask="____/__/__"
          slots={{
            day: countAppointments,
          }}
          slotProps={{
            actionBar: {
              actions: [],
            },
            day: { highlightedDays },
          }}
          sx={{
            ".MuiTypography-overline": {
              display: "none",
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default CalendarDatePicker;
