import React from "react";
import { useField } from "formik";
import { FormControlLabel, Switch } from "@mui/material";

const FormikSwitch = ({ label, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);

  function handleSwitchChange(e) {
    helpers.setValue(Number(e.target.checked));
    if (!!onChange) {
      onChange(e);
    }
  }

  // if (field.name === "distance")
  //   console.log("field, meta, helpers", field, meta, helpers);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={!!field.value}
          {...field}
          color="primary"
          onChange={handleSwitchChange}
          {...props}
        />
      }
      label={label}
      //   labelPlacement="start"
    />
  );
};

export default FormikSwitch;
