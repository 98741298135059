import React, { useEffect, useState } from "react";
import "chartjs-adapter-date-fns";
import { lt } from "date-fns/locale";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGlobalContext } from "../../../context";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { fetchAdHistory } from "../fetchOrSendAdData";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const AccordionHistory = ({ ad: adProp }) => {
  const { state, dispatch } = useGlobalContext();
  const [expanded, setExpanded] = useState(false);
  const { isMobile } = useCustomMediaQuery();
  const [ad, setAd] = useState(adProp);

  // console.log("ad from AccordionHistory", ad);
  let actions = !!ad?.properties?.ad_actions
    ? [...ad.properties.ad_actions.sort((a, b) => (a.id > b.id ? 1 : -1))]
    : [];

  useEffect(() => {
    fetchAdHistory(dispatch, adProp, setAd);
  }, [adProp.id]);

  let actionTextArray = [];
  actions.forEach((action, index) => {
    if (index === 0) {
      actionTextArray[0] = `${
        action.timestamp.split("T")[0]
      }: skelbimas publikuotas`;
    }
    if (
      index > 0 &&
      action.status !== "inactive" &&
      action.price !== actions[index - 1].price
    ) {
      let pChange = Math.round(
        (action.price / actions[index - 1].price - 1) * 100,
        1
      );
      actionTextArray.push(
        `${action.timestamp.split("T")[0]}: kaina ${
          pChange > 0 ? "padidinta" : "sumažinta"
        } ${Math.abs(pChange)}% iki ${action.price} €`
      );
    }
    if (index > 0 && action.status !== actions[index - 1].status) {
      actionTextArray.push(
        `${action.timestamp.split("T")[0]}: statusas pakeistas į "${
          action.status
        }"`
      );
    }
  });

  const currentDataPoint = { ...actions[actions.length - 1] };
  actions.push(currentDataPoint);
  actions[actions.length - 1].timestamp = new Date().toLocaleDateString("lt");

  let timeLabels = actions.map((action) =>
    new Date(action.timestamp.split("T")[0]).toLocaleDateString("lt")
  );

  const activeData = actions.map((action, index) =>
    action.status === "Aktyvus" ||
    actions[Math.max(index - 1, 0)].status === "Aktyvus"
      ? +action.price
      : null
  );
  const inactiveData = actions.map((action, index) =>
    ["Neaktyvus", "Ištrintas", "Blokuotas", "Baigė galioti"].includes(
      action.status
    ) ||
    ["Neaktyvus", "Ištrintas", "Blokuotas", "Baigė galioti"].includes(
      actions[Math.max(index - 1, 0)].status
    )
      ? +action.price
      : null
  );
  const reservedData = actions.map((action, index) =>
    action.status === "Rezervuotas" ||
    actions[Math.max(index - 1, 0)].status === "Rezervuotas"
      ? +action.price
      : null
  );
  const soldData = actions.map((action, index) =>
    action.status === "Parduotas" ||
    actions[Math.max(index - 1, 0)].status === "Parduotas"
      ? +action.price
      : null
  );
  const rentedData = actions.map((action, index) =>
    action.status === "Išnuomotas" ||
    actions[Math.max(index - 1, 0)].status === "Išnuomotas"
      ? +action.price
      : null
  );
  const priceData = actions.map((action) => +action.price);

  const chartData = {
    labels: timeLabels,
    datasets: [
      {
        label: "Objektas rezervuotas",
        data: reservedData,
        backgroundColor: "rgba(255, 165, 0, 1)",
        borderColor: "rgba(255, 165, 0, 1)",
        borderWidth: 2,
        stepped: true,
        pointRadius: 0,
        pointHitRadius: 0,
      },
      {
        label: "Skelbimas neaktyvus",
        data: inactiveData,
        backgroundColor: "rgba(128, 128, 128, 1)",
        borderColor: "rgba(128, 128, 128, 1)",
        borderWidth: 2,
        stepped: true,
        pointRadius: 0,
        pointHitRadius: 0,
      },
      {
        label: "Objektas parduotas",
        data: soldData,
        backgroundColor: "rgba(255, 0, 0, 1)",
        borderColor: "rgba(255, 0, 0, 1)",
        borderWidth: 2,
        stepped: true,
        pointRadius: 0,
        pointHitRadius: 0,
      },
      {
        label: "Objektas išnuomotas",
        data: rentedData,
        backgroundColor: "rgba(255, 0, 0, 1)",
        borderColor: "rgba(255, 0, 0, 1)",
        borderWidth: 2,
        stepped: true,
        pointRadius: 0,
        pointHitRadius: 0,
      },
      {
        label: "Skelbimas aktyvus",
        data: activeData,
        backgroundColor: "rgba(0, 128, 0, 1)",
        borderColor: "rgba(0, 128, 0, 1)",
        borderWidth: 2,
        stepped: true,
        pointRadius: 0,
        pointHitRadius: 0,
      },

      {
        // points
        data: priceData,
        backgroundColor: "grey",
        borderColor: "grey",
        borderWidth: 2,
        stepped: true,
        pointRadius: 2,
        pointHitRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: isMobile ? 1 : 2,
    plugins: {
      tooltip: {
        usePointStyle: true,
        // intersect: false, // makes it easier to select tooltips on mobile
        callbacks: {
          title: (tooltipObject) => {
            const title = new Date(
              tooltipObject[0].parsed.x
            ).toLocaleDateString("lt");
            return title;
          },
          label: (tooltipObject) => {
            // console.log("tooltipObject", tooltipObject);
            const label = `Kaina: ${tooltipObject.formattedValue} €`;
            return label;
          },
          afterLabel: function (tooltipItem, data) {
            // console.log("tooltipItem, data", tooltipItem, data);
            // Return value will be displayed after the label
            return `Statusas: ${
              ad.properties.ad_actions[tooltipItem.dataIndex]?.status ||
              ad.properties.ad_actions[tooltipItem.dataIndex - 1]?.status
            }`;
          },
        },
      },

      legend: {
        position: "bottom",
        labels: {
          filter: function (legendItem, chartData) {
            const hasData = chartData.datasets[
              legendItem.datasetIndex
            ].data.some((x) => !!x);
            return hasData && chartData.datasets[legendItem.datasetIndex].label;
          },
        },
      },
    },
    scales: {
      yAxis: {
        grace: "1%",
        ticks: {
          callback: function (value) {
            if (Math.floor(value) === value) {
              return value;
            }
          },
        },
      },
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        adapters: {
          date: {
            locale: lt,
          },
        },
      },
    },
  };

  function handleExpansionChange(event, expanded) {
    setExpanded(expanded);
  }

  return (
    <>
      {
        ad.properties.ad_type !== "Naujas daugiabučių projektas" && (
          <Accordion
            expanded={expanded}
            onChange={handleExpansionChange}
            sx={{ width: "100%" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="button"
                color={expanded ? "primary" : "default"}
              >
                Skelbimo istorija
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: "block" }}>
              <Line data={chartData} options={chartOptions} />
              <Box sx={{ mt: 4 }}>
                {actionTextArray.map((actionText, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", my: 1 }}
                    >
                      <ArrowRightIcon />
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        {actionText}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        )
        // </div>}
      }
    </>
  );
};

export default AccordionHistory;
