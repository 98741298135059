import React, { useState } from "react";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Box } from "@mui/system";
import AdViewHistory from "./AdViewHistory";
import { useGlobalContext } from "../../../../context";
import { fetchAdViewHistory } from "../../fetchOrSendDashboardData";

export default function ViewsLikesHistory({ ad }) {
  const [openViewHistory, setOpenViewHistory] = useState(false);
  const { dashboardState, dispatchDashboard } = useGlobalContext();

  // console.log("openViewHistory", openViewHistory, ad.id);

  function handleOpenViewHistory() {
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: {
        adViewHistoryLoading: true,
        adViewHistoryLoaded: false,
      },
    });
    fetchAdViewHistory(dispatchDashboard, ad.id);
    setOpenViewHistory(true);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDiredion: "row",
          justifyContent: "space-between",
          alignItems: "center",
          border: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 0,
            minWidth: 0,
          }}
        >
          <VisibilityIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
          <Typography variant="caption" sx={{ display: "inline" }}>
            Peržiūros (šiandien/iš viso):
            <Typography component="span" variant="caption" sx={{ ml: 0.5 }}>
              {Number(ad.properties.views_today)}/
              {Number(ad.properties.views_total)}
            </Typography>
          </Typography>
        </Box>
        <Button
          variant="outlined"
          size="small"
          startIcon={<TimelineIcon color="action" />}
          onClick={handleOpenViewHistory}
          sx={{
            mx: 0.5,
            minWidth: "120px",
            width: "120px",
            border: "solid lightgrey 1px",
          }}
          color="inherit"
        >
          <Typography variant="caption">Istorija</Typography>
        </Button>
        <Backdrop
          open={openViewHistory && !!dashboardState.adViewHistoryLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        {openViewHistory && !dashboardState.adViewHistoryLoading && (
          <AdViewHistory
            open={openViewHistory}
            setOpen={setOpenViewHistory}
            ad={ad}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", border: "none" }}>
        <FavoriteBorder fontSize="small" color="action" sx={{ mr: 0.5 }} />

        <Typography variant="caption">Įsiminė (šiandien/iš viso):</Typography>
        <Typography variant="caption" sx={{ ml: 0.5 }}>
          {Number(ad.properties.likes_today)}/
          {Number(ad.properties.likes_total)}
        </Typography>
      </Box>
    </>
  );
}
