import { Button, Grid, Menu } from "@mui/material";

export default function MunicipalityFilterChip({
  formikRef,
  anchorEl,
  setAnchorEl,
  children,
}) {
  return (
    <Menu
      anchor="top"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      getContentAnchorEl={null}
      // keepMounted
      variant="menu"
      open={!!anchorEl}
      onClose={() => formikRef.current.handleSubmit()}
      sx={{ m: 1, p: 1 }}
    >
      <Grid container sx={{ m: 1, width: 300, height: "auto" }}>
        <Grid container item>
          {children}
        </Grid>
        <Grid container item justifyContent="right" sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setAnchorEl(null)}
            sx={{ mr: 1 }}
          >
            Atšaukti
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => formikRef.current.submitForm()}
          >
            Gerai
          </Button>
        </Grid>
      </Grid>
    </Menu>
  );
}
