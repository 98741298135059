import { useGlobalContext } from "../../../context";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";

function usePhotoData(logo, edit, photoContainerWidth) {
  const { state, userState, adFormState, dispatch, dispatchAdForm } =
    useGlobalContext();
  const dispatchX = edit ? dispatch : dispatchAdForm;

  const ad = edit ? state.ad : adFormState.adDraft;

  const photoObj = logo ? ad?.properties?.logo : userState.currentUser;
  const photoUrl = getFullImgUrl(logo ? photoObj?.logo : photoObj?.photo);
  const cropUrl = getFullImgUrl(
    logo ? photoObj?.logo_thumbnail : photoObj?.photo_crop
  );
  const initialScale = photoObj.scale;
  const initialRotation = photoObj.rotation;
  const initialPosition = { x: photoObj.position_x, y: photoObj.position_y };
  const aspectRatio = Math.max(
    photoObj.width / photoObj.height,
    photoObj.height / photoObj.width
  );
  const orientation =
    photoObj?.width >= photoObj?.height
      ? initialRotation === 0 || initialRotation === 180
        ? "landscape"
        : "portrait"
      : initialRotation === 0 || initialRotation === 180
      ? "portrait"
      : "landscape";
  const width = logo
    ? orientation === "landscape"
      ? photoContainerWidth
      : photoContainerWidth / aspectRatio
    : photoContainerWidth;
  const height = logo
    ? orientation === "landscape"
      ? photoContainerWidth / aspectRatio
      : photoContainerWidth
    : photoContainerWidth;
  const minScale =
    (photoObj.width / photoObj.height >= 1 && orientation === "portrait") ||
    (photoObj.width / photoObj.height < 1 && orientation === "landscape")
      ? 1 / aspectRatio
      : 1;

  return {
    ad,
    photoObj,
    photoUrl,
    cropUrl,
    initialScale,
    initialRotation,
    initialPosition,
    aspectRatio,
    width,
    height,
    minScale,
    dispatchX,
  };
}

export default usePhotoData;
