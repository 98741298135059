import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import { blue } from "@mui/material/colors";
import { useHistory } from "react-router-dom";
import useBackHandler from "../../../hooks/useBackHandler";

export default function ActivatedMapDialog({ children }) {
  const { state, dispatch } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const params = useParamsFromUrl();
  const history = useHistory();
  useBackHandler(handleDialogXClick);

  function handleDialogOkClick() {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { mapDialogOkClick: true },
    });
  }

  function handleDialogXClick() {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { drawPolygonMode: false, drawPlacesMode: false },
    });
  }

  return (
    <Dialog
      open={!!state?.drawPlacesMode || !!state?.drawPolygonMode}
      container={() => document.getElementById("map-box")}
      maxWidth="xl"
      fullScreen={isMobile || !state.showMap}
      sx={isMobile || !state.showMap ? {} : { position: "absolute" }}
      PaperProps={
        isMobile || !state.showMap
          ? {}
          : {
              sx: {
                position: "absolute",
                maxHeight: "100%",
                width: `${
                  state.mapContainerRef.current.getBoundingClientRect().width
                }px`,
                height: `${
                  state.mapContainerRef.current.getBoundingClientRect().height
                }px`,
                m: 0,
              },
            }
      }
    >
      {state.drawPolygonMode && (
        <DialogTitle
          variant="h6"
          color="primary.text"
          sx={{ backgroundColor: blue[100] }}
        >
          {!!params?.shape_type ? "Keiskite" : "Apibrėžkite"} aktualią
          teritoriją
        </DialogTitle>
      )}
      {state.drawPlacesMode && (
        <DialogTitle
          variant="h6"
          color="primary.text"
          sx={{ backgroundColor: blue[100] }}
        >
          {params.place_coords ? "Keiskite" : "Pažymėkite"} aktualias vietas
        </DialogTitle>
      )}
      <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
      <DialogActions sx={{ justifyContent: "right" }}>
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
              handleDialogXClick();
            }}
            sx={{ mr: 1 }}
          >
            Atšaukti
          </Button>
          <Button variant="contained" onClick={handleDialogOkClick}>
            Gerai
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
