import React, { useState, useEffect } from "react";
import { Chip, Grid, Typography } from "@mui/material";
import AdTypeFilter from "./AdTypeFilter";
import { AD_TYPE_OPTIONS } from "../../../../utils/adOptions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { useGlobalContext } from "../../../../context";

export default function AdTypeFilterChip() {
  const params = useParamsFromUrl();
  const { state } = useGlobalContext();

  const [chipLabel, setChipLabel] = useState("Butai");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    changeChipLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.ad_type]);

  // const handleChipClick = (event) => {
  //   if (isMediumOrSmaller) {
  //     setAnchorEl(state.leftMenuRef.current);
  //   } else {
  //     setAnchorEl(event.currentTarget);
  //   }
  // };

  function changeChipLabel() {
    let chipLabel =
      AD_TYPE_OPTIONS.find((option) => option.value === params.ad_type)
        ?.label || "Butai";
    setChipLabel(chipLabel);
  }

  // console.log("leftMenuRef from AdTypeFilterChip", state?.leftMenuRef?.current);

  return (
    <>
      <Chip
        label={
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <Typography fontSize="small">{chipLabel}</Typography>
            {anchorEl ? (
              <ArrowDropUpIcon
                fontSize="small"
                color="action"
                sx={{ mr: -1 }}
              />
            ) : (
              <ArrowDropDownIcon
                fontSize="small"
                color="action"
                sx={{ mr: -1 }}
              />
            )}
          </Grid>
        }
        color={anchorEl ? "primary" : "default"}
        onClick={(e) =>
          setAnchorEl(state?.leftMenuRef?.current || e.currentTarget)
        }
        variant="outlined"
        sx={{ borderRadius: 1, mr: 1 }}
      />
      {!!anchorEl && (
        <AdTypeFilter anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      )}
    </>
  );
}
