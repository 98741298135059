import React, { useCallback, useMemo, useState } from "react";
import { Alert, Button, IconButton, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import { updateUserData } from "../fetchOrSendAccountsData";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-image-crop/dist/ReactCrop.css";
import { blue } from "@mui/material/colors";
import { useDropzone } from "react-dropzone";
import { crudCompanlyLogo } from "../../AdForm/fetchOrSendAdDraftData";

const MAX_FILE_SIZE = 1024 * 1024 * 13;
export const MAX_FILES = 1;

const errorObject = {
  "too-many-files": "Per daug failų",
  "file-too-large": "Per didelis failas",
  "file-invalid-type": "Netinkamas failo formatas",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#00e676",
};

function PhotoUploader({ ad, photoContainerWidth, logo, edit }) {
  const { userState, dispatchUser, dispatchAdForm, dispatch } =
    useGlobalContext();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [errorText, setErrorText] = useState("");

  const baseStyle = {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: "20px",
    borderWidth: 2,
    borderRadius: logo ? "3px" : "50%",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    backgroundColor: blue[50],
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    // console.log("acceptedFiles", acceptedFiles);
    if (!!rejectedFiles.length) {
      setErrorText(errorObject[rejectedFiles[0].errors[0].code] || "Klaida");
      setShowSnackBar(true);
    }
    if (!!acceptedFiles.length) {
      // console.log("acceptedFiles from PhotoUploader", acceptedFiles);
      let file = acceptedFiles[0];
      // console.log("file from user profile PhotoUploader", file);
      let img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        let formData = new FormData();
        formData.append("width", img.width);
        formData.append("height", img.height);
        formData.append("position_x", 0.5);
        formData.append("position_y", 0.5);
        formData.append("scale", 1);
        formData.append("rotation", 0);
        formData.append(logo ? "logo" : "photo", file);
        logo
          ? crudCompanlyLogo(
              ad.id,
              edit ? dispatch : dispatchAdForm,
              formData,
              "POST"
            )
          : updateUserData(userState.userId, dispatchUser, formData, () => {});
      };
    }
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: ["image/*"],
      maxFiles: MAX_FILES,
      maxSize: MAX_FILE_SIZE,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Box
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "right",
          width: "100%",
          alignItems: "center",
        }}
      >
        <IconButton size="small" sx={{ visibility: "hidden" }}>
          <CancelIcon color="action" sx={{ border: "none" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          // py: 1,
        }}
      >
        <Box
          sx={{
            // border: "solid grey 1px",
            borderRadius: logo ? "3px" : "50%",
            width: "100%",
            height: photoContainerWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,.05)",
          }}
        >
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box
              // container
              sx={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                m: "auto",
              }}
            >
              <AddAPhotoIcon color="info" sx={{ fontSize: "72px" }} />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ width: "70%", textAlign: "center" }}
              >
                Įkelkite arba vilkite nuotrauką čia
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: "none",
          flexGrow: 0,
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          py: 0.5,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          component="label"
          sx={{ visibility: "hidden" }}
        >
          Įkelti nuotrauką
          <input type="file" hidden />
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showSnackBar}
        onClose={() => setShowSnackBar(false)}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() => setShowSnackBar(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PhotoUploader;
