import { Divider, Grid, Typography, createFilterOptions } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import {
  fetchStreetOptions,
  fetchSuburbOptions,
  fetchTownOptions,
} from "../../fetchOrSendMainAppData";
import FormikMultipleAutocomplete from "../../../SharedComponents/FormikMultipleAutocomplete";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
// import FormPreview from "../../../AdForm/utils/FormPreview";

function LocationFilter() {
  const { state, dispatch } = useGlobalContext();
  const formik = useFormikContext();

  const [muniCenter, setMuniCenter] = useState();

  const [townOptions, setTownOptions] = useState(state.townOptions);
  const [suburbOptions, setSuburbOptions] = useState(state.suburbOptions);
  const [streetOptions, setStreetOptions] = useState(state.streetOptions);

  useEffect(() => {
    setTownOptions(state.townOptions);
  }, [state.townOptions]);

  useEffect(() => {
    setSuburbOptions(state.suburbOptions);
  }, [state.suburbOptions]);

  useEffect(() => {
    setStreetOptions(state.streetOptions);
  }, [state.streetOptions]);

  // add municipality center town as municipality changes
  useEffect(() => {
    if (!!muniCenter && !!townOptions.length) {
      const muniCenterId = townOptions
        .find((option) => option.name === muniCenter)
        ?.id.toString();
      formik.setFieldValue("town", muniCenterId);
      handleTownChange(muniCenterId);
    }
  }, [muniCenter, townOptions]);

  function handleMunicipalityChange(municipality) {
    const muniCenter = state.municipalityOptions.find(
      (option) => option.id.toString() === municipality
    )?.town;
    setMuniCenter(muniCenter);
    formik.setFieldValue("town", "");
    formik.setFieldValue("suburb", "");
    formik.setFieldValue("street", "");
    setTownOptions([]);
    setSuburbOptions([]);
    setStreetOptions([]);
    if (municipality.split(",").length === 1) {
      fetchTownOptions({ municipality }, dispatch, setTownOptions);
    }
  }

  function handleTownChange(town) {
    formik.setFieldValue("suburb", "");
    formik.setFieldValue("street", "");
    setSuburbOptions([]);
    setStreetOptions([]);
    if (town.split(",").length === 1) {
      fetchSuburbOptions({ town }, dispatch, setSuburbOptions);
      fetchStreetOptions({ town }, dispatch, setStreetOptions);
    }
  }

  function handleSuburbChange(suburb) {
    formik.setFieldValue("street", "");
  }

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  // console.log("townOptions from LocationFilter", townOptions);

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Vieta
          </Typography>
        </Divider>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FormikMultipleAutocomplete
            name="municipality"
            label="Savivaldybė"
            options={state.municipalityOptions || []}
            onChange={handleMunicipalityChange}
            filterOptions={filterOptions}
            autoFocus={false}
            initiallyClosed={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormikMultipleAutocomplete
            name="town"
            label="Gyvenvietė"
            options={townOptions}
            onChange={handleTownChange}
            filterOptions={filterOptions}
            disabled={!townOptions.length}
            autoFocus={false}
            initiallyClosed={true}
          />
        </Grid>
        {!!suburbOptions.length && (
          <Grid item xs={12} lg={6}>
            <FormikMultipleAutocomplete
              name="suburb"
              label="Mikrorajonas"
              options={suburbOptions}
              onChange={handleSuburbChange}
              filterOptions={filterOptions}
              disabled={!suburbOptions.length}
              autoFocus={false}
              initiallyClosed={true}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <FormikMultipleAutocomplete
            name="street"
            label="Gatvė"
            options={streetOptions}
            disabled={!streetOptions.length}
            autoFocus={false}
            initiallyClosed={true}
          />
        </Grid>
      </Grid>
      {/* <FormPreview /> */}
    </>
  );
}

export default LocationFilter;
