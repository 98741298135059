import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  getAddress,
  getAreaRange,
  getBuildingType,
  getInstallation,
  getPriceRangeApartmentComplex,
  getPriceSqmRangeApartmentComplex,
  getRoomsRange,
} from "./utils";
import MapButton from "./MapButton";

function BasicAdInfoApartmentComplex({ ad, setShowMap, showMap }) {
  const address = getAddress(ad);
  const areaRange = getAreaRange(ad, 6, 6);
  const roomsRange = getRoomsRange(ad, 6, 6);
  const buildingType = getBuildingType(ad, 6, 6);
  const installation = getInstallation(ad, 6, 6);
  const priceRangeApartmentComplex = getPriceRangeApartmentComplex(ad, 6, 6);
  const priceSqmRangeApartmentComplex = getPriceSqmRangeApartmentComplex(
    ad,
    6,
    6
  );

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          justifyContent: "space-between",
          // display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ mt: 1, mx: 1 }}
            color="primary"
            variant="body2"
            fontSize={20}
          >
            {ad?.properties?.project_name
              ?.replaceAll("'", "")
              ?.replaceAll('"', "") || "Naujas projektas"}
          </Typography>
          <Typography sx={{ mt: 1, pt: 0.3 }} variant="body2">
            (vystytojas {ad.properties.company_name})
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          alignItems: "center",
          px: 1,
          mt: 0.2,
        }}
      >
        <Typography variant="caption">{address} </Typography>
        <MapButton setShowMap={setShowMap} showMap={showMap} />
      </Grid>

      <Grid container sx={{ my: 1, pl: 1, border: "none" }}>
        {roomsRange}
        {areaRange}
        {buildingType}
        {installation}
        {priceRangeApartmentComplex}
        {priceSqmRangeApartmentComplex}
      </Grid>
    </>
  );
}

export default BasicAdInfoApartmentComplex;
