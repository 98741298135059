import { Box, Container, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { fetchPrivacyPolicy } from "../MainPage/fetchOrSendMainAppData";
import { useGlobalContext } from "../../context";
import { grey } from "@mui/material/colors";
import Footer from "../Layout/Footer/Footer";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const { dispatch, state, dispatchRouter } = useGlobalContext();

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "privacyPolicy" },
    });
  }, [dispatchRouter]);

  useEffect(() => {
    if (!state.privacyPolicyLoaded && !state.privacyPolicyLoading) {
      fetchPrivacyPolicy(dispatch);
    }
  }, [state.privacyPolicyLoaded]);

  const formattedDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("lt-LT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <Box
      sx={{
        border: "none",
        backgroundColor: grey[200],
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pt: 13,
      }}
    >
      <Helmet>
        <title>Privatumo politika</title>
      </Helmet>
      <Container maxWidth="md" disableGutters sx={{ p: 0.5 }}>
        {state?.privacyPolicy?.text && (
          <Paper elevation={1} sx={{ p: 1 }}>
            <Typography
              variant="h6"
              textAlign="center"
              color="primary.dark"
              sx={{ mt: 2 }}
            >
              Privatumo politika
            </Typography>
            <Typography variant="body2" textAlign="center" color="grey">
              Atnaujinta {formattedDate(state.privacyPolicy.edited_at)}
            </Typography>
            <Typography
              sx={{ mt: 7 }}
              variant="body1"
              component="div"
              dangerouslySetInnerHTML={{ __html: state.privacyPolicy.text }}
            />
          </Paper>
        )}
      </Container>
      <Footer />
    </Box>
  );
}

export default PrivacyPolicy;
