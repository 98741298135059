import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RentChart = ({ ad, showPricePerSqM, draftPrice }) => {
  const { isMobile } = useCustomMediaQuery();

  const areaMultiplier = ad.properties.area;
  let price =
    ad.properties.status === "draft" ? draftPrice : ad.properties.price;
  price = price / (showPricePerSqM ? areaMultiplier : 1);
  const rentPriceEst =
    ad.properties.prediction.rent_price_sqm_pred *
    (showPricePerSqM ? 1 : areaMultiplier);
  const flatRentMape = ad.properties.prediction.rent_price_mape;

  const rentData = {
    // labels: [0, 0, 0],
    datasets: [
      ad.properties.ad_type === "Nuomojamas butas" && price
        ? {
            label: "Šio būsto nuomos kaina",
            data: [
              {
                // x: Math.round(price * (pricePerSqM ? 1 : 1000)),
                x: Math.round(price),
                y: 0,
                z: "Šio būsto nuomos kaina",
              },
            ],
            backgroundColor: "rgba(255, 99, 132, 1)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
          }
        : null,
      {
        label: "Tipinės nuomos kainos rinkoje",
        data: [
          {
            x: Math.round(rentPriceEst * (1 - flatRentMape) * 10) / 10,
            y: 0,
            z: "Vidurkis - standartinis nuokrypis",
          },
          { x: Math.round(rentPriceEst * 10) / 10, y: 0, z: "Vidurkis" },
          {
            x: Math.round(rentPriceEst * (1 + flatRentMape) * 10) / 10,
            y: 0,
            z: "Vidurkis + standartis nuokrypis",
          },
        ],
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
      },
    ].filter((dataset) => dataset),
  };

  const rentOptions = {
    // maintainAspectRatio: false,
    maintainAspectRatio: isMobile,
    responsive: true,
    aspectRatio: 2,
    plugins: {
      title: {
        display: false,
        text: `Panašaus būsto nuomos kainos skelbimuose (${
          showPricePerSqM ? "€/\u33A1 per mėnesį" : "€ per mėn."
        })`,
      },
      tooltip: {
        usePointStyle: true,
        intersect: false, // makes it easier to select tooltips on mobile
        callbacks: {
          title: (toolTipItem) => {
            const dataIndex = toolTipItem[0].dataIndex;
            let title = toolTipItem[0].dataset.data[dataIndex].z;
            return title;
          },
          label: (toolTipItem) => {
            // console.log("toolTipItem", toolTipItem.label);
            // const dataIndex = toolTipItem[0].dataIndex;
            let label = showPricePerSqM
              ? `${toolTipItem.label} €/\u33A1 per mėnesį`
              : `${toolTipItem.label} € per mėnesį`;
            return label;
          },
        },
      },
      legend: {
        display: ad.properties.ad_type === "Nuomojamas butas" ? true : false,
        position: "bottom",
        labels: { usePointStyle: true, pointStyle: "circle", boxWidth: 6 },
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
      },
      y: {
        display: false,
      },
    },
  };

  // console.log("price from RentChart", price);

  return (
    <Line
      data={rentData}
      height={150}
      width={600}
      options={rentOptions}
      //   style={{ marginTop: "50px" }}
    />
  );
};

export default RentChart;
