import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import FormStep3 from "../../../AdForm/Step3AdSettings/FormStep3";
import useBackHandler from "../../../../hooks/useBackHandler";
import { useHistory } from "react-router-dom";

export default function AdExtensionDialog({ open, setOpen, ad }) {
  const { isMobile } = useCustomMediaQuery();
  const history = useHistory();
  useBackHandler(handleClose);

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      {
        <Dialog
          open={open}
          onClose={() => {
            history.goBack();
            handleClose();
          }}
          maxWidth="sm"
          fullScreen={isMobile}
          //   PaperProps={{ sx: { minWidth: 200 } }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Pratęskite skelbimo galiojimą
          </DialogTitle>
          <DialogContent>
            <FormStep3 ad={ad} setOpen={setOpen} />
          </DialogContent>
        </Dialog>
      }
    </>
  );
}
