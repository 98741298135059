import React, { useEffect } from "react";
import { fetchMyOrders } from "../fetchOrSendDashboardData";
import { useGlobalContext } from "../../../context";
import { Box, Container, Paper, Typography } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import { useParams } from "react-router-dom";
import { blue } from "@mui/material/colors";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import MyOrdersTable from "./MyOrdersTable";

export default function MyOrders() {
  const { dashboardState, dispatchDashboard } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const { tabName } = useParams();
  // const myOrders = dashboardState.myOrders || [];

  useEffect(() => {
    if (!dashboardState.myOrdersLoaded && !dashboardState.myOrdersLoading)
      fetchMyOrders(dispatchDashboard);
  }, [dashboardState.myOrdersLoaded, dispatchDashboard]);

  return (
    <>
      {!dashboardState.myOrdersLoaded && (
        <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )}
      {tabName === "my-orders" && !!dashboardState.myOrdersLoaded && (
        <Container maxWidth="md" disableGutters sx={{ px: 0.5 }}>
          <Paper
            elevation={1}
            sx={{
              mt: 2,
              p: 1,
              position: "relative",
              backgroundColor: blue[100],
              border: "solid 1px lightgrey",
            }}
          >
            <Box
              justifyContent="space-between"
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Typography variant={isMobile ? "body2" : "body1"} sx={{ m: 1 }}>
                {!!dashboardState?.myOrders?.length
                  ? `Mano užsakymai: ${dashboardState.myOrders.length}`
                  : `Jūs neužsakėte paslaugų`}
              </Typography>

              {/* <MyAdsFilterSelectBox /> */}
            </Box>
          </Paper>

          {!!dashboardState.myOrders?.length && (
            <MyOrdersTable />
            // <Paper sx={{ mt: 2 }}>
            //   {dashboardState.myOrders.map((order) => (
            //     <Box
            //       key={order.id}
            //       sx={{ display: "flex", justifyContent: "space-around" }}
            //     >
            //       <Typography>{getLocalDate(order.created_at)}</Typography>
            //       <Typography>Skelbimo ID: {order.payment.ad}</Typography>
            //       <Typography>Trukmė: {order.payment.duration}</Typography>
            //       <Typography>Suma: {order.payment.amount} €</Typography>
            //     </Box>
            //   ))}
            // </Paper>
          )}
        </Container>
      )}
    </>
  );
}
