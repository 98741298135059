import React, { useState, useEffect, useRef } from "react";
import { Chip } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import reloadAds from "../../../../utils/getUrlFromParams";
import RoomFilter from "./RoomFilter";
import useFilterType from "../hooks/useFilterType";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import AreaFilter from "./AreaFilter";
import CustomMenu from "../SharedComponents/CustomMenu";

const emptyRoomParams = {
  rooms_from: null,
  min_rooms: null,
  rooms_to: null,
  max_rooms: null,
};

const emptySizeParams = {
  min_size: null,
  max_size: null,
  min_plot_area: null,
  max_plot_area: null,
  area_from: null,
  area_to: null,
};

export default function RoomFilterChip() {
  const { dispatch } = useGlobalContext();
  const formikRef = useRef(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  const [roomParams, setRoomParams] = useState({});
  const [roomParamsHaveChanged, setRoomParamsHaveChanged] = useState(false);

  const { isFlat, isHouse, isLand, isApartmentComplex } = useFilterType(params);
  const [chipLabel, setChipLabel] = useState("Kambariai, plotas");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    changeChipLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  function handleChipClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleXClick() {
    let newParams = { ...params, ...emptyRoomParams, ...emptySizeParams };
    delete newParams.page;
    setRoomParams(emptyRoomParams);
    reloadAds(newParams, dispatch, history);
    setAnchorEl(null);
  }

  function handleSubmit(formikValues) {
    if (
      !!formikValues &&
      (!!roomParamsHaveChanged || !!formikRef?.current?.dirty)
    ) {
      let newParams = { ...params, ...roomParams, ...formikValues };
      delete newParams.page;
      reloadAds(newParams, dispatch, history);
    }
    setAnchorEl(null);
  }

  function changeChipLabel() {
    let sizeLabel = "";
    let roomLabel = "";
    let chipLabel =
      isFlat || isHouse || isApartmentComplex ? "Kambariai, plotas" : "Plotas";
    let minSize =
      (!isLand && +params?.min_size) ||
      (!isLand && +params?.area_from) ||
      (isLand && +params?.min_plot_area) ||
      null;
    let maxSize =
      (!isLand && +params?.max_size) ||
      (!isLand && +params?.area_to) ||
      (isLand && +params?.max_plot_area) ||
      null;
    let minRooms = +params?.min_rooms || +params?.rooms_from || 1;
    let maxRooms = +params?.max_rooms || +params?.rooms_to || null;
    let units = isLand ? "a" : "m²";

    if (!!minSize && maxSize)
      sizeLabel =
        minSize === maxSize
          ? `${minSize} ${units}`
          : `${minSize}-${maxSize} ${units}`;
    if (!minSize && !!maxSize) sizeLabel = `Iki ${maxSize} ${units}`;
    if (!!minSize && !maxSize) sizeLabel = `Nuo ${minSize} ${units}`;

    if (!!maxRooms && maxRooms < 6)
      roomLabel =
        Math.max(minRooms, 1) === maxRooms
          ? `${maxRooms} kamb.`
          : `${Math.max(minRooms, 1)}-${maxRooms} kamb.`;
    else if (minRooms > 1) roomLabel = `${minRooms}+ kamb.`;

    if (!!sizeLabel || !!roomLabel) chipLabel = `${roomLabel} ${sizeLabel}`;
    setChipLabel(chipLabel);
  }

  // console.log("roomParamsHaveChanged", roomParamsHaveChanged);

  return (
    <>
      <Chip
        label={chipLabel}
        onClick={handleChipClick}
        onDelete={
          chipLabel === "Kambariai, plotas" || chipLabel === "Plotas"
            ? null
            : handleXClick
        }
        variant="outlined"
        color={
          (chipLabel !== "Kambariai, plotas" && chipLabel !== "Plotas") ||
          anchorEl
            ? "primary"
            : "default"
        }
        sx={{ borderRadius: 1, mr: 1, fontSize: { xs: 10, sm: "small" } }}
      />
      <CustomMenu
        formikRef={formikRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <AreaFilter formikRef={formikRef} handleSubmit={handleSubmit} />
        <RoomFilter
          roomParams={roomParams}
          setRoomParams={setRoomParams}
          setRoomParamsHaveChanged={setRoomParamsHaveChanged}
        />
      </CustomMenu>
    </>
  );
}
