import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import ActivatedMapDialog from "./ActivatedMapDialog";

function MapDialog({ children }) {
  const { state } = useGlobalContext();

  return (
    <>
      {(!!state?.drawPolygonMode || !!state.drawPlacesMode) && (
        <ActivatedMapDialog>{children}</ActivatedMapDialog>
      )}
      {!state?.drawPolygonMode && !state?.drawPlacesMode && (
        <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
      )}
    </>
  );
}

export default MapDialog;
