import React from "react";
import FormStep1 from "./Step1AdInfo/FormStep1";
import FormStep2 from "./Step2Photos/FormStep2";
import FormStep1DataProvider from "./Step1AdInfo/FormStep1DataProvider";
import { useGlobalContext } from "../../context";
import FormStep3 from "./Step3AdSettings/FormStep3";
import CompletedForm from "./CompletedForm";

export default function StepContentComponent({
  activeStep,
  handleNext,
  handleBack,
}) {
  const { adFormState } = useGlobalContext();
  const ad = adFormState.adDraft;

  // console.log("render StepContenComponent");

  switch (activeStep) {
    case 0:
      return (
        <div>
          <FormStep1DataProvider>
            <FormStep1 handleNext={handleNext} />
          </FormStep1DataProvider>
        </div>
      );
    case 1:
      return (
        <div>
          <FormStep2 ad={ad} handleBack={handleBack} handleNext={handleNext} />
        </div>
      );
    case 2:
      return (
        <div>
          <FormStep3 ad={ad} handleBack={handleBack} handleNext={handleNext} />
        </div>
      );
    case 3:
      return (
        <div>
          <CompletedForm />
        </div>
      );

    default:
      return "Unknown stepIndex";
  }
}
