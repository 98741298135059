import React from "react";
import { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import SaleChart from "./SaleChart";
import RentChart from "./RentChart";

const AccordionValue = ({ ad, draftPrice }) => {
  const isDraft = ad.properties.status === "draft";
  const price = isDraft ? draftPrice : ad.properties.price;
  const priceSqm = price / ad.properties.area;
  const [expanded, setExpanded] = useState(isDraft);
  const [showPricePerSqM, setShowPricePerSqM] = useState(false);

  const showValuation =
    ["Parduodamas butas", "Parduodamas namas", "Nuomojamas butas"].includes(
      ad.properties.ad_type
    ) && !!ad.properties.prediction;

  // console.log("ad prediction", ad.prediction, ad);

  return (
    showValuation && (
      <Accordion
        expanded={expanded}
        onChange={(event, expanded) => {
          setExpanded(expanded);
        }}
        sx={{ width: "100%", border: isDraft ? "solid lightgrey 1px" : "none" }}
        elevation={isDraft ? 0 : 1}
      >
        <AccordionSummary
          expandIcon={!isDraft && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="button" color={expanded ? "primary" : "default"}>
            Kainų palyginimas
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "block" }}>
          <Grid
            container
            sx={{
              mb: 2,
              border: "none",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ mr: 2 }}>
              Kaina:
            </Typography>
            <FormControl>
              <RadioGroup row>
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      checked={!showPricePerSqM}
                      onChange={() => setShowPricePerSqM(!showPricePerSqM)}
                      // value={false}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">€</Typography>}
                  labelPlacement="end"
                  sx={{ mr: 3 }}
                />
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      checked={showPricePerSqM}
                      onChange={() => setShowPricePerSqM(!showPricePerSqM)}
                      // value={true}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">€/㎡</Typography>}
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {ad.properties.ad_type !== "Nuomojamas butas" && (
            <Box
              sx={{
                border: "none",
                width: "100%",
                display: "block",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ border: "none", textAlign: "center" }}
                >
                  Panašaus būsto kainos rinkoje (
                  {showPricePerSqM ? "€/\u33A1" : "€"})
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "none",
                  maxWidth: { xs: "100%", sm: "90%", md: "600px" },
                  // display: "flex",
                  display: "block",
                  justifyContent: "center",
                  m: "auto",
                }}
              >
                <SaleChart
                  ad={ad}
                  showPricePerSqM={showPricePerSqM}
                  draftPrice={draftPrice}
                />
              </Box>
            </Box>
          )}

          {ad.properties.ad_type === "Parduodamas butas" && (
            <Box
              sx={{
                mt: 4,
                border: "none",
                width: "100%",
                display: "block",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "center", px: 0.5, display: "inline" }}
                >
                  Panašaus būsto nuomos kainos rinkoje{" "}
                  <Typography component="span" variant="subtitle2" noWrap>
                    ({showPricePerSqM ? "€/\u33A1 per mėnesį" : "€ per mėn."})
                  </Typography>
                </Typography>
              </Grid>
              <Box
                sx={{
                  border: "none",
                  maxWidth: { xs: "100%", sm: "90%", md: "600px" },
                  // display: "flex",
                  display: "block",
                  justifyContent: "center",
                  m: "auto",
                }}
              >
                <RentChart
                  ad={ad}
                  showPricePerSqM={showPricePerSqM}
                  draftPrice={draftPrice}
                />
              </Box>
            </Box>
          )}

          {ad.properties.ad_type === "Nuomojamas butas" && (
            <Grid container sx={{ justifyContent: "space-between" }}>
              <RentChart
                ad={ad}
                showPricePerSqM={showPricePerSqM}
                draftPrice={draftPrice}
              />
            </Grid>
          )}

          <Grid
            container
            style={{
              marginTop: "20px",
              border: "none",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            {ad.properties.ad_type === "Parduodamas butas" &&
              !!ad.properties?.prediction &&
              price && (
                <Box
                  sx={{
                    border: "none",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <ArrowRightIcon />
                  <Typography variant="body2" sx={{ display: "inline" }}>
                    Orientacinė bendroji metinė nuomos grąža:{" "}
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ ml: 1, fontWeight: 500, whiteSpace: "nowrap" }}
                    >
                      {Math.round(
                        ((ad.properties.prediction.rent_price_sqm_pred *
                          (1 - ad.properties.prediction.rent_price_mape)) /
                          priceSqm) *
                          12 *
                          100 *
                          10
                      ) / 10}
                      -
                      {Math.round(
                        ((ad.properties.prediction.rent_price_sqm_pred *
                          (1 + ad.properties.prediction.rent_price_mape)) /
                          priceSqm) *
                          12 *
                          100 *
                          10
                      ) / 10}{" "}
                      %
                    </Typography>
                  </Typography>
                </Box>
              )}
          </Grid>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{ width: "100%", alignItems: "center", my: 2 }}
          >
            <WarningAmberIcon color="warning" fontSize="small" />
            <Typography variant="caption" sx={{ mb: 2 }}>
              Panašių būstų rinkos kainos apskaičiuotos pasielkiant pažangius
              mašininio mokymosi metodus, tačiau šie įverčiai gali būti
              netikslūs dėl nepakankamų duomenų, taikomų matematinių modelių
              trūkumų, jūsų būsto ypatumų, kurių modeliai negali įvertinti, ar
              kitų veiksnių. Kainų palyginimo funkcionalumas įdiegtas tik
              orientaciniais tikslais, todėl niekaip nepakeičia profesionalaus
              vertinimo.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default AccordionValue;
