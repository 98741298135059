import React, { useRef, useState } from "react";
import {
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../../context";
import { Box } from "@mui/system";
import usePanels from "./usePanels";
import { crudAdDraft } from "../fetchOrSendAdDraftData";
// import AdFormAppBar from "../AdFormAppBar";
import { grey } from "@mui/material/colors";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAdType from "../../IndividualAd/BasicAdInfo/useAdType";

export default function FormStep1({ handleNext }) {
  const { adFormState, dispatchAdForm } = useGlobalContext();
  const formikRef = useRef(null);
  const ad = adFormState.adDraft;
  const [panelIndex, setPanelIndex] = useState(
    adFormState?.activePanelIndex || 0
  );
  const edit = false;
  const panelProps = { ad, handleSubmit, edit, formikRef };
  const { panels } = usePanels(panelProps);
  const readyToEstimatePrice =
    panels.findIndex((panel) => panel.value === "descriptionPanel") - 1 ===
    panelIndex;
  const { isFlat, isHouse, isSalse } = useAdType(ad);

  // console.log("panels from FormStep1", panels);

  function handleSubmit(values, formikBag) {
    if (
      ad.properties.status === "draft" &&
      readyToEstimatePrice &&
      !!ad.properties.is_exact &&
      !ad.properties.prediction &&
      (isFlat || (isHouse && isSalse))
    ) {
      values = { ...values, initiate_price_estimation: true };
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { estimatingPrice: true },
      });
    }
    crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
    // console.log("formik submition successful");
    // formikBag.resetForm({ values });
    handlePanelChange(+1);
  }

  function handleInitiateSubmit() {
    if (formikRef.current) {
      const errorField = Object.keys(formikRef.current.errors)[0];
      if (errorField) {
        // Scroll to the first field with an error
        document.getElementsByName(errorField)[0]?.scrollIntoView({
          behavior: "smooth",
          // block: "end",
        });
      }
      formikRef.current.submitForm();
    }
  }

  function handlePanelChange(step) {
    let newPanelIndex = panelIndex + step;
    if (newPanelIndex === panels.length) {
      handleNext();
      return;
    }
    setPanelIndex(newPanelIndex);
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { activePanelIndex: newPanelIndex },
    });
  }

  // console.log("render FormStep1 count");
  // console.log("adFormState from FormStep1", adFormState);
  // console.log("formik from FormStep1", formikRef.current);
  console.log("ad from FormStep1", ad);

  return (
    <>
      {/* <AdFormAppBar
        handlePanelChange={handlePanelChange}
        handleInitiateSubmit={handleInitiateSubmit}
      /> */}
      <Paper sx={{ p: 1, mt: 2, backgroundColor: grey[50] }}>
        <Container disableGutters maxWidth="md">
          <Grid
            container
            sx={{
              border: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 1,
              mb: 1,
            }}
          >
            <IconButton
              onClick={() => handlePanelChange(-1)}
              disabled={!panelIndex}
            >
              <ChevronLeftIcon />
            </IconButton>
            <LinearProgress
              variant="determinate"
              color="success"
              value={((panelIndex + 1) / panels.length) * 100}
              sx={{ height: "15px", borderRadius: "1px", flexGrow: 1 }}
            />
            <IconButton onClick={handleInitiateSubmit}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 3, mb: 2 }}>
            <Typography variant="button">
              1.{panelIndex + 1}. {panels[panelIndex].label}
            </Typography>
          </Grid>

          {panels[panelIndex].component}

          <Grid container justifyContent="space-between" sx={{ mt: 3 }}>
            <Button
              variant="outlined"
              onClick={() => handlePanelChange(-1)}
              sx={{ visibility: !!panelIndex ? "visible" : "hidden" }}
            >
              Atgal
            </Button>
            <Button variant="contained" onClick={handleInitiateSubmit}>
              Pirmyn
            </Button>
          </Grid>
        </Container>
      </Paper>
    </>
  );
}
