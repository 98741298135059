import React, { useState, useCallback, useRef } from "react";
import { TextField, Popper, Box, Typography, Grid, Badge } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import useLocationSearchSocket from "./useLocationSearchSocket";
import reloadAds from "../../../../utils/getUrlFromParams";
import { debounce } from "../../../../utils/debounce";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import useParamsFromUrl from "../../../MainPage/hooks/useParamsFromUrl";
import { crudSavedSearch } from "../../../Dashboard/fetchOrSendDashboardData";

export default function LocationSearchAutocomplete({
  openSearch,
  setOpenSearch,
}) {
  const { dispatch, dashboardState, dispatchDashboard } = useGlobalContext();
  const [inputValue, setInputValue] = useState("");
  const history = useHistory();
  const params = useParamsFromUrl();
  const socketRef = useRef(null);

  const { locationSearchOptions } = useLocationSearchSocket(socketRef);

  const debouncedSearch = useCallback(
    debounce((value) => {
      // console.log(
      //   "value from debounced search:",
      //   value,
      //   socketRef?.current?.readyState
      // );
      if (socketRef?.current?.readyState === 1)
        socketRef?.current?.send(
          JSON.stringify({ type: "search", search_value: value })
        );
    }, 150),
    []
  );

  function handleInputChange(e, value) {
    setInputValue(value);
    debouncedSearch(value);
  }

  function handleValueChange(e, value) {
    if (!value) return;

    dispatch({
      type: "CHANGE_APP_STATE",
      payload: {
        townOptions: [],
        townOptionsLoaded: false,
        suburbOptions: [],
        suburbOptionsLoaded: false,
        streetOptions: [],
        streetOptionsLoaded: false,
      },
    });

    // handle saved search case
    if (!!value.filter_params) {
      const savedSearch = { ...value, run_search: true };
      crudSavedSearch(dispatchDashboard, "patch", savedSearch);
      const params = JSON.parse(value.filter_params);
      reloadAds(params, dispatch, history);
      return;
    }

    // handle location search case
    let form = {};
    form[value.type] = value.id;
    switch (value.type) {
      case "municipality":
        form.suburb = "";
        form.town = "";
        form.street = "";
        break;
      case "town":
        form.municipality = value.parent_id;
        form.suburb = "";
        form.street = "";
        break;
      case "suburb":
        form.municipality = value.parent__parent_id;
        form.town = value.parent_id;
        form.street = "";
        break;
      case "street":
        form.municipality = value.parent__parent_id;
        form.town = value.parent_id;
        form.suburb = "";
        break;
      default:
      // default case if needed
    }
    let newParams = { ...params, ...form };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
  }

  const customizedPopper = (props) => {
    return (
      <>
        <Popper
          {...props}
          sx={{ minWidth: "fit-content", zIndex: 1000 }}
          placement="bottom-start"
        />
      </>
    );
  };

  return (
    <>
      <Autocomplete
        sx={{ flexGrow: 1, mr: 1, border: "none" }}
        // value={searchValue}
        size="small"
        onChange={handleValueChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onBlur={() => setOpenSearch(false)}
        openOnFocus
        disableListWrap
        clearIcon={false}
        clearOnEscape
        autoHighlight
        blurOnSelect
        popupIcon={null}
        options={
          inputValue
            ? locationSearchOptions
            : dashboardState.mySearches.sort((a, b) => a.rank - b.rank)
        }
        filterOptions={(options, state) => options}
        isOptionEqualToValue={(option, value) => false}
        noOptionsText={inputValue ? "Nėra pasiūlymų" : "Nėra išsaugotų paieškų"}
        groupBy={(option) => !!option.filter_params && "Išsaugotos paieškos"}
        PopperComponent={customizedPopper}
        renderInput={(params) => {
          params.inputProps.value = inputValue;
          return (
            <>
              <TextField
                {...params}
                autoFocus={openSearch}
                variant="standard"
                margin="dense"
                placeholder="Vietos paieška (pvz., Baltupiai)"
                InputLabelProps={{ shrink: true }}
              />
            </>
          );
        }}
        getOptionLabel={(option) => {
          return option.name || option.full_name || "";
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id.toString()}>
              <Grid
                container
                // xs={12}
                justifyContent="space-between"
                sx={{ border: "none", display: "flex" }}
              >
                <Typography variant="body1">
                  {option.name || option.full_name}
                </Typography>
                {!!option.unseen_results_count && (
                  <Box sx={{ display: "flex" }}>
                    <FiberNewOutlinedIcon color="secondary" sx={{ mr: 1 }} />
                    <Typography variant="body1" color="secondary">
                      {option.unseen_results_count}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          );
        }}
      />
    </>
  );
}
