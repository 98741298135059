import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MyOrdersTableRow from "./MyOrdersTableRow";
import { useGlobalContext } from "../../../context";

export default function MyOrdersTable() {
  const { dashboardState } = useGlobalContext();

  //   const myOrders = dashboardState.myOrders;
  let orderAds = dashboardState.myOrders.reduce((acc, current) => {
    const isDuplicate = acc.find((order) => order.ad === current.ad);
    if (!isDuplicate) {
      acc.push(current);
    }
    return acc;
  }, []);
  orderAds.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table aria-label="collapsible table">
        <TableBody>
          {orderAds.map((orderAd) => (
            <MyOrdersTableRow
              key={orderAd.id}
              orderAd={orderAd}
              quantity={
                dashboardState.myOrders.filter(
                  (order) => order.ad === orderAd.ad
                ).length
              }
              sum={
                dashboardState.myOrders
                  .filter((order) => order.ad === orderAd.ad)
                  .reduce((acc, current) => acc + current.payment.amount, 0)
                  .toFixed(2) || 0
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
