import React, { useState, useRef } from "react";
import { Button, IconButton, Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import AvatarEditor from "react-avatar-editor";

import RotateRightIcon from "@mui/icons-material/RotateRight";
import { updateUserData } from "../fetchOrSendAccountsData";
import { base64StringtoFile } from "../../ImageCropper/ReusableUtils";
import { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import useScrollListener from "../../../hooks/useScrollListener";
import { crudCompanlyLogo } from "../../AdForm/fetchOrSendAdDraftData";
import usePhotoData from "./usePhotoData";

function PhotoEditor({ setEditProfilePhoto, photoContainerWidth, logo, edit }) {
  const { userState, dispatchUser } = useGlobalContext();

  const {
    ad,
    photoUrl,
    initialScale,
    initialRotation,
    initialPosition,
    aspectRatio,
    width,
    height,
    minScale,
    dispatchX,
  } = usePhotoData(logo, edit, photoContainerWidth);

  const [isUpdated, setIsUpdated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [scale, setScale] = useState(initialScale);
  const [rotation, setRotation] = useState(initialRotation);
  const [position, setPosition] = useState(initialPosition);

  const editorRef = useRef(null);
  const boxRef = useRef(null);
  const { wheelScale } = useScrollListener(boxRef, scale, minScale);
  // const [image, setImage] = useState(null);

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = photoUrl;
  //   img.onload = () => setImage(img);
  // }, [photoUrl]);

  useEffect(() => {
    setScale(wheelScale);
  }, [wheelScale]);

  useEffect(() => {
    if (!!ad?.properties?.logoLoaded) {
      setEditProfilePhoto(false);
      dispatchX({
        type: "CHANGE_AD_PROPERTIES",
        payload: { logoLoaded: false },
      });
    }
  }, [ad?.properties?.logoLoaded]);

  useEffect(() => {
    if (!!userState.photoLoaded) {
      setEditProfilePhoto(false);
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { photoLoaded: false },
      });
    }
  }, [userState?.photoLoaded]);

  function handleScaleChange(event, newValue) {
    setScale(newValue);
  }

  function handleRotate() {
    let newRotation = rotation === 270 ? 0 : rotation + 90;
    // console.log(
    //   "handleRotate newRotation, scale, aspectRatio",
    //   newRotation,
    //   scale,
    //   aspectRatio
    // );
    newRotation === 90 || newRotation === 270
      ? setScale(scale / aspectRatio)
      : setScale(scale * aspectRatio);
    setRotation(newRotation);
  }

  function handlePositionChange(value) {
    setPosition(value);
  }

  function handleClickSaveImage() {
    // let canvas = editorRef.current.getImageScaledToCanvas();
    // var ctx = canvas.getContext("2d");
    // ctx.globalCompositeOperation = "destination-over";
    // ctx.fillStyle = "white";
    // ctx.fillRect(0, 0, canvas.width, canvas.height);
    // let imageData = canvas.toDataURL("image/jpeg", 1);
    let imageData = editorRef.current
      .getImageScaledToCanvas()
      .toDataURL("image/jpeg", 1);

    let formData = new FormData();
    let imageFile = base64StringtoFile(imageData, "photo_crop.jpg");
    // console.log(
    //   "position x, y, scale, rotation",
    //   position.x,
    //   position.y,
    //   scale,
    //   rotation
    // );
    formData.append("position_x", position.x);
    formData.append("position_y", position.y);
    formData.append("scale", scale);
    formData.append("rotation", rotation);
    formData.append(logo ? "logo_thumbnail" : "photo_crop", imageFile);

    // console.log("imageFile", imageFile, logo);

    setIsUpdating(true);
    logo
      ? crudCompanlyLogo(ad.id, dispatchX, formData, "PATCH")
      : // ? updateCompanyLogo(adId, dispatchAdForm, formData, setIsUpdated)
        updateUserData(userState.userId, dispatchUser, formData);
  }

  return (
    <>
      <Box
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
          mt: -1,
        }}
      >
        <Box
          sx={{
            border: "none",
            display: "flex",
            justifyContent: "left",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, mr: 2 }}>
            Pritraukti
          </Typography>
          <Slider
            value={scale}
            onChange={handleScaleChange}
            sx={{ width: "50%" }}
            min={minScale}
            max={3}
            step={0.1}
          />
        </Box>
        <IconButton size="small" onClick={handleRotate}>
          <RotateRightIcon color="action" />
        </IconButton>
      </Box>

      <Box
        ref={boxRef}
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "auto",
          border: "solid grey 1px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 1,
        }}
      >
        <AvatarEditor
          ref={editorRef}
          image={photoUrl}
          width={width}
          height={height}
          border={0}
          borderRadius={logo ? 0 : 180}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          rotate={rotation}
          position={position}
          onPositionChange={handlePositionChange}
          crossOrigin="anonymous"
          backgroundColor="#ffffff"
          disableBoundaryChecks={false}
          // style={{ margin: "auto" }}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          border: "none",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          // py: 0.5,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setEditProfilePhoto(false);
          }}
        >
          Atšaukti
        </Button>
        <Button
          startIcon={
            isUpdating ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <SaveIcon />
            )
          }
          variant="contained"
          size="small"
          onClick={handleClickSaveImage}
          disabled={isUpdating}
          sx={{ ml: 1 }}
        >
          Išsaugoti
        </Button>
      </Box>
    </>
  );
}

export default PhotoEditor;
