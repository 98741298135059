import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { Box, Container, Grid, Typography } from "@mui/material";
import useAuthenticate from "./useAuthenticate";
import AddressManagement from "./AddressManagement";
import MapManagement from "./MapManagement";
import ModelInitialization from "./ModelInitialization";
import { ValuationManagement } from "./ValuationManagement";
import { grey } from "@mui/material/colors";

function Management() {
  const [flatValue, setFlatValue] = useState(1);
  const [houseValue, setHouseValue] = useState(1);
  const [flatRentValue, setFlatRentValue] = useState(1);
  const [data, setData] = useState({});
  const [paramsUpdated, setParamsUpdated] = useState(0);

  const { isAdmin } = useAuthenticate();

  function loadAdStats() {
    setParamsUpdated(0);
    let adParams = {
      site: "",
      butai: flatValue,
      namai: houseValue,
      "butu-nuoma": flatRentValue,
    };

    // alert("axios request");
    axiosInstance.defaults.timeout = 0; // turn of timeout because it will take a lot of time to wait for response
    axiosInstance
      .get("api/management/load-ad-stats/", { params: adParams })
      .then((res) => {
        // console.log("server response", res);
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error occured while trying download data", err);
      });
  }

  useEffect(() => {
    if (!!data["butai"]?.page) {
      let lastPage = data["butai"].lastPage;
      let nextPage = Math.min(data["butai"].page + 1, lastPage);
      setFlatValue(nextPage.toString() + "-" + lastPage.toString());
      setParamsUpdated((prev) => prev + 1);
      //   alert("butai updated");
    }
    if (!!data["namai"]?.page) {
      let lastPage = data["namai"].lastPage;
      let nextPage = Math.min(data["namai"].page + 1, lastPage);
      setHouseValue(nextPage.toString() + "-" + lastPage.toString());
      setParamsUpdated((prev) => prev + 1);
      //   alert("namai updated");
    }
    if (!!data["butu-nuoma"]?.page) {
      let lastPage = data["butu-nuoma"].lastPage;
      let nextPage = Math.min(data["butu-nuoma"].page + 1, lastPage);
      setFlatRentValue(nextPage.toString() + "-" + lastPage.toString());
      setParamsUpdated((prev) => prev + 1);
      //   alert("butu nuoma updated");
    }
  }, [data]);

  useEffect(() => {
    // console.log("paramsUpdated", paramsUpdated, Object.keys(data).length);
    let endReached =
      Boolean(data["butai"]?.endReached) +
      Boolean(data["namai"]?.endReached) +
      Boolean(data["butu-nuoma"]?.endReached);
    if (
      (paramsUpdated > 0) &
      (paramsUpdated === Object.keys(data).length) &
      (paramsUpdated !== endReached)
    ) {
      loadAdStats();
      setParamsUpdated(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUpdated]);

  return (
    <>
      {!isAdmin ? (
        <div>Checking credentials...</div>
      ) : (
        <>
          <Box sx={{ minHeight: "100vh", backgroundColor: grey[300], mt: 7 }}>
            <Container maxWidth="lg">
              <Grid container justifyContent="center">
                <Typography variant="h4" sx={{ my: 4 }}>
                  Valdymo blokas
                </Typography>
              </Grid>
              {/* <Grid container justifyContent="center">
                <Typography variant="h6" style={{ marginTop: "50px" }}>
                  Skelbimų statistikos atnaujinimas
                </Typography>
              </Grid>
              <Grid container alignItems="center" style={{ marginTop: "50px" }}>
                <Typography>Rinkti skelbimų statistiką iš:</Typography>
                <TextField style={{ marginLeft: "10px", width: "100px" }} />
                <Typography style={{ marginLeft: "50px" }}>
                  Butai (psl.)
                </Typography>
                <TextField
                  value={flatValue}
                  style={{ marginLeft: "10px", width: "40px" }}
                  onChange={(e) => setFlatValue(e.target.value)}
                />

                <Typography style={{ marginLeft: "50px" }}>
                  Namai (psl.)
                </Typography>
                <TextField
                  value={houseValue}
                  style={{ marginLeft: "10px", width: "40px" }}
                  onChange={(e) => setHouseValue(e.target.value)}
                />

                <Typography style={{ marginLeft: "50px" }}>
                  Butų nuoma (psl.)
                </Typography>
                <TextField
                  value={flatRentValue}
                  style={{ marginLeft: "10px", width: "40px" }}
                  onChange={(e) => setFlatRentValue(e.target.value)}
                />

                <Button
                  variant="outlined"
                  style={{ marginLeft: "50px" }}
                  onClick={loadAdStats}
                >
                  Siųstis
                </Button>
              </Grid>
              <br />
              <br />
              <br /> */}
              {Object.keys(data).map((adType) => {
                return (
                  <Typography key={adType}>
                    Tipas: {adType}. Psl.: {data[adType].page}/
                    {data[adType].lastPage}. Nauji: {data[adType].newAdCount}.
                    Seni: {data[adType].existingAdCount}. Dubliuojasi:{" "}
                    {data[adType].duplicates}
                  </Typography>
                );
              })}
              <ModelInitialization />
              <MapManagement />
              <AddressManagement />
              <ValuationManagement />
            </Container>
          </Box>
        </>
      )}
    </>
  );
}

export default Management;
