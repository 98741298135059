import { Divider, Grid, Typography } from "@mui/material";
import * as adOptions from "../../../../utils/adOptions";
import useFilterType from "../hooks/useFilterType";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import FormikBasicMultipleSelect from "../../../SharedComponents/FormikBasicMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikAndOrFilter from "./FormikAndOrFilter";
import { useFormikContext } from "formik";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { useState } from "react";

function FiltersFlat() {
  const formik = useFormikContext();
  const params = useParamsFromUrl();
  const { isSale, isRent } = useFilterType(params);

  const priceOptions = isSale
    ? adOptions.PRICE_OPTIONS
    : adOptions.PRICE_OPTIONS_RENT;

  const [rentTypeOptions, setRentTypeOptions] = useState(
    formik.values.min_rent_period < 30
      ? adOptions.SHORT_TERM_RENT_TYPE_CHOICES
      : adOptions.RENT_TYPE_CHOICES
  );

  function clearAllFilter(values, name) {
    values.length <= 1 && formik.setFieldValue(name + "_all", "");
  }

  function handleMinYearChange(value) {
    !value && formik.setFieldValue("include_renovated", "");
  }

  function handleMinRentPeriodChange(value) {
    !!value && value < 30 && formik.setFieldValue("long_term_rent_only", "");
    setRentTypeOptions(
      !!value && value < 30
        ? adOptions.SHORT_TERM_RENT_TYPE_CHOICES
        : adOptions.RENT_TYPE_CHOICES
    );
    formik.setFieldValue("rent_type", "");
  }

  function handleInstallationChange(value) {
    value !== "full" && formik.setFieldValue("condition", "");
  }

  // console.log("render FiltersFlat");

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pagrindiniai filtrai
          </Typography>
        </Divider>
      </Grid>
      <Grid container spacing={2} sx={{ border: "none" }}>
        <>
          {/* minimum price setting field */}
          <Grid item xs={6}>
            <FormikBasicAutocomplete
              name="min_price"
              label="Kaina (Eur, nuo)"
              options={
                !!+formik.values["max_price"]
                  ? priceOptions.filter(
                      (option) => option <= +formik.values["max_price"]
                    )
                  : priceOptions
              }
              type="number"
              removeSpinners={true}
            />
          </Grid>
          {/* max price setting field */}
          <Grid item xs={6}>
            <FormikBasicAutocomplete
              name="max_price"
              label="Kaina (Eur, iki)"
              options={priceOptions.filter(
                (option) => option >= +formik.values["min_price"]
              )}
              type="number"
              removeSpinners={true}
            />
          </Grid>
        </>

        <>
          {/* minimum room setting field */}
          <Grid item xs={6}>
            <FormikBasicAutocomplete
              name="min_rooms"
              label="Kambarių sk. (nuo)"
              options={
                !!+formik.values["max_rooms"]
                  ? adOptions.ROOM_OPTIONS.filter(
                      (option) => option <= +formik.values["max_rooms"]
                    )
                  : adOptions.ROOM_OPTIONS
              }
              type="number"
            />
          </Grid>
          {/* max room setting field */}
          <Grid item xs={6}>
            <FormikBasicAutocomplete
              name="max_rooms"
              label="Kambarių sk. (iki)"
              options={adOptions.ROOM_OPTIONS.filter(
                (option) => option >= +formik.values["min_rooms"]
              )}
              type="number"
            />
          </Grid>
          {/* minimum size setting field */}
          <Grid item xs={6}>
            <FormikBasicAutocomplete
              name="min_size"
              label="Plotas (nuo)"
              options={
                !!+formik.values["max_size"]
                  ? adOptions.SIZE_OPTIONS.filter(
                      (option) => option <= +formik.values["max_size"]
                    )
                  : adOptions.SIZE_OPTIONS
              }
              type="number"
            />
          </Grid>
          {/* max size setting field */}
          <Grid item xs={6}>
            <FormikBasicAutocomplete
              name="max_size"
              label="Plotas (iki)"
              options={adOptions.SIZE_OPTIONS.filter(
                (option) => option >= +formik.values["min_size"]
              )}
              type="number"
            />
          </Grid>
        </>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_floor"
            label="Aukštas (nuo)"
            options={
              !!+formik.values["max_floor"]
                ? adOptions.FLOOR_OPTIONS.filter(
                    (option) => option <= +formik.values["max_floor"]
                  )
                : adOptions.FLOOR_OPTIONS
            }
            type="number"
          />
        </Grid>
        {/* max floor setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_floor"
            label="Aukštas (iki)"
            options={adOptions.FLOOR_OPTIONS.filter(
              (option) => option >= +formik.values["min_floor"]
            )}
            type="number"
          />
        </Grid>

        <Grid container justifyContent="right" sx={{ px: 1 }}>
          <FormikCheckbox
            name="not_highest_floor"
            label="Ne viršutinis namo aukštas"
          />
        </Grid>

        {isSale && (
          <>
            <Grid item xs={12} sx={{ border: "none" }}>
              <FormikBasicMultipleSelect
                name="sale_type"
                label="Pardavimo sąlygos ir pirmenybės"
                options={adOptions.SALE_TYPE_CHOICES}
                onChange={(value) => clearAllFilter(value, "sale_type")}
              />
            </Grid>
            <FormikAndOrFilter name="sale_type_all" />
          </>
        )}

        {isRent && (
          <>
            <Grid item xs={12}>
              <FormikSelect
                name="min_rent_period"
                label="Orientacinis nuomos terminas"
                options={adOptions.MIN_RENT_PERIOD_CHOICES}
                enableClear={true}
                onChange={handleMinRentPeriodChange}
              />
            </Grid>
            {(!formik.values.min_rent_period ||
              +formik.values.min_rent_period >= 30) && (
              <Grid container justifyContent="right" sx={{ px: 1 }}>
                <FormikCheckbox
                  name="long_term_rent_only"
                  label="Nerodyti trumpalaikės nuomos (iki 1 mėn.) pasiūlymų"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="rent_type"
                label="Nuomos sąlygos ir pirmenybės"
                options={rentTypeOptions}
                onChange={(value) => clearAllFilter(value, "rent_type")}
              />
            </Grid>
            <FormikAndOrFilter name="rent_type_all" />
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pastatas
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2} sx={{ border: "none" }}>
        {/* minimum construction year setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_year"
            label={`Statybos ${
              formik.values.include_renovated ? "(renovacijos)" : ""
            } metai (nuo)`}
            options={
              !!+formik.values["max_year"]
                ? adOptions.YEAR_OPTIONS.filter(
                    (option) => option <= +formik.values["max_year"]
                  )
                : adOptions.YEAR_OPTIONS
            }
            onChange={handleMinYearChange}
            type="number"
            removeSpinners={true}
          />
        </Grid>
        {/* max construction year setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_year"
            label={`Statybos ${
              formik.values.include_renovated ? "(renovacijos)" : ""
            } metai (iki)`}
            options={adOptions.YEAR_OPTIONS.filter(
              (option) => option >= +formik.values["min_year"]
            )}
            type="number"
            removeSpinners={true}
          />
        </Grid>
        {Number(formik.values.min_year) > 0 && (
          <Grid container justifyContent="right" sx={{ px: 1 }}>
            <FormikCheckbox
              name="include_renovated"
              label="Įtraukti ir senesnės statybos renovuotus pastatus"
            />
          </Grid>
        )}
        <Grid container justifyContent="right" sx={{ px: 1 }}>
          <FormikCheckbox
            name="renovated_only"
            label="Rodyti tik renovuotus pastatus"
          />
        </Grid>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_floors"
            label="Aukštų sk. (nuo)"
            options={
              !!+formik.values["max_floors"]
                ? adOptions.FLOORS_OPTIONS.filter(
                    (option) => option <= +formik.values["max_floors"]
                  )
                : adOptions.FLOORS_OPTIONS
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_floors"
            label="Aukštų sk. (iki)"
            options={adOptions.FLOORS_OPTIONS.filter(
              (option) => option >= +formik.values["min_floors"]
            )}
            type="number"
          />
        </Grid>

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="building_type"
            label="Pastato konstrukcija"
            options={adOptions.BUILDING_TYPE_CHOICES}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="heating"
            label="Šildymas"
            options={adOptions.HEATING_CHOICES}
            onChange={(value) => clearAllFilter(value, "heating")}
          />
        </Grid>
        <FormikAndOrFilter name="heating_all" />
        <Grid container justifyContent="right" sx={{ px: 1 }}>
          <FormikCheckbox
            name="efficient_heating"
            label="Energetinis efektyvumas geresnis negu vidutinis"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikBasicAutocomplete
            name="max_heating_bill"
            label={"Vid. mėn. šildymo sąskaita žiemą (Eur, iki)"}
            options={adOptions.HEATING_BILL_OPTIONS_FLAT}
            type="number"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Būstas
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {isSale && (
          <>
            <Grid item xs={12} sm={6}>
              <FormikSelect
                name="installation"
                label="Baigtumas"
                options={adOptions.INSTALLATION_CHOICES}
                enableClear={true}
                onChange={handleInstallationChange}
              />
            </Grid>
            {formik.values.installation === "full" && (
              <Grid item xs={12} sm={6}>
                <FormikSelect
                  name="condition"
                  label="Būklė"
                  options={adOptions.CONDITION_CHOICES}
                  enableClear={true}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} sx={{ border: "none" }}>
          <FormikBasicMultipleSelect
            name="equipment"
            label="Įranga"
            options={adOptions.EQUIPMENT_CHOICES}
            onChange={(value) => clearAllFilter(value, "equipment")}
          />
        </Grid>
        <FormikAndOrFilter name="equipment_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="premises"
            label="Papildomos patalpos"
            options={adOptions.PREMISES_CHOICES}
            onChange={(value) => clearAllFilter(value, "premises")}
          />
        </Grid>
        <FormikAndOrFilter name="premises_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="other_features"
            label="Kiti ypatumai"
            options={adOptions.OTHER_FEATURES_CHOICES}
            onChange={(value) => clearAllFilter(value, "other_features")}
          />
        </Grid>
        <FormikAndOrFilter name="other_features_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="partial_amenities"
            label="Daliniai patogumai"
            options={adOptions.PARTIAL_AMENITIES_CHOICES}
            onChange={(value) => clearAllFilter(value, "partial_amenities")}
          />
        </Grid>
        <FormikAndOrFilter name="partial_amenities_all" />
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Aplinka
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="parking"
            label="Automobilio parkavimas"
            options={adOptions.PARKING_CHOICES}
            onChange={(value) => clearAllFilter(value, "parking")}
          />
        </Grid>
        <FormikAndOrFilter name="parking_all" />
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="security"
            label="Saugumas"
            options={adOptions.SECURITY_CHOICES}
            onChange={(value) => clearAllFilter(value, "security")}
          />
        </Grid>
        <FormikAndOrFilter name="security_all" />
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="recreation"
            label="Rekreacinės erdvės apylinkėse"
            options={adOptions.RECREATION_CHOICES}
            onChange={(value) => clearAllFilter(value, "recreation")}
          />
        </Grid>
        <FormikAndOrFilter name="recreation_all" />
      </Grid>
    </>
  );
}

export default FiltersFlat;
