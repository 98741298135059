import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, ZoomControl } from "react-leaflet";
import { useGlobalContext } from "../../../context";
import { fetchLocationGeom } from "../fetchOrSendMainAppData";
import { Box } from "@mui/system";
import PlaceSelector from "./PlaceSelector/PlaceSelector";
import PolygonSelectorChip from "./PolygonSelector/PolygonSelectorChip";
import MapDialog from "./MapDialog";
import PolygonSelector from "./PolygonSelector/PolygonSelector";
import AdGroup from "./AdGroup";
import SelectedShape from "./PolygonSelector/SelectedShape";
import SelectedPlaces from "./PlaceSelector/SelectedPlaces";
import PlaceSelectorChip from "./PlaceSelector/PlaceSelectorChip";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import { MapLibreTileLayer } from "./MapLibreTileLayer.ts";
import { Tooltip } from "@mui/material";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery.js";

export const placeMarkerIcon = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
    iconUrl: "https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png",
  },
});

function BasicMapComponent() {
  const { state, dispatch, routerState } = useGlobalContext();
  const mapRef = useRef(null);
  const params = useParamsFromUrl();
  const { isMobile } = useCustomMediaQuery();

  const adBoundsString = state.adBounds ? state.adBounds.toBBoxString() : null;
  const shapeBoundsString = state.shapeBounds
    ? state.shapeBounds.toBBoxString()
    : null;
  const geomString = state.geom ? state.geom.toString() : null;

  const [initialLoad, setInitialLoad] = useState(true);
  const [mapStyle, setMapStyle] = useState("osm_bright");

  const moveEndListener = () => {
    if (!mapRef.current) return;
    const mapCenter = Object.values(mapRef.current.getCenter());
    const mapZoom = mapRef.current.getZoom();
    // alert("storing mapCenter and mapZoom");
    localStorage.setItem("mapCenter", JSON.stringify(mapCenter));
    localStorage.setItem("mapZoom", JSON.stringify(mapZoom));
  };

  useEffect(() => {
    fetchLocationGeom(params, dispatch);
  }, [params.municipality, params.town, params.suburb, params.street]);

  useEffect(() => {
    if (!!mapRef.current) {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 10);
      mapRef.current.on("moveend", moveEndListener);
      const storedMapCenter = JSON.parse(localStorage.getItem("mapCenter"));
      const storedMapZoom = JSON.parse(localStorage.getItem("mapZoom"));
      if (storedMapCenter && storedMapZoom) {
        mapRef.current.setView(storedMapCenter, storedMapZoom);
      }
      // alert("initial load");
      setInitialLoad(false);
    }
  }, [mapRef.current, isMobile]);

  useEffect(() => {
    if (
      !initialLoad &&
      !state.adCardsLoading &&
      !state.drawPolygonMode &&
      !state.drawPlacesMode
      // &&
      // !!routerState.mouseOverAd
    ) {
      if (!!state.shapeBounds || !!state.adBounds) {
        // alert("fit bounds to shapeBounds or adBounds");
        mapRef.current.fitBounds(state.shapeBounds || state.adBounds, {
          padding: [30, 30],
        });
      } else if (!!state?.geom?.length) {
        // alert("fitBounds");
        let mapZoom = !!params?.street
          ? 17
          : !!params?.town || !!params?.suburb
          ? 15
          : 14;
        mapRef.current.fitBounds(state.geom, { maxZoom: mapZoom });
      }
      // localStorage.setItem(
      //   "mapCenter",
      //   JSON.stringify(mapRef.current.getCenter())
      // );
      // localStorage.setItem("mapZoom", JSON.stringify(mapRef.current.getZoom()));
    }
  }, [
    adBoundsString,
    shapeBoundsString,
    geomString,
    state.adCardsLoading,
    routerState.mouseOverAd,
  ]);

  // console.log("BasicMapComponent rerender");
  // console.log("mapRef from BasicMapComponent", mapRef.current);
  // console.log(
  //   "shapebounds from BasicMapComponent",
  //   state.shapeBounds,
  //   state.adBounds,
  //   state.geom
  // );

  // const style = "osm_bright"; //alidade_satellite
  const tileUrl = `https://tiles.stadiamaps.com/styles/${mapStyle}.json`;

  return (
    <Box sx={{ width: "100%", height: "100%", border: "solid lightgrey 1px" }}>
      <MapDialog>
        <MapContainer
          zoomControl={false}
          center={
            JSON.parse(localStorage.getItem("mapCenter")) || state.mapCenter
          }
          zoom={JSON.parse(localStorage.getItem("mapZoom")) || state.mapZoom}
          maxZoom={18}
          minZoom={7}
          style={{ height: "100%", width: "100%" }}
          ref={mapRef}
        >
          {/* <CustomZoomLevel /> */}
          <ZoomControl position="bottomleft" />
          <MapLibreTileLayer
            url={tileUrl}
            attribution={"&copy; Stadia Maps, OpenStreetMap"}
          />

          {!!state?.adCards?.length &&
            !state?.drawPolygonMode &&
            !state?.drawPlacesMode && <AdGroup />}
          {!state.drawPolygonMode && <SelectedShape />}
          {!state.drawPlacesMode && <SelectedPlaces />}

          <Box>
            {!state.drawPolygonMode && !state.drawPlacesMode && (
              <>
                <PolygonSelectorChip />
                <PlaceSelectorChip />
                <Tooltip
                  title={mapStyle === "osm_bright" ? "Palydovas" : "Žemėlapis"}
                >
                  <Box
                    component="img"
                    src={
                      mapStyle === "osm_bright"
                        ? "/satellite.jpg"
                        : "/osm_street.jpg"
                    }
                    sx={{
                      border: "solid 2px lightgrey",
                      borderRadius: "4px",
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      zIndex: 1000,
                      top: 10,
                      left: 10,
                    }}
                    onClick={() =>
                      setMapStyle(
                        mapStyle === "osm_bright"
                          ? "alidade_satellite"
                          : "osm_bright"
                      )
                    }
                  />
                </Tooltip>
              </>
            )}
            {state.drawPolygonMode && <PolygonSelector />}
            {state.drawPlacesMode && <PlaceSelector />}
          </Box>
        </MapContainer>
      </MapDialog>
    </Box>
  );
}

export default BasicMapComponent;
