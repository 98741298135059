import React, { useState } from "react";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdTitle from "../../../IndividualAd/AdTitle";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import DeleteAdDialog from "./DeleteAdDialog";

export default function AdTitleAndDelete({ ad }) {
  const { isSmallOrMobile } = useCustomMediaQuery();
  const [openDeleteAdDialog, setOpenDeleteAdDialog] = useState(false);

  // function handleDeleteAd() {
  //   const data = { status: "deleted" };
  //   crudMyAd(dispatch, dispatchDashboard, ad.id, data, "PATCH");
  // }

  return (
    <>
      <Grid
        container
        sx={{
          border: "none",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
          flexWrap: "nowrap",
        }}
      >
        <AdTitle ad={ad} />
        {isSmallOrMobile ? (
          <IconButton
            size="small"
            sx={{ border: "solid lightgrey 1px", mx: 1 }}
            onClick={() => setOpenDeleteAdDialog(true)}
          >
            <DeleteForeverIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        ) : (
          <Button
            variant="outlined"
            size="small"
            startIcon={<DeleteForeverIcon color="action" />}
            sx={{ mx: 0.5, width: "120px", border: "solid lightgrey 1px" }}
            color="inherit"
            onClick={() => {
              setOpenDeleteAdDialog(true);
            }}
          >
            <Typography variant="caption">Pašalinti</Typography>
          </Button>
        )}

        {openDeleteAdDialog && (
          <DeleteAdDialog
            open={openDeleteAdDialog}
            setOpen={setOpenDeleteAdDialog}
            ad={ad}
          />
        )}
      </Grid>
      <Box width="100%" sx={{ pr: 1, mt: 1 }}>
        <Divider />
      </Box>
    </>
  );
}
