import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import ltLocale from "date-fns/locale/lt";
import lt from "date-fns/locale/lt";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker/DesktopDatePicker";
// import DesktopTimePicker from "@mui/lab/DesktopTimePicker/DesktopTimePicker";

import { FormHelperText, Grid } from "@mui/material";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function DateTimePickerComponent({
  initialAppointmentDateTime,
  appointmentDateTime,
  setAppointmentDateTime,
  setCalendarError,
  setAppointmentDateTimeChanged,
}) {
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  const [date, setDate] = useState(appointmentDateTime);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [time, setTime] = useState(appointmentDateTime);

  // Check if appointment time has changed
  const appointmentDateTimeChanged = useMemo(() => {
    const initialAppointmentDateTimeString = !!initialAppointmentDateTime
      ? new Date(initialAppointmentDateTime).toISOString()
      : "";
    const appointmentDateTimeString = !!appointmentDateTime
      ? appointmentDateTime.toISOString()
      : "";
    return initialAppointmentDateTimeString !== appointmentDateTimeString;
  }, [appointmentDateTime, initialAppointmentDateTime]);

  useEffect(() => {
    if (!appointmentDateTime) {
      setDate(null);
      setTime(null);
      setAppointmentDateTimeChanged(appointmentDateTimeChanged);
    }
  }, [
    appointmentDateTime,
    appointmentDateTimeChanged,
    setAppointmentDateTimeChanged,
  ]);

  // console.log(
  //   "appointmentDateTimeChanged from DateTimePicker",
  //   appointmentDateTimeChanged
  // );

  // set appointmentDateTime when date and time changes
  useEffect(() => {
    if (!date && !time) {
      setAppointmentDateTime(null);
      setCalendarError(false);
    }
    if (!date && !!time) {
      setCalendarError(true);
    }
    if (!!date?.getTime() && !dateError && !timeError) {
      let newAppointmentDateTime = new Date(date);
      newAppointmentDateTime.setHours(time?.getHours() || 0);
      newAppointmentDateTime.setMinutes(time?.getMinutes() || 0);
      newAppointmentDateTime.setSeconds(0);
      setAppointmentDateTime(newAppointmentDateTime);
      setAppointmentDateTimeChanged(appointmentDateTimeChanged);
      setCalendarError(false);
    }
    if (dateError || timeError) {
      setCalendarError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time, dateError, timeError, appointmentDateTimeChanged]);

  function handleDateChange(newDate) {
    setDate(newDate);
    // if (!newDate) {
    //   setDate(null);
    // } else if (!!newDate.getTime() && newDate >= today) {
    //   newDate.setHours(time?.getHours() || 0);
    //   newDate.setMinutes(time?.getMinutes() || 0);
    //   newDate.setSeconds(0);
    //   setDate(newDate);
    // }
  }

  function handleTimeChange(newTime) {
    setTime(newTime);
    // if (!newTime) {
    //   setTime(null);
    // } else if (!!newTime?.getTime()) {
    //   newTime.setSeconds(0);
    //   date.setHours(newTime.getHours());
    //   date.setMinutes(newTime.getMinutes());
    //   date.setSeconds(0);
    //   setTime(newTime);
    //   setDate(date);
    // }
  }

  // console.log(
  //   "appointmentDateTime, date, time from DateTimePickerComponent \n",
  //   initialAppointmentDateTime,
  //   "\n",
  //   appointmentDateTime,
  //   "\n",
  //   date,
  //   "\n",
  //   time,
  //   "\n",
  //   appointmentDateTimeChanged
  // );

  // console.log("appointmentDateTimeChanged", appointmentDateTimeChanged);
  // console.log("initialAppointmentDateTime", initialAppointmentDateTime);
  // console.log("appointmentDateTime from DateTimePicker", appointmentDateTime);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={lt}>
      <Grid
        container
        justifyContent="space-between"
        sx={{ border: "none" }}
        spacing={1}
      >
        <Grid xs={12} sm={6} item sx={{ width: "100%", mt: 3, border: "none" }}>
          <DesktopDatePicker
            label="Data"
            emptyLabel="Data"
            views={["year", "month", "day"]}
            value={date}
            onChange={handleDateChange}
            onError={(error) => setDateError(error)}
            // onAccept={handleDateChange}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
            inputFormat="yyyy/MM/dd"
            disablePast
            mask="____/__/__"
            sx={{ width: "100%" }}
          />
          {!!dateError && (
            <FormHelperText error>
              {dateError === "invalidDate"
                ? "Formatas: yyyy/MM/dd"
                : "Praeities data negalima"}
            </FormHelperText>
          )}
        </Grid>
        <Grid xs={12} sm={6} item sx={{ width: "100%", mt: 3, border: "none" }}>
          <DesktopTimePicker
            label="Laikas"
            emptyLabel="Laikas"
            value={time}
            onChange={handleTimeChange}
            onError={(error) => setTimeError(error)}
            // onAccept={handleTimeChange}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
            ampm={false}
            mask="__:__"
            sx={{ width: "100%" }}
          />
          {!!timeError && (
            <FormHelperText error>Formatas: HH:mm</FormHelperText>
          )}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
