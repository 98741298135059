import React, { useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Badge,
  Grid,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
// import ChatStatusFilter from "../MyAds/";
import CustomAvatar from "../../../Layout/CustomAvatar";
import AppointmentBox from "../../MyAds/Appointment/AppointmentBox";
import { useGlobalContext } from "../../../../context";
import ContactStatusSelector from "../../SharedComponents/ContactStatusSelector";
import useClearAppointmentNotifications from "../../../Layout/Header/Notification/AppointmentNotification/useClearAppointmentNotifications";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { green } from "@mui/material/colors";
import { getDefaultPhotoUrl } from "../../../../utils/getDefaultPhotoUrl";
// import useClearNotifications from "../../SharedComponents/useClearNotifications";

export default function ContactList({ openDrawer, setOpenDrawer }) {
  let history = useHistory();
  const { dashboardState, userState, notificationState } = useGlobalContext();
  let { tabName, adId, extension } = useParams();
  const chatFilterValue =
    dashboardState.myEnquiryChatFilterValue || "only relevant";

  const openChat = extension === "chat";
  const userId = userState.userId;

  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();
  useClearAppointmentNotifications();

  const contacts = dashboardState.adContacts.filter(
    (enquiry) => enquiry.enquirer === +userId
  );

  const newMessageNotifications = notificationState.notifications.map(
    (notification) => notification.enquiry.ad
  );

  const orderedContacts = useMemo(
    () => getOrderedContacts(contacts),
    [contacts, chatFilterValue]
  );

  function getOrderedContacts(contacts) {
    let orderedContacts = [];
    if (!!contacts?.length) {
      orderedContacts = contacts.sort((a, b) =>
        a.owner_name.localeCompare(b.owner_name)
      );
      if (chatFilterValue === "only relevant") {
        orderedContacts = orderedContacts.filter(
          (enquiry) => !["blocked", "irrelevant"].includes(enquiry.owner_status)
        );
      }
      if (chatFilterValue === "all") {
        orderedContacts = orderedContacts.filter(
          (enquiry) => enquiry.owner_status !== "blocked"
        );
      }
      if (chatFilterValue === "only blocked") {
        orderedContacts = orderedContacts.filter(
          (enquiry) => enquiry.owner_status === "blocked"
        );
      }
    }
    return orderedContacts;
  }

  // open/close chat window and change url parameters when user selects an enquirer
  function handleContactClick(event, index) {
    let selectedAdId = orderedContacts[index].ad;
    // setAd(dashboardState.myEnquiryAds.find((a) => a.id === selectedAdId));
    let chat = Number(adId) === selectedAdId && openChat ? "" : "/chat";
    history.replace(
      `/dashboard/${tabName}/${selectedAdId}/${userState.userId}${chat}`
    );
    setOpenDrawer(!chat);
  }

  // console.log("dashboardState from ContactList", dashboardState);
  // console.log("orderedContacts from ContactList", contacts, orderedContacts);
  // console.log("tabName from ContactList", tabName);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        // pt: 3,
        border: "none",
        width: "100%",
        maxHeight: "250px",
        // width: "1000px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {!orderedContacts.length && (
        <Grid container sx={{ py: 2, pl: 1 }}>
          <Typography variant="subtitle2">Pokalbių nėra.</Typography>
        </Grid>
      )}
      {!!orderedContacts.length &&
        !!dashboardState.myEnquiryAds?.length &&
        orderedContacts.map((enquiry, index) => {
          let ownerBadge = newMessageNotifications.includes(enquiry.ad);
          const ad = dashboardState.myEnquiryAds.find(
            (a) => a.id === enquiry.ad
          );
          // console.log("ad from Enquiry contact list", ad);

          return (
            <ListItem
              key={enquiry.ad}
              disablePadding
              sx={{ display: "block", borderBottom: "solid lightgrey 1px" }}
            >
              <ListItemButton
                selected={enquiry.ad === Number(adId)}
                onClick={(event) => handleContactClick(event, index)}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  // px: 2.5,
                  py: openDrawer ? 0.5 : 3,
                  pr: openDrawer ? 1 : 2,
                  "&.Mui-selected": {
                    bgcolor: green[50],
                    "&:hover": {
                      bgcolor: green[50],
                    },
                  },
                }}
                disableRipple={openDrawer}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    variant="dot"
                    color={ownerBadge ? "secondary" : "default"}
                  >
                    {/* <CustomAvatar
                      userId={ad.properties.owner}
                      name={ad.properties.name}
                      styleProps={{
                        width: "30px",
                        height: "30px",
                      }}
                    /> */}
                    <Box
                      component="img"
                      src={
                        ad?.properties?.photos[0]?.photo_thumbnail ||
                        getDefaultPhotoUrl()
                      }
                      sx={{ width: "40px" }}
                    />
                  </Badge>
                </ListItemIcon>
                <ListItemText sx={{ opacity: openDrawer ? 1 : 0 }}>
                  <Box
                    sx={{
                      border: "none",
                      width: "100%",
                      alignItems: "center",
                      display: openDrawer ? "flex" : "none",
                      justifyContent: "space-between",
                      // display: openChat ? "none" : "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Box
                      sx={{
                        ml: 1,
                        width: isMobile ? "auto" : "30%",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomAvatar
                        userId={ad.properties.owner}
                        name={ad.properties.name}
                        styleProps={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                      {!isMobile && (
                        <Typography
                          variant="body1"
                          noWrap
                          sx={{
                            opacity:
                              enquiry.owner_status === "irrelevant" ? 0.4 : 1,
                            ml: 1,
                          }}
                        >
                          {ad.properties?.name}
                        </Typography>
                      )}
                    </Box>
                    {!isSmallOrMobile && (
                      <Box
                        sx={{
                          mx: 1,
                          width: isMobile ? "auto" : "30%",
                          justifyContent: "left",
                          border: "none",
                          flexWrap: "wrap",
                        }}
                      >
                        {!!ad.properties.phone && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // mr: 3,
                              border: "none",
                            }}
                          >
                            <PhoneIphoneIcon
                              fontSize="small"
                              color="action"
                              sx={{
                                mr: 0.5,
                                opacity:
                                  enquiry.owner_status === "irrelevant"
                                    ? 0.4
                                    : 1,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                opacity:
                                  enquiry.owner_status === "irrelevant"
                                    ? 0.4
                                    : 1,
                              }}
                            >
                              {formatPhoneNumberIntl(ad.properties.phone)}
                            </Typography>
                          </Box>
                        )}
                        {ad.properties.status === "expired" && (
                          <Typography color="error" variant="body1">
                            Skelbimas nebegalioja
                          </Typography>
                        )}
                        {ad.properties.status === "deleted" && (
                          <Typography color="error" variant="body1">
                            Skelbimas ištrintas
                          </Typography>
                        )}
                        {ad.properties.status === "blocked" && (
                          <Typography color="error" variant="body1">
                            Skelbimas užblokuotas
                          </Typography>
                        )}
                        {!["expired", "deleted", "blocked"].includes(
                          ad.properties.status
                        ) &&
                          !enquiry.owner_active && (
                            <Typography color="error" variant="body1">
                              Paskyra neaktyvi
                            </Typography>
                          )}
                      </Box>
                    )}
                    <AppointmentBox enquiry={enquiry} />
                    <ContactStatusSelector enquiry={enquiry} index={index} />
                  </Box>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            // <Divider sx={{ display: openChat ? "none" : "block" }} />
            // </Box>
          );
        })}
    </List>
  );
}
