import axios from "axios";
import { refreshAccessToken } from "../components/Accounts/fetchOrSendAccountsData";

const baseURL =
  process.env.REACT_APP_API_BASE_URL?.replace("http://", "https://") ||
  "http://localhost:8000/";

function redirectToLogin() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("userState");
  window.location.href = "/login";
}

const axiosInstance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  withCredentials: true, // allows sending cookies to a different domain
});

// A request interceptor to add the JWT token to the authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    // console.log("request interceptor executed");
    const accessTokenFromLocalStorage = localStorage.getItem("access_token");
    const accessTokenFromSessionStorage =
      sessionStorage.getItem("access_token");
    const accessToken =
      accessTokenFromLocalStorage || accessTokenFromSessionStorage;

    // Set Authorization header if access token exists
    if (accessToken) {
      config.headers["Authorization"] = "JWT " + accessToken;
    }
    // config.headers["Authorization"] = !!accessToken
    //   ? "JWT " + accessToken
    //   : null;
    // Check if the request is carrying a file by checking if data is FormData
    const isFileUpload = config.data instanceof FormData;

    // Only set Content-Type to application/json if it's not a file upload
    if (!isFileUpload) {
      config.headers["Content-Type"] = "application/json";
    } else {
      // Ensure Content-Type is not explicitly set for FormData to allow the browser to set it
      // along with the correct boundary. Delete the Content-Type header if it exists.
      delete config.headers["Content-Type"];
    }

    return config;
  },
  (error) => Promise.reject(error)
);

let refreshAccessTokenPromise = null;
// A response interceptor to handle and refresh the JWT token
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log("error from axiosInstance interceptor", error);
    const originalRequest = error.config;

    if (
      ["api/auth/jwt/verify/", "api/auth/users/me/"].includes(
        originalRequest.url
      ) &&
      error.response.status === 401
    ) {
      return Promise.reject(error);
    }

    if (
      originalRequest.url === "api/auth/jwt/refresh/" &&
      [400, 401].includes(error.response.status)
    ) {
      // console.log("token refresh failed", error.response);
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (
      error?.response?.status === 401 &&
      error?.response?.data?.detail ===
        "Token is issued before the last login time"
    ) {
      // console.log("Token is issued before the last login time", error.response);
      // alert("about to redirect to login 1");
      redirectToLogin();
      return Promise.reject(error);
    }

    if (
      error?.response?.status === 401 &&
      (error?.response?.data?.code === "token_not_valid" ||
        error?.response?.data?.detail ===
          "Authentication credentials were not provided.")
    ) {
      // console.log("access token not valid", error.response);
      // console.log("refreshAccessTokenPromise", refreshAccessTokenPromise);
      // alert("about to refresh token" + refreshAccessTokenPromise);

      if (!!refreshAccessTokenPromise) {
        // console.log(
        //   "refreshAccessTokenPromise not null",
        //   refreshAccessTokenPromise
        // );
        return refreshAccessTokenPromise
          .then(() => {
            // alert(
            //   "refreshAccessTokenPromise not null and resolved" +
            //     error.config.url
            // );
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            // console.log("err", err);
            // alert("refreshAccessTokenPromise failed to resolve");
          });
      } else {
        // console.log("refreshAccessTokenPromise is null");
        refreshAccessTokenPromise = refreshAccessToken()
          .then(() => {
            // alert(
            //   "refreshAccessToken successfully resolved" + error.config.url
            // );
            // console.log("refreshAccessToken response: success");
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            // alert("failed to resolve refreshAccessToken" + error.config.url);
            // console.log("refreshAccessToken response: failure", err);
            // window.location.href = "/login";
            // alert("redirect to Login 2 from: " + originalRequest.url);
            redirectToLogin();
            return Promise.reject(error);
          })
          .finally(() => (refreshAccessTokenPromise = null));
        return refreshAccessTokenPromise;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
