import axiosInstance from "../../utils/axios";

export async function initializeModels() {
  return axiosInstance
    .post("api/management/initialize-models/")
    .then((res) => {
      // console.log(
      //   "Server response: model initiation task successfully started",
      //   res.data
      // );
      return res.data.task_id;
    })
    .catch((error) => {
      // console.log("Server response: model initiation task failed", error)
    });
}
