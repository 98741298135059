import { Drawer, Menu } from "@mui/material";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

export default function DrawerOrMenu({
  children,
  handleAdTypeChange,
  ...otherProps
}) {
  const { isSmallOrMobile } = useCustomMediaQuery();
  const rect = otherProps?.anchorEl?.getBoundingClientRect();
  // console.log("drawerOrMenu props", otherProps);

  return isSmallOrMobile ? (
    <Drawer
      anchor="top"
      open={!!otherProps.anchorEl}
      onClose={handleAdTypeChange}
      {...otherProps}
    >
      {children}
    </Drawer>
  ) : (
    <Menu
      anchorEl={otherProps.anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      // getContentAnchorEl={null}
      // keepMounted
      variant="menu"
      open={Boolean(otherProps.anchorEl)}
      onClose={handleAdTypeChange}
      slotProps={{ paper: { sx: { width: rect.width } } }}
      // PaperProps={{
      //   sx: {
      //     width: isMediumOrSmaller
      //       ? state?.leftMenuRef?.current?.getBoundingClientRect()?.width
      //       : "400px",
      //   },
      // }}
      // sx={{
      //   left:  !isMediumOrSmaller ? 0 : -15,
      //   mt: isMediumOrSmaller ? 0 : 1,
      // }}
      // sx={{ left: 15, mt: 1 }}
      marginThreshold={0}
      MenuListProps={{ sx: { py: 0 } }}
    >
      {children}
    </Menu>
  );
}
