import StyledListItem from "./StyledListItem";
import {
  MIN_RENT_PERIOD_CHOICES,
  MIN_RENT_PERIOD_CHOICES_LAND,
} from "../../../utils/adOptions";

export function capitalizeFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stringifyArray(ad, attr) {
  return ad.properties[attr]
    .map((obj) => obj[attr])
    .toString()
    .toLowerCase()
    .replace(/,/g, ", ");
}

export function getBuildingDescription(ad) {
  const buildingDescription = `${
    ad.properties.building_type !== "Kita" ? ad.properties.building_type : ""
  } ${ad.properties.floors} ${
    Number(ad.properties.floors) === 1 ? "aukšto" : "aukštų"
  } pastatas, ${ad.properties.construction_year} m. statybos`;
  return <StyledListItem>{buildingDescription}</StyledListItem>;
}

export function getBuildingDescriptionPremises(ad) {
  let buildingDescription = `${
    ad.properties.building_type !== "Kita" ? ad.properties.building_type : ""
  } ${ad.properties.floors} ${
    Number(ad.properties.floors) === 1 ? "aukšto" : "aukštų"
  } pastatas, ${ad.properties.construction_year} m. statybos`;
  if (!!ad.properties.renovation) {
    let renovationYear = ` (renovuotas ${ad.properties.renovation_year} m.)`;
    buildingDescription += renovationYear;
  }

  return <StyledListItem>{buildingDescription}</StyledListItem>;
}

export function getHeating(ad) {
  return (
    <StyledListItem>Šildymas: {stringifyArray(ad, "heating")}</StyledListItem>
  );
}

export function getEnergyEfficiency(ad) {
  const energyEfficiency =
    ad.properties.energy_efficiency.length > 2
      ? ad.properties.energy_efficiency.toLowerCase()
      : ad.properties.energy_efficiency;
  return (
    !!energyEfficiency && (
      <StyledListItem>
        Energetinis efektyvumas: {energyEfficiency}
      </StyledListItem>
    )
  );
}

export function getInstallation(ad) {
  const flatRenovated = !!ad.properties?.flat_renovated?.length
    ? ` (įrengta ${stringifyArray(ad, "flat_renovated")})`
    : "";
  const installation = `${
    ad.properties.installation === "Pilna apdaila"
      ? `${
          ad.properties.installation
        }, ${ad.properties.condition.toLowerCase()} `
      : ""
  } ${
    ad.properties.installation === "Dalinė apdaila"
      ? `${ad.properties.installation}${flatRenovated}`
      : ""
  }`;
  return <StyledListItem>{installation}</StyledListItem>;
}

export function getInstallationPremises(ad) {
  const installation =
    ad.properties.installation === "Pilna apdaila"
      ? `${
          ad.properties.installation
        }, ${ad.properties.condition.toLowerCase()} `
      : ad.properties.installation;
  return <StyledListItem>{installation}</StyledListItem>;
}

export function getEquipment(ad) {
  const equipment = stringifyArray(ad, "equipment");
  return (
    !!equipment && (
      <StyledListItem>
        {["Parduodamos patalpos", "Nuomojamos patalpos"].includes(
          ad.properties.ad_type
        )
          ? "Patalpų "
          : "Būsto "}
        įranga: {equipment}
      </StyledListItem>
    )
  );
}

export function getPremises(ad) {
  const premises = stringifyArray(ad, "premises");
  return (
    !!premises && (
      <StyledListItem>Papildomos patalpos: {premises}</StyledListItem>
    )
  );
}

export function getPremisesPurpose(ad) {
  const premisesPurpose = stringifyArray(ad, "premises_purpose");
  return (
    !!premisesPurpose && (
      <StyledListItem>Paskirtis: {premisesPurpose}</StyledListItem>
    )
  );
}

export function getPartialAmenities(ad) {
  const partialAmenities = stringifyArray(ad, "partial_amenities");
  return (
    !!partialAmenities && (
      <StyledListItem>Daliniai patogumai: {partialAmenities}</StyledListItem>
    )
  );
}

export function getSecurity(ad) {
  const security = stringifyArray(ad, "security");
  return !!security && <StyledListItem>Saugumas: {security}</StyledListItem>;
}

export function getParking(ad) {
  let parking = stringifyArray(ad, "parking");
  return (
    !!parking && (
      <StyledListItem>Automobilių parkavimas: {parking}</StyledListItem>
    )
  );
}

export function getRecreation(ad) {
  const recreation = stringifyArray(ad, "recreation");
  return (
    !!recreation && (
      <StyledListItem>
        Laisvalaikio erdvės apylinkėse: {recreation}
      </StyledListItem>
    )
  );
}

export function getWaterBodies(ad) {
  const waterBodies = stringifyArray(ad, "water_body");
  return (
    !!waterBodies && (
      <StyledListItem>
        Vandens telkiniai netoliese: {waterBodies}
      </StyledListItem>
    )
  );
}

export function getForestType(ad) {
  const forestType = ad.properties.forest_type;
  return (
    !!forestType && (
      <StyledListItem>Miško tipas: {forestType.toLowerCase()}</StyledListItem>
    )
  );
}

export function getForestGroup(ad) {
  let forestGroup = ad.properties.forest_group;
  if (!!forestGroup.length) {
    forestGroup = forestGroup
      .map((group) => group["forest_group"].split(": ")[1])
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ");
  } else {
    forestGroup = null;
  }
  return (
    !!forestGroup && <StyledListItem>Miško grupė: {forestGroup}</StyledListItem>
  );
}

export function getIsLoggingForest(ad) {
  const isLoggingForest = ad.properties.is_logging_forest;
  return (
    <>
      {isLoggingForest === true && (
        <StyledListItem>Galimas komercinis kirtimas</StyledListItem>
      )}
      {isLoggingForest === false && (
        <StyledListItem>Komercinis kirtimas negalimas</StyledListItem>
      )}
    </>
  );
}

export function getFertilityScore(ad) {
  const fertilityScore = ad.properties.fertility_score;
  return (
    !!fertilityScore && (
      <StyledListItem>Našumo balas: {fertilityScore}</StyledListItem>
    )
  );
}

export function getAuction(ad) {
  const auction = capitalizeFirst(
    ad.properties.sale_type
      .filter((el) =>
        ["Turto varžytynės", "Parduodama aukciono būdu"].includes(el.sale_type)
      )
      .map((el) => el.sale_type)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return !!auction && <StyledListItem>{auction}</StyledListItem>;
}

export function getExchange(ad) {
  const exchange = ad.properties.sale_type
    .map((el) => el.sale_type)
    .includes("Domina keitimas");
  return exchange && <StyledListItem>Domina keitimas</StyledListItem>;
}

export function getPreferences(ad) {
  const preferences = capitalizeFirst(
    ad.properties.sale_type
      .filter((el) =>
        [
          "Parduodama skubiai",
          "Pirmenybė gryniesiems",
          "Pirmenybė pirkėjui be paskolos",
        ].includes(el.sale_type)
      )
      .map((el) => el.sale_type)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return !!preferences && <StyledListItem>{preferences}</StyledListItem>;
}

export function getNonnegotiable(ad) {
  const nonnegotiable = ad.properties.sale_type
    .map((el) => el.sale_type)
    .includes("Kaina galutinė");
  return !!nonnegotiable && <StyledListItem>Kaina galutinė</StyledListItem>;
}

export function getNoIntermediaries(ad) {
  const noIntermediaries = ad.properties.sale_type
    .map((el) => el.sale_type)
    .includes("Tarpininkų paslaugos nedomina");
  return (
    !!noIntermediaries && (
      <StyledListItem>Tarpininkų paslaugos nedomina</StyledListItem>
    )
  );
}

export function getAllowedTenants(ad) {
  const allowedTenants = capitalizeFirst(
    ad.properties.rent_type
      .filter((el) =>
        [
          "Nuomojama tik vienam asmeniui",
          "Nuomojama tik vienam arba dviems asmenims",
          "Draugiška šeimoms su vaikais",
          "Draugiška augintiniams",
        ].includes(el.rent_type)
      )
      .map((el) => el.rent_type)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return !!allowedTenants && <StyledListItem>{allowedTenants}</StyledListItem>;
}

export function getPreferLongerContracts(ad) {
  const preferLongerContracts = capitalizeFirst(
    ad.properties.rent_type
      .filter((el) =>
        [
          "Pirmenybė ilgesnio termino sutartims",
          "Galima nuolaida nuomojantis ilgesniam terminui",
        ].includes(el.rent_type)
      )
      .map((el) => el.rent_type)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return (
    preferLongerContracts && (
      <StyledListItem>{preferLongerContracts}</StyledListItem>
    )
  );
}

export function getMinRentPeriod(ad) {
  let options =
    ad.properties.ad_type === "Nuomojamas sklypas"
      ? MIN_RENT_PERIOD_CHOICES_LAND
      : MIN_RENT_PERIOD_CHOICES;
  const minRentPeriod = options.find(
    (option) => option.value === ad.properties.min_rent_period
  )?.label;
  return (
    <StyledListItem>Minimali nuomos trukmė: {minRentPeriod}</StyledListItem>
  );
}

export function getDepositRequired(ad) {
  const depositRequired = ad.properties.rent_type
    .filter((el) => el.rent_type === "Imamas užstatas")
    .map((el) => el.rent_type)
    .toString();
  // console.log("depositRequired", depositRequired);
  return depositRequired && <StyledListItem>{depositRequired}</StyledListItem>;
}

export function getFormalContract(ad) {
  const formalContract = capitalizeFirst(
    ad.properties.rent_type
      .filter((el) =>
        [
          "Galimas atsiskaitymas per banką",
          'Galimas sutarties registravimas "Registrų centre"',
          "Galimas gyvenamosios vietos deklaravimas",
        ].includes(el.rent_type)
      )
      .map((el) => el.rent_type)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
      .replace("registrų centre", "Registrų centre")
  );
  return !!formalContract && <StyledListItem>{formalContract}</StyledListItem>;
}

export function getNoRentAgency(ad) {
  const noRentAgency = capitalizeFirst(
    ad.properties.rent_type
      .filter((el) =>
        [
          "Nėra tarpininkavimo mokesčio",
          "Tarpininkų paslaugos nedomina",
        ].includes(el.rent_type)
      )
      .map((el) => el.rent_type)
      .sort()
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return !!noRentAgency && <StyledListItem>{noRentAgency}</StyledListItem>;
}

export function getHeatingBill(ad) {
  const heatingBill = ad.properties.heating_bill;
  return (
    !!heatingBill && (
      <StyledListItem>
        Vidutinė žiemos mėnesio šildymo sąskaita siekia {heatingBill} Eur
      </StyledListItem>
    )
  );
}

export function getHeatingCost(ad) {
  const heatingCost = ad.properties.heating_bill;
  return (
    !!heatingCost && (
      <StyledListItem>
        Žiemos metu vidutiniai 1 m² patalpų šildymo kaštai siekia {heatingCost}{" "}
        € per mėn.
      </StyledListItem>
    )
  );
}

export function getRenovation(ad) {
  const renovation =
    !!ad.properties.renovation_year &&
    `${ad.properties.renovation_year} m. atlikta
              ${
                ad.properties.building_renovated
                  .map((obj) => obj.building_renovated.toLowerCase())
                  .includes("pilna renovacija")
                  ? "pilna renovacija"
                  : `dalinė renovacija (${stringifyArray(
                      ad,
                      "building_renovated"
                    )})`
              }; išmokėta renovacijos dalis - ${
      ad.properties.renovation_paid
    } proc.`;
  return (
    !!ad.properties.renovation_year && (
      <StyledListItem>{renovation}</StyledListItem>
    )
  );
}

export function getflatDescription(ad) {
  let flatDescription = `${ad.properties.rooms} ${
    Number(ad.properties.rooms) < 2 ? "kambario" : "kambarių"
  } ${
    Number(ad.properties.area) % 1 === 0
      ? Number(ad.properties.area).toFixed(0)
      : Number(ad.properties.area).toFixed(1)
  } kv. m ploto butas ${ad.properties.floor}-ame aukšte`;
  const flatsInFloor = !!ad.properties.flats_in_floor
    ? ` (laiptinės aukšte yra ${
        ad.properties.flats_in_floor === "11+"
          ? "daugiau nei 10"
          : ad.properties.flats_in_floor
      } ${
        Number(ad.properties.flats_in_floor) === 1
          ? "butas"
          : Number(ad.properties.flats_in_floor) < 10
          ? "butai"
          : "butų"
      })`
    : "";
  flatDescription += flatsInFloor;
  return <StyledListItem>{flatDescription}</StyledListItem>;
}

export function getFlatRenovation(ad) {
  const flatRenovation = stringifyArray(ad, "flat_renovated");
  return (
    flatRenovation && (
      <StyledListItem>Būste atnaujinta {flatRenovation}</StyledListItem>
    )
  );
}

export function getOtherFeatures(ad) {
  const otherFeatures = stringifyArray(ad, "other_features");
  return (
    !!otherFeatures && (
      <StyledListItem>Kiti ypatumai: {otherFeatures}</StyledListItem>
    )
  );
}

export function getHouseDescription(ad) {
  const houseDescription = `${ad.properties.rooms}
        ${Number(ad.properties.rooms) < 2 ? " kambario" : " kambarių"}
        ${
          Number(ad.properties.area) % 1 === 0
            ? Number(ad.properties.area).toFixed(0)
            : Number(ad.properties.area).toFixed(1)
        } kv. m ploto ${ad.properties.house_type.toLowerCase()}`;
  return <StyledListItem> {houseDescription}</StyledListItem>;
}

export function getHouseRenovation(ad) {
  const houseRenovation = `Atlikta
              ${
                ad.properties.building_renovated
                  .map((obj) => obj.building_renovated.toLowerCase())
                  .includes("pilna renovacija")
                  ? "pilna renovacija"
                  : `dalinė renovacija (${stringifyArray(
                      ad,
                      "building_renovated"
                    )})`
              }`;
  return (
    !!ad.properties.building_renovated.length && (
      <StyledListItem>{houseRenovation}</StyledListItem>
    )
  );
}

export function getPlot(ad) {
  const plot = `${
    Number(ad.properties?.plot_area) % 1 === 0
      ? Number(ad.properties?.plot_area).toFixed(0)
      : Number(ad.properties?.plot_area).toFixed(1)
  } a ploto sklypas`;
  return <StyledListItem>{plot}</StyledListItem>;
}

export function getPlotAreaPremises(ad) {
  const plotArea = ad.properties?.plot_area;
  const plotAreaDescription = `Priklausančios teritorijos plotas (arai): ${
    Number(plotArea) % 1 === 0
      ? Number(plotArea).toFixed(0)
      : Number(plotArea).toFixed(1)
  } a`;
  return (
    <>{!!plotArea && <StyledListItem>{plotAreaDescription}</StyledListItem>}</>
  );
}

export function getPlotPurpose(ad) {
  const plotPurpose = `${ad.properties.plot_purpose.toLowerCase()}`;
  return <StyledListItem>Paskirtis: {plotPurpose}</StyledListItem>;
}

export function getPlotCommunications(ad) {
  const plotCommunications = ad.properties.plot_infrastructure
    .filter((el) =>
      [
        "Elektra",
        "Dujos",
        "Asfaltuotas privažiavimas",
        "Televizijos ir interneto kabeliai",
      ].includes(el.plot_infrastructure)
    )
    .map((el) => el.plot_infrastructure)
    .toString()
    .toLowerCase()
    .replace(/,/g, ", ");
  return (
    !!plotCommunications && (
      <StyledListItem>Komunikacijos: {plotCommunications}</StyledListItem>
    )
  );
}

export function getLandPlotCommunications(ad) {
  let plotCommunications = ad.properties.plot_infrastructure.map(
    (el) => el.plot_infrastructure
  );

  if (plotCommunications.includes("Asfaltuotas privažiavimas")) {
    plotCommunications = plotCommunications.filter(
      (x) => x !== "Geras privažiavimas"
    );
  }
  plotCommunications = plotCommunications
    .toString()
    .toLowerCase()
    .replace(/,/g, ", ");
  return (
    !!plotCommunications && (
      <StyledListItem>
        Komunikacijos ir įrengimas: {plotCommunications}
      </StyledListItem>
    )
  );
}

export function getPlotInfrastructure(ad) {
  const plotInfrastructure = ad.properties.plot_infrastructure
    .filter(
      (el) =>
        ![
          "Elektra",
          "Dujos",
          "Asfaltuotas privažiavimas",
          "Televizijos ir interneto kabeliai",
        ].includes(el.plot_infrastructure)
    )
    .map((el) => el.plot_infrastructure)
    .toString()
    .toLowerCase()
    .replace(/,/g, ", ");
  return (
    !!plotInfrastructure && (
      <StyledListItem>Aplinkos įrengimas: {plotInfrastructure}</StyledListItem>
    )
  );
}

export function getInfrastructurePremises(ad) {
  const infrastructure = ad.properties.plot_infrastructure
    .map((el) => el.plot_infrastructure)
    .toString()
    .toLowerCase()
    .replace(/,/g, ", ");
  return (
    !!infrastructure && (
      <StyledListItem>
        Komunikacijos ir infrastruktūra: {infrastructure}
      </StyledListItem>
    )
  );
}

export function getWaterSewage(ad) {
  const waterSewage = stringifyArray(ad, "water_sewage");
  return (
    !!waterSewage && (
      <StyledListItem>Vanduo ir nuotekos: {waterSewage}</StyledListItem>
    )
  );
}

export function getParkingDescription(ad) {
  let [action, ...object] = ad.properties.ad_type.split(" ");
  object = object.join(" ");
  let size =
    +ad.properties.garage_size >= 10
      ? `(${ad.properties.garage_size} automobilių)`
      : +ad.properties.garage_size > 1
      ? `(${ad.properties.garage_size} automobiliams)`
      : "";

  let parkingDescription = `${ad.properties.area} m² ploto ${object} ${size}`;
  return <StyledListItem>{parkingDescription}</StyledListItem>;
}

export function getParkingType(ad) {
  let parkingType = ad.properties.garage_type.toLowerCase();
  if (
    [
      "Kelių aukštų garažų komplekse",
      "Kelių aukštų parkavimo aikštelėje",
    ].includes(ad.properties.garage_type)
  ) {
    parkingType += ` (${ad.properties.garage_floor} aukštas)`;
  }
  return <StyledListItem>Tipas: {parkingType}</StyledListItem>;
}

export function getParkingInstallation(ad) {
  const parkingInstallation = ad.properties.garage_installation
    .filter((el) =>
      [
        "Elektra",
        "Vanduo",
        "Šildomas",
        "Su duobe",
        "Automatiniai vartai",
        "Pritaikytas didelių gabaritų automobiliams",
      ].includes(el.garage_installation)
    )
    .map((el) => el.garage_installation)
    .toString()
    .toLowerCase()
    .replace(/,/g, ", ");
  return (
    !!parkingInstallation && (
      <StyledListItem>Įrengimas: {parkingInstallation}</StyledListItem>
    )
  );
}

export function getElectricalImprovements(ad) {
  const electricalImprovements = capitalizeFirst(
    ad.properties.garage_installation
      .filter((el) =>
        [
          "Didesnės galios elektros įvadas",
          "Įrengta elektromobilio įkrovimo stotelė",
          "Galimybė krauti elektromobilį",
        ].includes(el.garage_installation)
      )
      .map((el) => el.garage_installation)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return (
    !!electricalImprovements && (
      <StyledListItem>{electricalImprovements}</StyledListItem>
    )
  );
}

export function getParkingRoof(ad) {
  const parkingRoof = capitalizeFirst(
    ad.properties.garage_installation
      .filter((el) =>
        ["Parkavimo vieta po stogu", "Parkavimo vieta be stogo"].includes(
          el.garage_installation
        )
      )
      .map((el) => el.garage_installation)
      .toString()
      .toLowerCase()
      .replace(/,/g, ", ")
  );
  return !!parkingRoof && <StyledListItem>{parkingRoof}</StyledListItem>;
}

export function getApartmentComplexDescription(ad) {
  const num = ad.properties.number_of_buildings;
  const floors =
    !!ad.properties.floors_from &&
    (ad.properties.floors_from === ad.properties.floors_to
      ? ad.properties.floors_from
      : `${ad.properties.floors_from}-${ad.properties.floors_to}`);
  const description =
    num === 1
      ? `${ad.properties.floors} aukštų daugiabutis`
      : `${num} daugiabučių (${floors} aukštų) kompleksas`;
  return <StyledListItem>{description}</StyledListItem>;
}

export function getCompletionDate(ad) {
  const completionDate = `${ad.properties.construction_year} m. ${ad.properties.quarter}`;
  return <StyledListItem>Statybos pabaiga: {completionDate}</StyledListItem>;
}

export function getBuildingType(ad) {
  const buildingType = ad.properties.building_type;
  return (
    <StyledListItem>
      Pastato konstrukcija: {buildingType.toLowerCase()}
    </StyledListItem>
  );
}

export function getRoomsRange(ad) {
  const isOneRoom = ad.properties.rooms_from === ad.properties.rooms_to;
  const rooms = isOneRoom
    ? ad.properties.rooms_from
    : `${ad.properties.rooms_from}-${ad.properties.rooms_to}`;
  return <StyledListItem>Kambarių skaičius bute: {rooms}</StyledListItem>;
}

export function getAreaRange(ad) {
  const isOneArea = ad.properties.area_from === ad.properties.area_to;
  let area;
  if (!Number(ad.properties.area_from)) {
    area = Number(ad.properties.area).toFixed(0);
  } else {
    area = isOneArea
      ? Number(ad.properties.area_from).toFixed(0)
      : `${Number(ad.properties.area_from).toFixed(0)}-${Number(
          ad.properties.area_to
        ).toFixed(0)}`;
  }
  return <StyledListItem>Butų plotai: {area} m²</StyledListItem>;
}

export function getPriceRange(ad) {
  let price;
  if (!!ad.properties?.price_from) {
    const isOnePrice = ad.properties.price_from === ad.properties.price_to;
    price = isOnePrice
      ? ad.properties.price_from
      : `${ad.properties.price_from}-${ad.properties.price_to}`;
  } else {
    price = ad.properties.price;
  }
  return (
    <StyledListItem>
      Butų kainos: {price}€ ({getPriceSqmRange(ad)} €/m²)
    </StyledListItem>
  );
}

export function getPriceSqmRange(ad) {
  let priceSqm;
  const isSmall =
    (!!ad.properties.price_sqm && Number(ad.properties.price_sqm) <= 100) ||
    (!!ad.properties.price_sqm_from &&
      Number(ad.properties.price_sqm_from) <= 100);
  if (!!ad.properties?.price_sqm_from) {
    const isOnePrice =
      ad.properties.price_sqm_from === ad.properties.price_sqm_to;
    priceSqm = isOnePrice
      ? Number(ad.properties.price_sqm_from).toFixed(isSmall ? 1 : 0)
      : `${Number(ad.properties.price_sqm_from).toFixed(
          isSmall ? 1 : 0
        )}-${Number(ad.properties.price_sqm_to).toFixed(isSmall ? 1 : 0)}`;
  } else {
    priceSqm = Number(ad.properties.price_sqm).toFixed(isSmall ? 1 : 0);
  }
  return priceSqm;
}

export function getInstallationComplex(ad) {
  const installation = ad.properties.installation
    .replace("ė", "e")
    .toLowerCase();
  const price =
    ad.properties.installation === "Dalinė arba pilna apdaila"
      ? ad.properties.installation_price_sqm
      : "";
  return (
    <StyledListItem>
      Butai su {installation}
      {!!price ? ` (pilnos apdailos kaina nuo ${price} €/m²)` : ""}
    </StyledListItem>
  );
}
