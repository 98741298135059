export const initialMainAppState = {
  // queryParams,
  initialAppLoad: true,
  loading: true,
  adCards: [],
  loadingAdCards: false,
  ads: [],
  loadinAds: false,
  resultsPerPage: +localStorage.getItem("resultsPerPage") || 20,
  resultMessage: "",
  resultCount: 0,
  totalPages: 0,
  preferencesSelected: false,
  placesSelected: false,
  previousScrollPosition: 0,
  mapCenter: [54.686741, 25.290675], // Gedimino pilis
  geom: null,
  mapZoom: 15,
  mySearches: [],
  municipalityArray: [],
  municipalityOptionsLoaded: false,
  selectedMunicipalities: [],
  townOptions: [],
  townOptionsLoaded: false,
  selectedTowns: [],
  suburbOptions: [],
  suburbOptionsLoaded: false,
  locationOptions: [],
  locationOptionsLoaded: false,
  selectedLocationIds: "",
  selectedSuburbs: [],
  streetOptions: [],
  streetOptionsLoaded: false,
  selectedStreets: [],
  showMap: localStorage.getItem("showMap") === "true" ? true : false,
  ownerAdsFilterValue: "all",
};

export function mainAppReducer(state, action) {
  let ad;
  switch (action.type) {
    case "CHANGE_APP_STATE":
      let payload = action.payload;
      state = {
        ...state,
        ...payload,
      };
      return state;
    case "SAVE_INFRASTRUCTURE_DATA":
      ad = { ...state.ad, ...action.payload };
      state = { ...state, ad };
      return state;
    case "UPDATE_AD":
      let adToUpdate = action.payload.adToUpdate;
      let updatedAds = state.ads.filter((ad) => ad.id !== adToUpdate.id);
      updatedAds.push(adToUpdate);
      state = {
        ...state,
        ads: updatedAds,
      };
      return state;
    case "UPDATE_NOTE":
      let noteObj = action.payload.noteObj;
      if (state?.ad?.id === noteObj.ad) {
        ad = state.ad;
        ad.properties.note = noteObj;
        state = { ...state, ad };
      }
      if (state.ads.map((ad) => ad.id).includes(noteObj.ad)) {
        let ad = state.ads.find((ad) => ad.id === noteObj.ad);
        state.ads.pop(ad);
        ad.properties.note = noteObj;
        state.ads.push(ad);
      }
      return state;
    case "CHANGE_AD_PROPERTIES":
      let propertiesToUpdate = action.payload;
      ad = state.ad;
      // console.log("mainAppState from reducer", state);
      ad.properties = { ...ad.properties, ...propertiesToUpdate };
      state = { ...state, ad, adCardsLoaded: false, adsLoaded: false, ads: [] };
      return state;
    case "CHANGE_AD_HISTORY":
      const adId = action.payload.adId;
      const adActions = action.payload.adActions;
      ad = state.ads.find((ad) => ad.id === adId);
      if (!!ad) {
        ad.properties.ad_actions = adActions;
        state.ad = ad;
      }
      return state;
    case "LOGOUT_USER":
      state = { ...initialMainAppState };
      return state;

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
