export const initialNotificationState = {
  notifications: [],
  newNotification: null,
  checkNotification: null,
  notificationSocket: null,
  searchNotificationSettings: {
    send_notifications: false,
    check_intervals: "1_days",
    notification_modes: "mail",
    firebase_token: "",
  },
  searchNotificationSettingsLoaded: false,
};

export function notificationReducer(notificationState, action) {
  let notifications, notificationsToDelete;

  switch (action.type) {
    case "CHANGE_NOTIFICATION_STATE":
      let payload = action.payload;
      notificationState = { ...notificationState, ...payload };
      return notificationState;

    case "ADD_NEW_NOTIFICATION":
      let newNotification = action.payload.newNotification;
      notifications = notificationState.notifications;
      notifications.unshift(newNotification);
      notificationState = { ...notificationState, notifications };
      return notificationState;

    case "CLEAR_NOTIFICATIONS":
      notificationsToDelete = action.payload.notifications_to_delete;
      notifications = notificationState.notifications.filter(
        (notification) => !notificationsToDelete.includes(notification.id)
      );
      notificationState = { ...notificationState, notifications };
      return notificationState;

    case "LOGOUT_USER":
      notificationState = { ...initialNotificationState };
      return notificationState;

    default:
      throw new Error(`Unknown router action type: ${action.type}`);
  }
}
