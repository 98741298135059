import React, { useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Box, Button } from "@mui/material";
import {
  fetchUserData,
  getContactAvatarData,
  loginWithGoogle,
} from "./fetchOrSendAccountsData";
import { useGlobalContext } from "../../context";
import { crudSavedSearch } from "../Dashboard/fetchOrSendDashboardData";
import GoogleIcon from "@mui/icons-material/Google";
import { useHistory } from "react-router-dom";

function GoogleLoginButton() {
  const { userState, dispatchUser, dispatchDashboard, routerState } =
    useGlobalContext();
  const history = useHistory();

  useEffect(() => {
    if (userState.isAuthenticated && userState.userId) {
      fetchUserData(userState.userId, dispatchUser);
      getContactAvatarData(dispatchUser);
      crudSavedSearch(dispatchDashboard, "get");
      //   if (userState.userCreated) {
      //     history.push("/user-profile");
      //   }
    }
  }, [userState.userId]);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // console.log("Google token response", tokenResponse);
      loginWithGoogle(tokenResponse.access_token, dispatchUser);
    },
    onError: (error) => {
      // console.log("Google login failed", error);
    },
    // scope: "openid profile email",
    // flow: "implicit", // Ensure the flow is set to implicit to get id_token
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        border: "none",
      }}
    >
      <Button
        variant="outlined"
        onClick={() => login()}
        startIcon={<GoogleIcon />}
        fullWidth
        sx={{
          borderColor: "lightgrey",
          "&:hover": {
            borderColor: "lightgrey",
          },
        }}
      >
        Jungtis su Google
      </Button>
    </Box>
  );
}

export default GoogleLoginButton;
