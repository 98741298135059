import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";
import {
  BUILDING_TYPE_CHOICES,
  ENERGY_EFFICIENCY_CHOICES,
  HEATING_CHOICES,
  BUILDING_RENOVATED_CHOICES,
} from "../../../../utils/adOptions";
import { Formik, Form } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

function BuildingPanel({ ad, handleSubmit, edit, formikRef, initiateSubmit }) {
  const { isFlat, isHouse } = useAdType(ad);
  const { adFormState } = useGlobalContext();
  const panelName = "buildingPanel";
  const initialValues =
    (!edit && adFormState.formik[panelName]) ||
    init.getBuildingInitialValues(ad, adOptions);
  const validationSchema = schema.buildingValidationSchema;

  function handleRenovationChange(e, formik) {
    if (!e.target.checked) {
      formik.setFieldValue("renovation_year", null);
      formik.setFieldValue("renovation_paid", null);
      formik.setFieldValue("building_renovated", []);
      formik.setFieldTouched("renovation_year", false);
      formik.setFieldTouched("renovation_paid", false);
      formik.setFieldTouched("building_renovated", false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="floors"
                label="Pastato aukštų skaičius *"
                type="number"
                inputProps={{
                  min: 0,
                  max: 30,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="construction_year"
                label="Statybos metai *"
                type="number"
                inputProps={{
                  min: 1900,
                  max: 2025,
                  step: 1,
                }}
              />
            </Grid>

            <Grid item xs={6} sx={{ my: 1 }}>
              <FormikSelect
                name="building_type"
                label="Pastato konstrukcija *"
                options={BUILDING_TYPE_CHOICES}
              />
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
              <FormikSelect
                name="energy_efficiency"
                label="Energetinis efektyvumas *"
                options={ENERGY_EFFICIENCY_CHOICES}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                formik={formik}
                name="heating"
                label="Šildymas *"
                options={HEATING_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField
                name="heating_bill"
                label={
                  isFlat || isHouse
                    ? "Vidutinė žiemos mėn. šildymo sąskaita (Eur per mėn.)"
                    : "Vidutiniai 1 m² šildymo kaštai žiemą (Eur/m² per mėn.)"
                }
                type="number"
              />
            </Grid>

            {+formik.values.construction_year < 2000 && (
              <>
                <Grid item xs={12} sm={6} sx={{ my: 2 }}>
                  <FormikCheckbox
                    name="renovation"
                    label="Pastatas renovuotas"
                    onChange={(e) => handleRenovationChange(e, formik)}
                  />
                </Grid>
                {formik.values.renovation &&
                  ["premises_sale", "premises_rent"].includes(
                    formik.values.ad_type
                  ) && (
                    <Grid item xs={6} sx={{ my: 1 }}>
                      <FormikTextField
                        name="renovation_year"
                        label="Renovacijos metai *"
                        type="number"
                        inputProps={{
                          min: 2000,
                          step: 1,
                        }}
                      />
                    </Grid>
                  )}
              </>
            )}

            {formik.values.renovation &&
              ["flat_sale"].includes(formik.values.ad_type) && (
                <>
                  <Grid item xs={12} sx={{ my: 1 }}>
                    <FormikMultipleSelect
                      name="building_renovated"
                      label="Renovacijos apimtys *"
                      options={BUILDING_RENOVATED_CHOICES}
                    />
                  </Grid>

                  <Grid item xs={6} sx={{ my: 1 }}>
                    <FormikTextField
                      name="renovation_year"
                      label="Renovacijos metai *"
                      type="number"
                      inputProps={{
                        min: 2000,
                        step: 1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ my: 1 }}>
                    <FormikTextField
                      name="renovation_paid"
                      label="Išmokėta dalis (%) *"
                      type="number"
                      inputProps={{
                        min: 1,
                        max: 100,
                        step: 1,
                      }}
                    />
                  </Grid>
                </>
              )}
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default BuildingPanel;
