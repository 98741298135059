import React from "react";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";

import { useGlobalContext } from "../../../context";
import reloadAds from "../../../utils/getUrlFromParams";
import { Box } from "@mui/material";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import AdsPerPageSelector from "./AdsPerPageSelector";

export default function PageNavigation() {
  const { state, dispatch } = useGlobalContext();
  const history = useHistory();
  const totalPages = state.totalPages;
  const params = useParamsFromUrl();

  function handlePageChange(e, page) {
    let newParams = { ...params, page };
    reloadAds(newParams, dispatch, history);
  }

  return (
    <Box
      sx={{
        display: "flex",
        mt: 3,
        px: 0.5,
        flexDirection: { xs: "column", sm: "row" },
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      gap={2}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "left",
          border: "none",
        }}
      >
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            color="primary"
            variant="outlined"
            shape="rounded"
            page={+params?.page || 1}
            onChange={handlePageChange}
          />
        )}
      </Box>
      {totalPages > 0 && (
        <Box sx={{ flexGrow: 0 }}>
          <AdsPerPageSelector />
        </Box>
      )}
    </Box>
  );
}
