import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views-react-18-fix";
// import { autoPlay } from "react-swipeable-views-utils";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { grey } from "@mui/material/colors";
import useKeyPress from "../../../hooks/useKeyPress";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";
import { getDefaultPhotoUrl } from "../../../utils/getDefaultPhotoUrl";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../../utils/defaults";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export function resized(url) {
  let dotPosition = url.lastIndexOf(".");
  return url.slice(0, dotPosition) + ".resized" + url.slice(dotPosition);
}

function SwipeableCarousel(props) {
  const {
    ad,
    activeSlide,
    setActiveSlide,
    open: openFullScreenCarousel,
    setOpen: setOpenFullScreenCarousel,
  } = props;
  const { isMobile } = useCustomMediaQuery();

  // console.log("ad from SwipeableCarousel", ad);
  const photos = ad.properties.photos;
  const [isLoaded, setIsLoaded] = useState(false);

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(activeSlide);
  const [slideChange, setSlideChange] = useState(0);
  const maxSteps = photos.length;

  useEffect(() => {
    setActiveStep(activeSlide);
  }, [activeSlide]);

  const handleNext = () => {
    setActiveSlide(Math.min(activeStep + 1, maxSteps - 1));
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, maxSteps - 1)
    );
  };

  const handleBack = () => {
    setActiveSlide(Math.max(0, activeStep - 1));
    setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1));
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    setActiveSlide(step);
  };

  useEffect(() => {
    if ((slideChange === 1) & (activeStep < maxSteps - 1)) handleNext();
    if ((slideChange === -1) & (activeStep > 0)) handleBack();
    setSlideChange(0);
  }, [slideChange]);

  useKeyPress(39, () => {
    if (!!openFullScreenCarousel) setSlideChange(1);
  }); // on RightArrowPress
  useKeyPress(37, () => {
    if (!!openFullScreenCarousel) setSlideChange(-1);
  }); // on LeftArrowPress
  // on Escape press, close fill screen carousel
  useKeyPress(27, () => {
    if (!!openFullScreenCarousel) setOpenFullScreenCarousel(false);
  });

  //   two functions to differentiate between mouse click and mouse drag
  function mouseDownCoords(e) {
    e.preventDefault();
    window.checkForDrag = e.clientX;
  }

  const clickOrDrag = (e) => {
    e.preventDefault();
    const mouseUp = e.clientX;
    if (
      mouseUp < window.checkForDrag + 6 &&
      mouseUp > window.checkForDrag - 6
    ) {
      setOpenFullScreenCarousel(true);
    }
  };

  return (
    <>
      <Box
        onMouseDown={(e) => mouseDownCoords(e)}
        onMouseUp={(e) => clickOrDrag(e)}
        onLoad={() => setIsLoaded(true)}
        position="relative"
        sx={{
          border: "none",
          width: "100%",
          // height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <>
          {!!openFullScreenCarousel && (
            <Box
              position="absolute"
              sx={{
                border: "none",
                zIndex: 1000,
                height: "100%",
                width: "50%",
                left: 0,
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
                handleBack();
              }}
            />
          )}
          {isLoaded && (
            <IconButton
              onMouseUp={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleBack();
              }}
              size={isMobile ? "small" : "large"}
              sx={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "translate(0, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                opacity: 0.8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                },
                ml: 1,
              }}
            >
              <ChevronLeftIcon
                fontSize="large"
                sx={{ color: activeStep === 0 ? grey[800] : grey[50] }}
              />
            </IconButton>
          )}

          {!!openFullScreenCarousel && (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                handleNext();
              }}
              position="absolute"
              sx={{
                right: 0,
                width: "50%",
                height: "100%",
                zIndex: 1000,
                border: "none",
              }}
            />
          )}
          {isLoaded && (
            <IconButton
              onMouseUp={(e) => {
                e.stopPropagation();
                handleNext();
              }}
              size={isMobile ? "small" : "large"}
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translate(0, -50%)",
                zIndex: 1000,
                right: 0,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                opacity: 0.8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                },
                mr: 1,
              }}
            >
              <ChevronRightIcon
                fontSize="large"
                sx={{
                  color: activeStep === maxSteps - 1 ? grey[800] : grey[50],
                }}
              />
            </IconButton>
          )}
        </>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          // onSwitching={() => setIsCarouselLoaded(true)}
        >
          {photos.map((photo, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",

                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={
                    openFullScreenCarousel
                      ? {
                          width: "auto",
                          height: "auto",
                          maxWidth: "90vw",
                          maxHeight: "90vh",
                          // height: photo.height >= photo.width ? "562px" : "auto",
                          // maxHeight: "500px",
                          // maxHeight: "80vh",
                          // width: photo.height >= photo.width ? "auto" : "1000px",
                          // maxWidth: "1000px",
                          // maxWidth: "80vw",
                          // objectFit: "cover",
                          border: "none",
                        }
                      : {
                          width: "100%",
                          aspectRatio: String(ASPECT),
                          objectFit: "cover",
                        }
                  }
                  src={
                    openFullScreenCarousel
                      ? getFullImgUrl(photo.photo) || getDefaultPhotoUrl()
                      : getFullImgUrl(photo.photo_crop) || getDefaultPhotoUrl()
                  }
                  alt={index}
                />
              ) : null}
            </Box>
          ))}
        </SwipeableViews>
      </Box>
    </>
  );
}

export default SwipeableCarousel;
