import * as Yup from "yup";
import { isCity } from "./utils";

export function getLocationValidationSchema(municipalityOptions) {
  const locationValidationSchema = Yup.object().shape({
    municipality: Yup.number("Privalomas laukas")
      .positive(" ")
      .integer(" ")
      .required("Privalomas laukas")
      .typeError(" ")
      .nullable(),
    town: Yup.number()
      .positive("Turi būti teigiamas skaičius")
      .integer("Turi būti sveikas skaičius")
      .nullable()
      .typeError(" ")
      .when("municipality", {
        is: (municipality) => !isCity(municipalityOptions, municipality),
        then: Yup.number()
          .positive("Turi būti teigiamas skaičius")
          .integer("Turi būti sveikas skaičius")
          .required("Privalomas laukas")
          .nullable()
          .typeError(" "),
      }),
    // location: Yup.number()
    //   .integer("Turi būti sveikas skaičius")
    //   .nullable()
    //   .typeError(" ")
    //   .when("municipality", {
    //     is: (municipality) => !isCity(municipalityOptions, municipality),
    //     then: Yup.number()
    //       .positive("Turi būti teigiamas skaičius")
    //       .integer("Turi būti sveikas skaičius")
    //       .required("Privalomas laukas")
    //       .nullable()
    //       .typeError(" "),
    //   }),
    suburb: Yup.number().positive().integer().nullable().typeError(" "),
    street: Yup.number()
      // .positive("Turi būti teigiamas skaičius")
      // .integer("Turi būti sveikas skaičius")
      // .required("Privalomas laukas")
      .nullable()
      .typeError(" "),
    house: Yup.string().max(10, "Maks. 10 simbolių").nullable(),
    // .required("Privalomas laukas")
    show_house: Yup.boolean(),
    show_flat: Yup.boolean(),
    flat: Yup.string().max(10, "Maks. 10 simbolių").nullable(),
    // .when("show_flat", {
    //   is: true,
    //   then: Yup.string()
    //     .max(10, "Maks. 10 simbolių")
    //     .required("Privalomas laukas")
    //     .nullable(),
    // })
    loc_x: Yup.number().nullable(),
    loc_y: Yup.number().nullable(),
    is_exact: Yup.boolean()
      .required("Privaloma pasirinkti")
      .typeError("Privaloma pasirinkti"),
    // .oneOf(
    //   [true],
    //   "Užtikrinkite, kad objekto vieta žemėlapyje būtų tiksli. Vilkite žymeklį į reikiamą vietą ir patvirtinkite."
    // ),
  });
  return locationValidationSchema;
}

export const buildingValidationSchema = Yup.object().shape({
  construction_year: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  building_type: Yup.string().required("Privalomas laukas").nullable(),
  floors: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("floor", {
      is: (floor) => +floor > 0,
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .min(
          Yup.ref("floor"),
          "Aukštų skaičius negali būti mažesnis už buto aukštą"
        )
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  energy_efficiency: Yup.string().required("Privalomas laukas").nullable(),
  heating: Yup.array().min(1, "Privalomas laukas"),
  heating_bill: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  renovation: Yup.boolean().nullable(),
  renovation_year: Yup.number("Įveskite renovacijos metus")
    .nullable()
    .when(["renovation", "ad_type"], {
      is: (renovation, ad_type) =>
        renovation === true &&
        ["flat_sale", "premises_sale", "premises_rent"].includes(ad_type),
      then: Yup.number("Įveskite renovacijos metus")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .min(
          Yup.ref("construction_year"),
          "Renovacija negalėjo būti vykdyta anksčiau nei statyba"
        )
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  renovation_paid: Yup.number("Įveskite skaičių nuo 0 iki 100 proc.")
    .nullable()
    .when(["renovation", "ad_type"], {
      is: (renovation, ad_type) =>
        renovation === true && ad_type === "flat_sale",
      then: Yup.number("Įveskite skaičių nuo 0 iki 100 proc.")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .min(0, "Įveskite skaičių nuo 0 iki 100 proc.")
        .max(100, "Įveskite skaičių nuo 0 iki 100 proc.")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  building_renovated: Yup.array().when(["renovation", "ad_type"], {
    is: (renovation, ad_type) => renovation === true && ad_type === "flat_sale",
    then: Yup.array().min(1, "Privalomas laukas").required("Privalomas laukas"),
  }),
});

export const buildingHouseValidationSchema = Yup.object().shape({
  house_type: Yup.string().required("Privalomas laukas").nullable(),
  building_type: Yup.string().required("Privalomas laukas").nullable(),
  rooms: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .max(20, "Negali būti daugiau nei 20")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  construction_year: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  floors: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  energy_efficiency: Yup.string().required("Privalomas laukas").nullable(),
  heating: Yup.array().min(1, "Privalomas laukas"),
  heating_bill: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  renovation: Yup.boolean(),
  renovation_year: Yup.number("Įveskite renovacijos metus")
    .nullable()
    .when("renovation", {
      is: true,
      then: Yup.number("Įveskite renovacijos metus")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .min(
          Yup.ref("construction_year"),
          "Negali būti vykdyta anksčiau nei statybos metai"
        )
        .required("Privalomas laukas")
        .typeError("Turi būti skaičius"),
    }),
  building_renovated: Yup.array().when("renovation", {
    is: true,
    then: Yup.array().min(1, "Privalomas laukas").required("Privalomas laukas"),
  }),
});

export const buildingApartmentComplexValidationSchema = Yup.object().shape({
  company_name: Yup.string().required("Privalomas laukas").nullable(),
  project_name: Yup.string().nullable(),
  number_of_buildings: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  floors: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_buildings", {
      is: (number_of_buildings) =>
        !number_of_buildings || +number_of_buildings === 1,
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  floors_from: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_buildings", {
      is: (number_of_buildings) => +number_of_buildings > 1,
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  floors_to: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .min(Yup.ref("floors_from"), "Turi būti nemažesnis už minimalią reikšmę")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_buildings", {
      is: (number_of_buildings) => +number_of_buildings > 1,
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .min(
          Yup.ref("floors_from"),
          "Turi būti nemažesnis už minimalią reikšmę"
        )
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  construction_year: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  quarter: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  building_type: Yup.string().required("Privalomas laukas").nullable(),
  energy_efficiency: Yup.string().required("Privalomas laukas").nullable(),
  heating: Yup.array().min(1, "Privalomas laukas"),
  heating_bill: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
});

export const equipmentHouseValidationSchema = Yup.object().shape({
  // rooms: Yup.number("Turi būti skaičius")
  //   .positive("Turi būti teigiamas skaičius")
  //   .integer("Turi būti sveikas skaičius")
  //   .required("Privalomas laukas")
  //   .nullable()
  //   .typeError("Turi būti skaičius"),
  // area: Yup.number("Turi būti skaičius")
  //   .positive("Turi būti teigiamas skaičius")
  //   .required("Privalomas laukas")
  //   .nullable()
  //   .typeError("Turi būti skaičius"),
  // floor: Yup.number()
  //   .positive("Turi būti teigiamas skaičius")
  //   .integer("Turi būti sveikas skaičius")
  //   .required("Privalomas laukas")
  //   .nullable()
  //   .typeError("Turi būti skaičius"),
  // flats_in_floor: Yup.number("Turi būti skaičius")
  //   .positive("Turi būti teigiamas skaičius")
  //   .integer("Turi būti sveikas skaičius")
  //   .typeError("Turi būti skaičius"),
  installation: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("ad_type", {
      is: "house_sale",
      then: Yup.string().required("Privalomas laukas").nullable(),
    }),
  condition: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("installation", {
      is: "full",
      then: Yup.string().required("Privalomas laukas").nullable().typeError(""),
    }),
  flat_renovated: Yup.array(),
  equipment: Yup.array(),
  premises: Yup.array(),
  // other_features: Yup.array(),
  partial_amenities: Yup.array(),
});

export const flatValidationSchema = Yup.object().shape({
  rooms: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  floor: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .max(Yup.ref("floors"), "Negali viršyti pastato aukštų skaičiaus")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  flats_in_floor: Yup.string().nullable(),
  installation: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("ad_type", {
      is: "flat_sale",
      then: Yup.string().required("Privalomas laukas").nullable(),
    }),
  condition: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("installation", {
      is: "full",
      then: Yup.string().required("Privalomas laukas").nullable().typeError(""),
    }),
  flat_renovated: Yup.array(),
  equipment: Yup.array(),
  premises: Yup.array(),
  other_features: Yup.array(),
  partial_amenities: Yup.array(),
});

export const flatApartmentComplexValidationSchema = Yup.object().shape({
  flats: Yup.array()
    .min(
      1,
      "Pasirinkite bent vieną iš egzistuojančių skelbimų (vystytojo parduodamų butų šiame projekte)"
    )
    .required("Privalomas laukas"),
  installation: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("ad_type", {
      is: "flat_sale",
      then: Yup.string().required("Privalomas laukas").nullable(),
    }),
  installation_price_sqm: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("installation", {
      is: "full_or_partial",
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  premises_in_complex: Yup.array(),
  parking_in_complex: Yup.array(),
});

export const premisesValidationSchema = Yup.object().shape({
  floors: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  premises_purpose: Yup.array().min(1, "Privalomas laukas"),
  number_of_premises: Yup.string()
    .required("Privalomas laukas")
    .nullable()
    .typeError(""),
  area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_premises", {
      is: "one",
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),

  area_from: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_premises", {
      is: "several",
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  area_to: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .min(Yup.ref("area_from"), "Turi būti nemažesnis už minimalią reikšmę")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_premises", {
      is: "several",
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .min(Yup.ref("area_from"), "Turi būti nemažesnis už minimalią reikšmę")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  // floor: Yup.number()
  //   .max(Yup.ref("floors"), "Negali viršyti pastato aukštų skaičiaus")
  //   .min(-3, "Minimali leidžiama reikšmė: -3")
  //   .integer("Turi būti sveikas skaičius")
  //   .required("Privalomas laukas")
  //   .nullable()
  //   .typeError("Turi būti skaičius"),
  floor_from: Yup.number()
    .max(Yup.ref("floors"), "Negali viršyti pastato aukštų skaičiaus")
    .min(-3, "Minimali leidžiama reikšmė: -3")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  floor_to: Yup.number()
    .max(Yup.ref("floors"), "Negali viršyti pastato aukštų skaičiaus")
    .min(Yup.ref("floor_from"), "Turi būti nemažesnis už minimalią reikšmę")
    // .min(-3, "Minimali leidžiama reikšmė: -3")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_premises", {
      is: "one",
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  price_from: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_premises", {
      is: "several",
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),

  price_to: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .min(Yup.ref("price_from"), "Turi būti nemažesnė už minimalią reikšmę")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("number_of_premises", {
      is: "several",
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .min(Yup.ref("price_from"), "Turi būti nemažesnė už minimalią reikšmę")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  min_rent_period: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("ad_type", {
      is: "premises_rent",
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
  installation: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("ad_type", {
      is: "premises_sale",
      then: Yup.string().required("Privalomas laukas").nullable(),
    }),
  condition: Yup.string()
    .nullable()
    .typeError("Type error")
    .when("installation", {
      is: "full",
      then: Yup.string().required("Privalomas laukas").nullable().typeError(""),
    }),
  equipment: Yup.array(),
  premises: Yup.array(),
  other_features: Yup.array(),
});

export const environmentValidationSchema = Yup.object().shape({
  security: Yup.array(),
  parking: Yup.array(),
  recreation: Yup.array(),
});

export const environmentHouseValidationSchema = Yup.object().shape({
  plot_area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  water_sewage: Yup.array(),
  plot_infrastructure: Yup.array(),
  security: Yup.array(),
  parking: Yup.array(),
  recreation: Yup.array(),
  water_body: Yup.array(),
});

export const environmentPremisesValidationSchema = Yup.object().shape({
  plot_area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  plot_infrastructure: Yup.array(),
  security: Yup.array(),
  parking: Yup.array(),
});

export const environmentLandValidationSchema = Yup.object().shape({
  plot_area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  plot_purpose: Yup.string().required("Privalomas laukas").nullable(),
  forest_group: Yup.array(),
  forest_type: Yup.string().nullable(),
  forest_mature: Yup.string().nullable(),
  is_logging_forest: Yup.boolean()
    // .required("Būtina pasirinkti")
    .typeError("")
    .nullable()
    .when("plot_purpose", {
      is: "forest",
      then: Yup.boolean()
        .typeError("")
        .oneOf([true, false], "Būtina pasirinkti"),
    }),
  water_sewage: Yup.array(),
  plot_infrastructure: Yup.array(),
  recreation: Yup.array(),
  water_body: Yup.array(),
  fertility_score: Yup.number("Įveskite skaičių nuo 0 iki 100")
    .positive("Turi būti teigiamas skaičius")
    .min(0, "Įveskite skaičių nuo 0 iki 100")
    .max(100, "Įveskite skaičių nuo 0 iki 100")
    .nullable()
    .typeError("Turi būti skaičius"),
});

export const descriptionValidationSchema = Yup.object().shape({
  description: Yup.string().max(10000, "Maks. 10000 simbolių").nullable(),
});

export const priceValidationSchema = Yup.object().shape({
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  sale_type: Yup.array(),
  hide_validation: Yup.boolean(),
});

export const priceRentValidationSchema = Yup.object().shape({
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  utils_included: Yup.boolean()
    .required("Būtina pasirinkti")
    .nullable()
    .oneOf([true, false], "Būtina pasirinkti"),
  min_rent_period: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  rent_type: Yup.array(),
  hide_validation: Yup.boolean(),
});

export const priceRentLandValidationSchema = Yup.object().shape({
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  min_rent_period: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  rent_type: Yup.array(),
});

export const ownerDetailsValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, "Maks. 40 simbolių")
    .required("Privalomas laukas")
    .nullable(),
  owner_type: Yup.string().required("Privalomas laukas").nullable(),
  company_name: Yup.string().nullable(),
  project_name: Yup.string().nullable(),
  phone: Yup.string()
    .required("Privalomas laukas")
    .max(20, "Maks. 20 simbolių")
    .test("is-valid-phone", "Netinkamas telefono numeris", (value) => {
      if (value && value.startsWith("+370")) {
        return value.length === 12;
      }
      return true;
    })
    .nullable(),
  show_phone: Yup.boolean(),
  email: Yup.string()
    .email("Turi būti tinkamas el. pašto adresas")
    .nullable()
    .when("show_email", {
      is: true,
      then: Yup.string()
        .email("Turi būti tinkamas el. pašto adresas")
        .required("Privalomas laukas")
        .nullable(),
    }),
  show_email: Yup.boolean(),
  show_owner_photo: Yup.boolean(),
  show_company_logo: Yup.boolean(),
});

export const garageValidationSchema = Yup.object().shape({
  garage_installation: Yup.array(),
  garage_type: Yup.string().required("Privalomas laukas").nullable(),
  garage_floor: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .max(10, "Maks. leistina reikšmė: 10")
    .min(-5, "Min. leistina reikšmė -5")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("garage_type", {
      is: (garage_rent) =>
        garage_rent === "multi_storey_garages" ||
        garage_rent === "multi_storey_parking",
      then: Yup.number("Turi būti skaičius nuo -5 iki 10")
        .required("Privalomas laukas")
        .integer("Turi būti sveikas skaičius")
        .max(10, "Maks. leistina reikšmė: 10")
        .min(-5, "Min. leistina reikšmė -5")
        .nullable()
        .typeError("Turi būti skaičius nuo -5 iki 10"),
    }),
  area: Yup.number("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .required("Privalomas laukas")
    .nullable()
    .typeError("Turi būti skaičius"),
  garage_size: Yup.number("Turi būti skaičius")
    .required("Privalomas laukas")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  security: Yup.array(),
  price: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  min_rent_period: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("ad_type", {
      is: (ad_type) => ad_type === "garage_rent" || ad_type === "parking_rent",
      then: Yup.number()
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .required("Privalomas laukas")
        .nullable()
        .typeError("Turi būti skaičius"),
    }),
});
