export function debounce(cb, delay = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // console.log("args inside timer", ...args);
      cb(...args);
    }, delay);
  };
}

export function debounceSubsequent(cb, delay = 500) {
  let timer = 0;
  return (...args) => {
    // console.log("timer", timer);
    if (Date.now() - timer > delay) {
      // console.log("timer callback");
      cb(...args);
    }
    timer = Date.now();
  };
}
