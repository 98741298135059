import React, { useRef } from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const containerRef = useRef(null);

  // let scrollHeight = window.scrollHeight;
  // console.log("Full document height, with scrolled out part: " + scrollHeight);
  // console.log("containerRef", containerRef);

  return (
    <Box
      component="div"
      ref={containerRef}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      sx={{
        border: "none",
        width: "100%",
      }}
    >
      {value === index && (
        <Box sx={{ p: 0, display: "flex", justifyContent: "center" }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
