import { useEffect } from "react";
import { useGlobalContext } from "../../context";
import { useParams, useHistory } from "react-router-dom";
import {
  fetchAd,
  fetchFlatOptionsComplex,
  registerAdView,
} from "./fetchOrSendAdData";
import {
  fetchAdCards,
  fetchAds,
  fetchLikedAdIds,
} from "../MainPage/fetchOrSendMainAppData";

export default function AdDataProvider({ children }) {
  const { routerState, state, dispatch, userState } = useGlobalContext();
  const visitor_id = userState?.visitor_id || "";
  const currentUser = userState?.userId;
  const { slug, adId } = useParams();
  const history = useHistory();
  // useRouterTracker("individualAd");

  const ad =
    +state?.ad?.id === +adId
      ? state.ad
      : state?.ads?.find((ad) => +ad?.id === +adId);

  useEffect(() => {
    // if (!!ad?.id) {
    !!currentUser
      ? history.replace(`/skelbimas/${slug}/${adId}/${currentUser}/chat`)
      : history.replace(`/skelbimas/${slug}/${adId}`);
    // }
  }, [adId, currentUser, history]);

  useEffect(() => {
    if (!!ad) {
      dispatch({ type: "CHANGE_APP_STATE", payload: { ad } });
    } else {
      fetchAd(dispatch, adId);
    }
  }, [ad, dispatch, adId, state.ads]);

  useEffect(() => {
    if (!!visitor_id) {
      registerAdView(adId, visitor_id);
    }
  }, [adId, visitor_id]);

  useEffect(() => {
    if (ad?.id && ad.properties.ad_type === "Naujas daugiabučių projektas") {
      fetchFlatOptionsComplex(dispatch, ad.id);
    }
  }, [ad?.id]);

  useEffect(() => {
    if (userState.isAuthenticated) {
      if (!state.adCardsLoaded && !state.adCardsLoading) {
        fetchAdCards(routerState.appParams, dispatch);
      }
      if (!state.adsLoaded && !state.adsLoading) {
        fetchAds(routerState.appParams, dispatch);
      }
      if (!state.likedAdIdsLoaded && !state.likedAdIdsLoading) {
        fetchLikedAdIds({}, dispatch);
      }
    }
  }, [userState.isAuthenticated]);

  return children;
}
