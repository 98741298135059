import React from "react";
import { Link } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

export default function Footer() {
  const { isMobile } = useCustomMediaQuery();

  return (
    <Box
      sx={{
        width: "100%",
        mt: 5,
        position: "relative",
        bottom: 0,
        border: "none",
      }}
    >
      <Divider />
      <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
        <Typography
          variant={isMobile ? "caption" : "body2"}
          sx={{ textAlign: "center", border: "none" }}
        >
          <Link
            to="/about-us"
            style={{
              display: "inline-block",
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "none",
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            Apie mus
          </Link>
          <Link
            to="/privacy-policy"
            style={{
              display: "inline-block",
              marginLeft: "10px",
              marginRight: "10px",
              textDecoration: "none",
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            Privatumo politika
          </Link>
          <Link
            to="/terms-of-use"
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              display: "inline-block",
              textDecoration: "none",
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            Naudojimosi taisyklės
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
