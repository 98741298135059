import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import PhotoList from "./PhotoGalery/PhotoList";
import SwipeableCarousel from "./PhotoGalery/SwipeableCarousel";
import { Box } from "@mui/system";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../utils/defaults";
import BasicAdInfo from "./BasicAdInfo/BasicAdInfo";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import { defaultPhotoUrl } from "../../utils/defaults";
import AdTitle from "./AdTitle";
import PhotoModal from "./PhotoGalery/PhotoModal";
import AdStatusText from "../MainPage/MainListing/AdCardFormats/AdStatusText";

const ExpiredAd = ({ ad }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [openFullScreenCarousel, setOpenFullScreenCarousel] = useState(false);

  return (
    <>
      {/* <Container maxWidth="lg" sx={{ pt: 13 }} disableGutters> */}
      <Grid container justifyContent="center" sx={{ border: "none" }}>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="left"
          sx={{ position: "relative", border: "none" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              pl: 1,
              mb: 1,
              justifyContent: "start",
              border: "none",
            }}
          >
            <BreadcrumbsComponent ad={ad} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ border: "none", display: "flex", pl: 1 }}
            justifyContent="left"
          >
            <AdTitle ad={ad} />
          </Grid>
          <Box
            sx={{
              width: "100%",
              // height: "auto",
              aspectRatio: String(ASPECT),
              border: "none",
              objectFit: "contain",
              px: 0.5,
            }}
          >
            {!showMap && (
              <>
                {!!ad.properties.photos.length && (
                  <Box sx={{ position: "relative" }}>
                    <AdStatusText ad={ad} zIndex={1} variant="body1" />
                    <SwipeableCarousel
                      ad={ad}
                      activeSlide={activeSlide}
                      setActiveSlide={setActiveSlide}
                      open={false}
                      setOpen={setOpenFullScreenCarousel}
                    />
                  </Box>
                )}
                {!ad.properties.photos.length && (
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                    }}
                    src={defaultPhotoUrl}
                    alt="default photo"
                  />
                )}
              </>
            )}
          </Box>

          <PhotoModal
            ad={ad}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            openFullScreenCarousel={openFullScreenCarousel}
            setOpenFullScreenCarousel={setOpenFullScreenCarousel}
          />

          {/* {ad.properties.photos.length && ( */}
          <Grid container sx={{ px: 0.5 }}>
            <PhotoList
              ad={ad}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setShowMap={setShowMap}
            />
          </Grid>
          {/* )} */}
        </Grid>

        <Grid
          item
          container
          xs={12}
          lg={8}
          sx={{ px: 0.5, mt: 1, border: "none", flex: 1 }}
        >
          <BasicAdInfo ad={ad} />
        </Grid>
      </Grid>
      {/* </Container> */}
    </>
  );
};

export default ExpiredAd;
