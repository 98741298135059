import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Box from "@mui/material/Box";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { Button, Stack, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useGlobalContext } from "../../../context";

export default function ShareAd({ ad }) {
  const { userState, dispatchUser } = useGlobalContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const sharePageUrl = `${window.location.origin}/skelbimai/${ad.properties.slug}/${ad.id}/`;

  function handleCopyLink() {
    navigator.clipboard.writeText(sharePageUrl);
    const snackbar = userState.snackbar;
    snackbar.open = true;
    snackbar.message = "Skelbimo nuoroda nukopijuota";
    snackbar.severity = "success";
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { snackbar },
    });
    handleClose();
  }

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} size="small">
        <ShareOutlinedIcon fontSize="small" color="inherit" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 1 }}
      >
        <Stack direction="column" sx={{ p: 1 }}>
          <Box sx={{ pl: 1 }}>
            <FacebookShareButton url={sharePageUrl}>
              <Stack
                direction="row"
                sx={{ alignItems: "center" }}
                spacing={1}
                onClick={handleClose}
              >
                <FacebookIcon size={28} round={true} />
                <Typography>Dalintis Facebooke</Typography>
              </Stack>
            </FacebookShareButton>
          </Box>
          <Button
            variant="text"
            sx={{
              textDecoration: "none",
              textTransform: "none",
              backgroundColor: "white",
              color: "inherit",
              "&:hover": { backgroundColor: "white" },
            }}
            onClick={handleCopyLink}
          >
            <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
              <LinkIcon color="action" sx={{ fontSize: 28 }} />
              <Typography>Kopijuoti nuorodą</Typography>
            </Stack>
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
