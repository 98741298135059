import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import FormikCheckbox from "../../SharedComponents/FormikCheckBox";
import FormikTextField from "../../SharedComponents/FormikTextField";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import {
  createAdComplaint,
  listAdComplaints,
} from "../../Accounts/fetchOrSendAccountsData";
import useBackHandler from "../../../hooks/useBackHandler";
import { useHistory } from "react-router-dom";
// import FormPreview from "../../AdForm/utils/FormPreview";

export default function ReportAdDialog({ open, setOpen }) {
  const { userState, dispatchUser } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const { adId } = useParams();
  const history = useHistory();
  useBackHandler(handleXClick);

  const visitorParams = {
    user: userState?.userId || null,
    visitor: userState.visitor_id || null,
  };

  const initialValues = {
    ...visitorParams,
    ad: +adId,
    erroneous_info: false,
    inappropriate_photos: false,
    irrelevant_content: false,
    offensive_content: false,
    fraud: false,
    other: false,
    additional_info: "",
  };

  useEffect(() => {
    if (!userState.adComplaintsLoaded && userState.visitorLoaded) {
      listAdComplaints(dispatchUser, visitorParams);
    }
  }, [
    userState.adComplaintsLoaded,
    userState.isAuthenticated,
    userState.visitorLoaded,
  ]);

  // trigger refresh of ad complaints if user logs in/off
  useEffect(() => {
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { adComplaintsLoaded: false },
    });
  }, [userState.isAuthenticated]);

  function handleSubmit(values) {
    // console.log("formik values", values);
    createAdComplaint(dispatchUser, values);
    setOpen(false);
    const snackbar = userState.snackbar;
    snackbar.open = true;
    snackbar.message = "Jūsų pranešimą gavome. Ačiū!";
    snackbar.severity = "success";
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { snackbar },
    });
  }

  function handleXClick() {
    setOpen(false);
  }

  // console.log("adId from ReportAdDialog", adId);

  return (
    <Dialog
      open={open}
      onClose={() => {
        history.goBack();
        handleXClick();
      }}
      fullScreen={isMobile}
    >
      <DialogTitle>
        Konfidencialus pranešimas apie netinkamą skelbimą
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <>
            <DialogContent>
              <Typography variant="body1">
                Skelbimas galimai netinkamas dėl šių priežasčių:
              </Typography>
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="erroneous_info"
                    label="Klaidinanti informacija"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="inappropriate_photos"
                    label="Netinkamos nuotraukos"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="irrelevant_content"
                    label="Netinkamas turinys"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="offensive_content"
                    label="Įžeidžiantis turinys"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="fraud"
                    label="Galimas sukčiavimas"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="other"
                    label="Kita"
                    labelPlacement="end"
                  />
                </Grid>
                <FormikTextField
                  name="additional_info"
                  label="Papildomas komentaras"
                  multiline
                  rows={4}
                  sx={{ mt: 2 }}
                />
                <Typography variant="caption" sx={{ mt: 1 }}>
                  Peržiūrėjus Jūsų pranešimą ir nustačius, kad skelbimas
                  neatitinka portalo naudojimosi taisyklių, jo publikavimas gali
                  būti laikinai sustabdytas. Dėkojame už pagalbą.
                </Typography>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => {
                  history.goBack();
                  handleXClick();
                }}
              >
                Atšaukti
              </Button>
              <Button
                // type="submit"
                variant="contained"
                disabled={Object.values(formik.values).every((value) => !value)}
                onClick={() => handleSubmit(formik.values)}
              >
                Pranešti
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
      {/* <FormPreview /> */}
    </Dialog>
  );
}
