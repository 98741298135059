import React from "react";
import Button from "@mui/material/Button";
import { Typography, Stack, Divider, Box } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { PREFERENCE_ARRAY, emptyPrefParams } from "../../../../utils/defaults";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { Form, Formik } from "formik";
import FormikSwitch from "../../../SharedComponents/FormikSwitch";

// const marks = [
//   {
//     value: 0,
//     label: "Nesvarbu",
//   },
//   {
//     value: 1,
//     label: "Pageidautina",
//   },
//   {
//     value: 2,
//     label: "Svarbu",
//   },
// ];

// // Auxiliary function for sliders' tooltips
// function ValueLabelComponent(props) {
//   const { children, open, value } = props;
//   return (
//     <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
//       {children}
//     </Tooltip>
//   );
// }

// ValueLabelComponent.propTypes = {
//   children: PropTypes.element.isRequired,
//   open: PropTypes.bool.isRequired,
//   value: PropTypes.number.isRequired,
// };

// Main PreferenceSelector component
export default function PreferenceSelector({
  formikRef,
  handleSubmit,
  // visibility,
  // setVisibility,
  setAnchorEl,
}) {
  const { dispatch } = useGlobalContext();

  const params = useParamsFromUrl();

  // console.log("params from PreferenceSelector", params);
  const prefParams = {};
  Object.keys(emptyPrefParams).forEach(
    (key) => (prefParams[key] = Number(params[key]))
  );
  const initialValues = { ...emptyPrefParams, ...prefParams };

  // const handleSliderChange = (preferenceParam) => (event, preferenceWeight) => {
  //   let newParams = { ...params, [preferenceParam]: preferenceWeight };
  //   if (
  //     preferenceParam === "distance" &&
  //     preferenceWeight > 0 &&
  //     !params?.place_coords
  //   ) {
  //     // // fetch is needed to preserve the change in params, when place selector opens
  //     // fetchAdCards(params, state, dispatch);
  //     dispatch({
  //       type: "CHANGE_APP_STATE",
  //       payload: { drawPlacesMode: true },
  //     });
  //     // setParams(newParams);
  //     // const urlObject = getUrlFromParams(newParams);
  //     // history.replace(urlObject);
  //     // fetchAdCards(newParams, state, dispatch);
  //     // return;
  //   }
  //   // setParams(newParams);
  // };

  // function handleSwitchChange(e, prefParam) {
  //   if (
  //     prefParam === "distance" &&
  //     !!e.target.checked &&
  //     !params?.place_coords
  //   ) {
  //     // alert("handleSwitchState");
  //     dispatch({
  //       type: "CHANGE_APP_STATE",
  //       payload: { drawPlacesMode: true },
  //     });
  //     setAnchorEl(null);
  //     // setVisibility("hidden");
  //   }
  // }

  // useEffect(() => {
  //   if (state.drawPlacesMode === false && visibility === "hidden") {
  //     setVisibility("visible");
  //   }
  // }, [setVisibility, state.drawPlacesMode, visibility]);

  // console.log("drawPlacesMode", state.drawPlacesMode);

  const filteredPreferenceArray = PREFERENCE_ARRAY.filter((pref) => {
    if (params.ad_type === "flat_sale") return true;
    if (params.ad_type === "house_sale" && pref.param !== "gross_rent_return")
      return true;
    if (params.ad_type === "flat_rent" && pref.param !== "gross_rent_return")
      return true;
    if (!["relative_value", "gross_rent_return"].includes(pref.param))
      return true;
    return false;
  });

  // console.log("preference array", PREFERENCE_ARRAY);
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      <Form>
        <Stack sx={{ px: 2 }}>
          <Divider sx={{ my: 1 }}>
            <Typography color="primary">Patogi vieta</Typography>
          </Divider>
          {filteredPreferenceArray.map((preference, index) => (
            <Box key={preference.param}>
              {preference.param === "relative_value" && (
                <Divider sx={{ my: 1 }}>
                  <Typography color="primary">Finansinė nauda</Typography>
                </Divider>
              )}
              {preference.param === "primary_education" && (
                <Divider sx={{ my: 1 }}>
                  <Typography color="primary">Miesto patogumai</Typography>
                </Divider>
              )}
              {preference.param === "bus_stops" && (
                <Divider sx={{ my: 1 }}>
                  <Typography color="primary">Susisiekimas</Typography>
                </Divider>
              )}
              {preference.param === "natural" && (
                <Divider sx={{ my: 1 }}>
                  <Typography color="primary">Aplinka</Typography>
                </Divider>
              )}
              <FormikSwitch
                name={preference.param}
                label={preference.label}
                // onChange={(e) => handleSwitchChange(e, preference.param)}
                disabled={
                  preference.param === "distance" && !params.place_coords
                }
              />
              {preference.param === "distance" && !params.place_coords && (
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography variant="body2" sx={{ mr: 2 }}>
                    Atkualios vietos nepasirinktos.
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    sx={{ opacity: 0.8 }}
                    onClick={() => {
                      dispatch({
                        type: "CHANGE_APP_STATE",
                        payload: { drawPlacesMode: true },
                      });
                      setAnchorEl(null);
                    }}
                  >
                    Pasirinkti vietas
                  </Button>
                </Box>
              )}
            </Box>
          ))}
        </Stack>
        {/* <FormPreview /> */}
      </Form>
    </Formik>
  );
}

// {
//   /* <Slider
//             value={params[preference.param] || 0}
//             onChange={handleSliderChange(preference.param)}
//             min={0}
//             max={2}
//             valueLabelDisplay="auto"
//             valueLabelFormat={(value) => marks[value].label}
//             ValueLabelComponent={ValueLabelComponent}
//             sx={{ width: 100 }}
//           /> */
// }
