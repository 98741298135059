import { useEffect } from "react";
import { useGlobalContext } from "../../../../context";
import { fetchTownOptions } from "../../fetchOrSendMainAppData";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

export default function TownFilterDataProvider({ children }) {
  const { dispatch, state } = useGlobalContext();
  const params = useParamsFromUrl();

  // console.log("params from TownFilterDataProvider", params);
  // console.log(
  //   "townOptionsLoaded, townOptionsLoading",
  //   state.townOptionsLoaded,
  //   state.townOptionsLoading
  // );

  useEffect(() => {
    let selectedMunicipalities = params?.municipality?.split(",");

    if (
      selectedMunicipalities?.length === 1 &&
      !state?.townOptionsLoaded &&
      !state?.townOptionsLoading
    ) {
      if (selectedMunicipalities?.length === 1) {
        fetchTownOptions(params, dispatch);
      }
    }
  }, [
    dispatch,
    params,
    params?.municipality,
    state?.townOptionsLoaded,
    state?.townOptionsLoading,
  ]);

  return children;
}
