import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import humanizeDate from "../../../../utils/humanizeDate";
import useAppointment from "../../MyAds/Appointment/useAppointment";
import useAppointmentMessage from "../../MyAds/Appointment/useAppointmentMessage";
import { useHistory } from "react-router-dom";
import {
  crudAppointment,
  crudCalendarEntry,
} from "../../fetchOrSendDashboardData";
import { useGlobalContext } from "../../../../context";
import useBackHandler from "../../../../hooks/useBackHandler";

export default function CalendarEntryDeleteDialog({ open, setOpen, entry }) {
  const { dispatchDashboard } = useGlobalContext();
  useBackHandler(handleXClose);
  const history = useHistory();
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  const { appointment } = useAppointment(null);
  const { appointmentMessage } = useAppointmentMessage("", appointment);

  let contentText = !!entry?.private
    ? "Bus panaikintas kalendoriaus įrašas"
    : "Bus panaikintas kalendoriaus įrašas ir atšauktas apžiūros laikas";
  contentText += ` (${humanizeDate(entry?.calendar_date)})`;

  function handleXClose(event, reason) {
    if (!!reason && reason === "backdropClick") return;
    history.replace(`/dashboard/my-calendar`);
    setOpen(false);
  }

  function handleOkClose() {
    history.replace(`/dashboard/my-calendar`);
    crudCalendarEntry(dispatchDashboard, entry, "delete");
    if (!entry.private && !!entry.ad && !!entry.enquirer) {
      let appointmentMethod = appointment.appointment_status.includes("confirm")
        ? "delete"
        : "patch";
      crudAppointment(
        dispatchDashboard,
        appointment,
        appointmentMethod,
        appointmentMessage
      );
      // if (new Date(entry.calendar_date) >= today) {
      //   listCreateEnquiryMessage(
      //     dispatchDashboard,
      //     "post",
      //     null,
      //     appointmentMessage
      //   );
      // }
    }
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleXClose} maxWidth="xs" fullWidth>
      <DialogTitle>Panaikinti kalendoriaus įrašą?</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleXClose} color="error" variant="outlined">
          Ne
        </Button>
        <Button
          onClick={handleOkClose}
          color="error"
          variant="contained"
          autoFocus
        >
          Taip
        </Button>
      </DialogActions>
    </Dialog>
  );
}
