import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useGlobalContext } from "../../../context";
import { Container, Stack } from "@mui/material";
import { registerCookieConsent } from "./fetchHeaderData";
import { Link } from "react-router-dom";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

const CookieConsentDrawer = () => {
  const [open, setOpen] = useState(false);
  const { userState, dispatchUser } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 500); // delay in milliseconds

    return () => clearTimeout(timer); // cleanup on unmount
  }, []);

  const handleClose = () => {
    let form = {
      visitor: userState?.visitor_id,
      required: true,
      functional: true,
      statistical: true,
      marketing: true,
    };
    registerCookieConsent(form, dispatchUser);
    setOpen(false);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="persistent"
      // ModalProps={{
      //   disableBackdropClick: true,
      // }}
    >
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          p: 2,
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          // // height: 200, // adjust this to change the height of the drawer
          // border: "groove",
          // // maxWidth: 800,
          // width: "100%",
          // m: "auto",
        }}
      >
        <Typography
          variant="h6"
          textAlign="left"
          sx={{ border: "none", width: "100%", mb: 1 }}
        >
          Mes naudojame slapukus
        </Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          spacing={3}
        >
          <Typography variant="body2">
            Siekdami užtikrinti geriausą vartotojų patirtį mūsų svetainėje, mes
            naudojame (kelis) slapukus. Naudodamiesi šia svetaine, sutinkate su
            slapukų naudojimu, svetainės{" "}
            <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
              Privatumo politika
            </Link>{" "}
            ir{" "}
            <Link to="/terms-of-use" style={{ textDecoration: "none" }}>
              Naudojimosi taisyklėmis
            </Link>
            .
          </Typography>

          <Button onClick={handleClose} variant="contained">
            Sutinku
          </Button>
        </Stack>
      </Container>
    </Drawer>
  );
};

export default CookieConsentDrawer;
