import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import { useGlobalContext } from "../../../../context";
import { crudSavedSearch } from "../../../Dashboard/fetchOrSendDashboardData";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
// import FormPreview from "../../../AdForm/utils/FormPreview";
import { Grid, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import getUrlFromParams from "../../../../utils/getUrlFromParams";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useBackHandler from "../../../../hooks/useBackHandler";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function SaveSearchDialog({ open, setOpen }) {
  const { userState, dispatchUser, dispatchDashboard, dashboardState } =
    useGlobalContext();
  const params = useParamsFromUrl();
  const { isMobile } = useCustomMediaQuery();
  const [duplicate, setDuplicate] = useState(false);
  useBackHandler(handleClose);
  const history = useHistory();

  const initialValues = { name: "" };
  const validationSchema = Yup.object({
    name: Yup.string().required("Privalomas").max(100, "Maks. 100 simbolių"),
  });

  function handleSubmit(values) {
    delete params.page;
    params.ordering = "-list_date";
    values.filter_params = JSON.stringify(params);
    values.user = userState.userId;
    values.id_to_overwrite = duplicate?.id || "";
    const urlObj = getUrlFromParams(params);
    // values.url = window.location.origin + urlObj.pathname + urlObj.search;
    values.url = urlObj.pathname + urlObj.search;
    values.notify = true;
    crudSavedSearch(dispatchDashboard, "post", values);
    setOpen(false);
    const snackbar = userState.snackbar;
    snackbar.open = true;
    snackbar.message = "Paieška išsaugota";
    snackbar.severity = "success";
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { snackbar },
    });
  }

  function handleInputChange(value) {
    const duplicate = dashboardState.mySearches.find(
      (search) =>
        search.name.toLowerCase().trim() === value.toLowerCase().trim()
    );
    setDuplicate(duplicate);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        <Form autoComplete="off" noValidate>
          <DialogTitle id="form-dialog-title">
            Išsaugokite paieškos nustatymus
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Išsaugotą paiešką galėsite pakartoti vienu paspaudimu.
              <br /> Taip pat galėsite gauti pranešimus apie naujus skelbimus.
            </DialogContentText>
            <FormikTextField
              name="name"
              label="Paieškos pavadinimas"
              autoFocus
              sx={{ mt: 3 }}
              onChange={handleInputChange}
            />
            {!!duplicate && (
              <Grid container alignItems="center" sx={{ mt: 3 }}>
                <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Paieška tokiu pavadinimu jau egzistuoja. <br /> Išsaugojus
                  naują paiešką, senoji paieška bus ištrinta.
                </Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions sx={{ mx: 2 }}>
            <Button
              onClick={() => {
                history.goBack();
                handleClose();
              }}
              color="primary"
              variant="outlined"
            >
              Atšaukti
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Išsaugoti
            </Button>
          </DialogActions>
          {/* <FormPreview /> */}
        </Form>
      </Formik>
    </Dialog>
  );
}
