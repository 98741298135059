import React from "react";
import { Typography, List, ListItem } from "@mui/material";
import { useGlobalContext } from "../../../context";
import useDescription from "./useDescription";
import { Link } from "react-router-dom";

const AccordionDetailApartmentComplex = ({ ad }) => {
  const { state } = useGlobalContext();
  const { complex } = useDescription(ad);

  const flats =
    state?.flatOptions
      ?.filter((ad) => ad.ad_type === "flat_sale")
      .map((ad) => ad.secondary_label) || [];

  const premises =
    state.flatOptions
      ?.filter((ad) => ad.ad_type === "premises_sale")
      .map((ad) => ad.secondary_label) || [];

  const parking =
    state.flatOptions
      ?.filter((ad) => ad.ad_type === "parking_sale")
      .map((ad) => ad.secondary_label) || [];

  const garages =
    state.flatOptions
      ?.filter((ad) => ad.ad_type === "garage_sale")
      .map((ad) => ad.secondary_label) || [];

  // console.log("flats from accordion", flats);

  return (
    complex && (
      <>
        <Typography variant="subtitle2">Pastatas </Typography>
        <List dense>
          {complex.apartmentComplexDescription}
          {complex.completionDate}
          {complex.buildingType}
          {complex.heating}
          {complex.energyEfficiency}
          {/* {complex.heatingBill} */}
        </List>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Butai
        </Typography>
        <List dense>
          {complex.roomsRange}
          {complex.areaRange}
          {complex.priceRange}
          {complex.installationComplex}
        </List>

        <List dense>
          {flats?.map((ad) => {
            let adId = ad.split(",")[0].split(" ")[1];
            // let link = `ad/${adId}`;

            return (
              <ListItem>
                <Link to={adId} style={{ textDecoration: "none" }}>
                  <Typography variant="body2" color="primary">
                    {ad}
                  </Typography>
                </Link>
              </ListItem>
            );
          })}
        </List>

        {!!premises.length && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Patalpos
            </Typography>

            <List dense>
              {premises.map((ad) => {
                let adId = ad.split(",")[0].split(" ")[1];

                return (
                  <ListItem>
                    <Link to={adId} style={{ textDecoration: "none" }}>
                      <Typography variant="body2" color="primary">
                        {ad}
                      </Typography>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}

        {!!parking.length && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Parkavimo vietos
            </Typography>

            <List dense>
              {parking.map((ad) => {
                let adId = ad.split(",")[0].split(" ")[1];

                return (
                  <ListItem>
                    <Link to={adId} style={{ textDecoration: "none" }}>
                      <Typography variant="body2" color="primary">
                        {ad}
                      </Typography>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}

        {!!garages.length && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Garažai
            </Typography>

            <List dense>
              {garages.map((ad) => {
                let adId = ad.split(",")[0].split(" ")[1];

                return (
                  <ListItem>
                    <Link to={adId} style={{ textDecoration: "none" }}>
                      <Typography variant="body2" color="primary">
                        {ad}
                      </Typography>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </>
    )
  );
};

export default AccordionDetailApartmentComplex;
