import React, { useState, useEffect, useRef, useMemo } from "react";
import { IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useGlobalContext } from "../../../context";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { blue } from "@mui/material/colors";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
// import useChatSocket from "../hooks/useChatSocket";
import { listCreateEnquiryMessage } from "../fetchOrSendDashboardData";

export default function MessageBar() {
  const { userState, dashboardState, dispatchDashboard } = useGlobalContext();
  let { adId, enquirerId } = useParams();
  const isEnquirer = Number(enquirerId) === userState.userId;
  const { isMobile } = useCustomMediaQuery();

  const adContact = useMemo(
    () =>
      dashboardState.adContacts
        .filter((contact) => contact.owner_active && contact.enquirer_active)
        .find(
          (contact) => contact.ad === +adId && contact.enquirer === +enquirerId
        ),
    [dashboardState.adContacts, adId, enquirerId]
  );

  const messageBoxRef = useRef(null);

  const [formValue, setFormValue] = useState("");
  const [triggerOnEnter, setTriggerOnEnter] = useState(false);

  // const { chatSocket } = useChatSocket();

  // Enable sending message by pressing Enter
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        setTriggerOnEnter(true);
      }
    };
    document.addEventListener("keyup", listener);
    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, []);

  useEffect(() => {
    if (!!triggerOnEnter) {
      handleMessageSend();
    }
    setTriggerOnEnter(false);
  }, [triggerOnEnter]);

  function handleMessageSend() {
    if (!!formValue.trim()) {
      let messageObj = {
        ad: +adId,
        enquirer: +enquirerId,
        message: formValue.trim(),
        name: adContact.name,
        phone: adContact.phone,
        email: adContact.email,
        reply: !isEnquirer,
      };
      listCreateEnquiryMessage(dispatchDashboard, "post", null, messageObj);
      // chatSocket?.send(
      //   JSON.stringify({
      //     type: "chat_message",
      //     ad: adId,
      //     enquirer: enquirerId,
      //     message: formValue,
      //     reply: !isEnquirer,
      //   })
      // );
    }
    setFormValue("");
  }

  // console.log("adContact from MessageBar", adContact);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: blue[100],
      }}
    >
      <TextField
        ref={messageBoxRef}
        placeholder={
          !!adContact?.enquirer_suspended
            ? "Negalite siųsti žinučių..."
            : "Rašykite žinutę..."
        }
        fullWidth
        multiline
        maxRows={2}
        variant="outlined"
        value={formValue}
        size="small"
        onChange={(e) => setFormValue(e.target.value)}
        sx={{
          my: 1,
          ml: isMobile ? 7.25 : 8.25,
          borderRadius: "4px",
          backgroundColor: "white",
          "& .MuiInputBase-input": {
            fontSize: "14px", // Set the font size of the input text
          },
        }}
        disabled={
          !adContact ||
          !!adContact.enquirer_suspended ||
          ["expired", "deleted", "blocked"].includes(adContact.ad_status)
        }
      />
      <Box
        sx={{
          minWidth: isMobile ? "58px" : "66px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton
          // type="submit"
          color="primary"
          disabled={
            !formValue.trim() ||
            !adContact ||
            !!adContact.enquirer_suspended ||
            ["expired", "deleted", "blocked"].includes(adContact.ad_status)
          }
          onClick={handleMessageSend}
          size="large"
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
