import React, { useEffect, useRef, useMemo, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { indigo } from "@mui/material/colors";
import { listCreateEnquiryMessage } from "../fetchOrSendDashboardData";
import useClearMessageNotifications from "../../Layout/Header/Notification/MessageNotification/useClearMessageNotifications";
import MessageBubble from "./MessageBubble";

export default function MessageBoard() {
  const { dashboardState, dispatchDashboard, userState } = useGlobalContext();
  useClearMessageNotifications();
  let { adId, enquirerId } = useParams();
  const [scrollToTop, setScrollToTop] = useState(false);
  const endRef = useRef(null);
  const messageBoxRef = useRef(null);
  const adContact = dashboardState.adContacts.find(
    (contact) =>
      contact.enquirer === Number(enquirerId) && contact.ad === Number(adId)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const orderedMessages = useMemo(getOrderedMessages, [
    dashboardState?.enquiriesLoaded,
    dashboardState?.enquiries,
    dashboardState?.enquiries?.length,
    adId,
    enquirerId,
  ]);

  //   scroll last message of open chat into view
  useEffect(() => {
    if (!scrollToTop && !!endRef?.current) {
      endRef.current.scrollIntoView({
        // behavior: "smooth",
        // behavior: "auto",
        block: "nearest",
        inline: "nearest",
      });
    }
    if (!scrollToTop && !!messageBoxRef?.current) {
      messageBoxRef.current.scrollIntoView({
        // behavior: "smooth",
        // behavior: "auto",
        block: "nearest",
        inline: "nearest",
      });
    }
    if (scrollToTop) {
      setScrollToTop(false);
    }
  }, [orderedMessages, endRef, messageBoxRef]);

  function getOrderedMessages() {
    let orderedMessages = dashboardState?.enquiries || [];
    if (!!orderedMessages?.length) {
      orderedMessages = orderedMessages
        .filter(
          (message) =>
            Number(message.ad) === Number(adId) &&
            Number(message.enquirer) === Number(enquirerId)
        )
        .sort((a, b) => a.contact_date.localeCompare(b.contact_date));
    }
    return orderedMessages;
  }

  function showPreviousMessages() {
    let params = {
      ad_id: Number(adId),
      enquirer_id: Number(enquirerId),
      first_shown_message_date: orderedMessages[0].contact_date,
    };
    listCreateEnquiryMessage(dispatchDashboard, "get", params);
    setScrollToTop(true);
  }

  // console.log("orderedMessages from MessageBoard", orderedMessages, adContact);
  // console.log("adContact from MessageBoard", adContact);

  return (
    !!orderedMessages.length && (
      <Box sx={{ borderBottomRightRadius: "3px" }}>
        <Box
          // item
          sx={{
            height: "250px",
            width: "100%",
            overflowY: "auto",
            border: "none",
            py: 1,
            backgroundColor: indigo[50],
          }}
        >
          {Date.parse(orderedMessages[0]?.contact_date) >
            Date.parse(adContact?.contact_date) && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                size="small"
                onClick={showPreviousMessages}
              >
                <Typography variant="body2">Ankstesnės žinutės</Typography>
              </Button>
            </Box>
          )}
          {orderedMessages.map((m, index) => {
            // console.log("message from MessageBoard", m);
            const isEnquirer = m.enquirer === userState.userId;
            const myMessage = isEnquirer ? !m.reply : m.reply;
            return (
              <Box key={index}>
                <MessageBubble
                  m={m}
                  myMessage={myMessage}
                  adContact={adContact}
                />
              </Box>
            );
          })}
          <div ref={endRef} />
        </Box>
      </Box>
    )
  );
}
