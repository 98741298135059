import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import ListboxComponent from "../../utils/ListboxComponent";
import { useEffect, useState } from "react";

export default function FormikMultipleAutocomplete({
  name,
  label,
  options,
  onChange,
  initiallyClosed,
  filterOptions,
  ...props
}) {
  const [field, meta] = useField(name);
  const formik = useFormikContext();
  const errorText = meta.touched && meta.error;

  const [openOptions, setOpenOptions] = useState(!initiallyClosed);
  const [inputValue, setInputValue] = useState("");

  const [values, setValues] = useState(
    !!field.value
      ? options?.filter((option) =>
          field.value.split(",").includes(option.id.toString())
        )
      : []
  );

  useEffect(() => {
    setValues(
      !!field.value
        ? options?.filter((option) =>
            field.value.split(",").includes(option.id.toString())
          )
        : []
    );
  }, [field.value, options]);

  function handleChange(e, values) {
    setValues(values);
    const newValue = values.map((value) => value.id.toString()).toString();
    field.onChange({
      target: {
        name: field.name,
        value: newValue,
      },
    });
    if (onChange) {
      onChange(newValue);
    }
    setOpenOptions(false);
  }

  function handleInputChange(e, value) {
    setInputValue(value);
    setOpenOptions(true);
  }

  // console.log(
  //   "formikMultipleAutocomplete options, filterOptions",
  //   options,
  //   filterOptions
  // );

  return (
    <Autocomplete
      freeSolo={!openOptions || !options.length}
      open={openOptions}
      multiple
      openOnFocus
      autoHighlight
      disableListWrap
      value={values}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      PopperComponent={"bottom-center"}
      ListboxProps={{ sx: { maxHeight: "10rem" } }}
      ListboxComponent={ListboxComponent}
      options={options}
      filterOptions={filterOptions}
      noOptionsText={"Nėra variantų"}
      onBlur={formik.onBlur}
      // clearOnBlur
      // includeInputInList={false}
      // fullWidth
      // blurOnSelect
      clearIcon={null}
      // onHighlightChange={(event, option) => {
      //   console.log("onHighlightChange option", option);
      // }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, state) => {
        return (
          <Box component="li" {...props}>
            <Checkbox size="small" checked={state.selected} />
            <Typography fontSize="medium">{option.name}</Typography>
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            autoFocus
            error={!!errorText}
            helperText={errorText}
            name={name}
            {...params}
            label={label}
            margin="dense"
            onClick={() => setOpenOptions(!openOptions)}
            // onKeyDown={(event) => {
            //   if (event.key === "Enter" && !params.inputProps.value) {
            //     // alert("focus");
            //     props.okButtonRef.current.focus();
            //   }
            // }}
            {...props}
          />
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              variant="outlined"
              key={option?.name}
              label={option?.name}
              color="primary"
              sx={{
                width: "auto",
                mr: "3px",
                borderRadius: "3px",
              }}
            />
          );
        })
      }
      {...props}
    />
  );
}
