import React from "react";
import { Drawer, Menu } from "@mui/material";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

export default function DrawerOrMenu({ anchorEl, setAnchorEl, children }) {
  const { isMobile } = useCustomMediaQuery();
  return (
    <>
      {isMobile ? (
        <Drawer
          anchor="right"
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: { width: "250px" },
          }}
        >
          {children}
        </Drawer>
      ) : (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          variant="menu"
          // getContentAnchorEl={null}
          keepMounted
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {children}
        </Menu>
      )}
    </>
  );
}
