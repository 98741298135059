import React, { useEffect } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useGlobalContext } from "../../context";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { loginUser } from "./fetchOrSendAccountsData";
// import FormPreview from "../AdForm/utils/FormPreview";
import FormikTextField from "../SharedComponents/FormikTextField";
import { Box, Divider, Link, Stack } from "@mui/material";
import FormikCheckbox from "../SharedComponents/FormikCheckBox";
import FormikPasswordField from "../SharedComponents/FormikPasswordField";

import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import { GoogleOAuthProvider } from "@react-oauth/google";

const initialValues = Object.freeze({
  email: "",
  password: "",
  remember: true,
});

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Turi būti tinkamas el. pašto adresas")
    .required("Privalomas laukas"),
  password: Yup.string().required("Privalomas laukas"),
  remember: Yup.boolean(),
});

export default function Login({ pushTo }) {
  // const history = useHistory();
  const location = useLocation();
  const {
    userState,
    dispatch,
    dispatchUser,
    dispatchDashboard,
    // routerState,
    dispatchRouter,
  } = useGlobalContext();

  async function handleSubmit(values, formikHelpers) {
    try {
      await loginUser(values, dispatch, dispatchUser, dispatchDashboard);
    } catch (error) {
      if (error?.response?.status === 401) {
        formikHelpers.setErrors({
          password: "Neteisingas slaptažodis (arba el. paštas)",
        });
      }
    }
  }

  useEffect(() => {
    // console.log("routerState from Login component", routerState);
    if (location.pathname === "/login") {
      dispatchRouter({
        type: "CHANGE_ROUTER_STATE",
        payload: { from: "login" },
      });
    }
  }, []);

  function handleEmailChange(value, formik) {
    formik.setFieldValue("email", value.toLowerCase());
  }

  // console.log("render Login");

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form noValidate sx={{ width: "100%" }}>
          <Stack justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Avatar sx={{ width: 70, height: 70 }} />
            <Typography variant="h6" color="grey">
              Prisijunkite
            </Typography>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                >
                  <GoogleLoginButton />
                </GoogleOAuthProvider>
              </Grid>
              <Grid item xs={12}>
                <FacebookLoginButton />
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", mt: 1 }}>
              <Divider sx={{ my: 2 }}>
                <Typography variant="subtitle2">
                  Arba junkitės tiesiogiai
                </Typography>
              </Divider>
            </Box>

            <Stack
              spacing={3}
              sx={{
                display: "flex",
                justifyContent: "left",
                my: 1,
                width: "100%",
              }}
            >
              <FormikTextField
                name="email"
                label="El. paštas *"
                onChange={(value) => handleEmailChange(value, formik)}
                size="small"
              />
              <FormikPasswordField
                name="password"
                label="Slaptažodis *"
                size="small"
              />
            </Stack>
            <Grid container sx={{ mb: 2, mt: -1 }}>
              <FormikCheckbox
                name="remember"
                label="Įsiminti mane"
                color="primary"
                sx={{ ml: 1 }}
              />
            </Grid>

            <Button type="submit" fullWidth variant="contained" color="primary">
              Prisijungti
            </Button>
            <Grid container sx={{ mt: 1 }}>
              <Link
                component={RouterLink}
                to={"/password-reset"}
                variant="body2"
                onClick={() =>
                  !!userState.openLoginDialog &&
                  dispatchUser({
                    type: "CHANGE_USER_STATE",
                    payload: { openLoginDialog: false },
                  })
                }
              >
                Pamiršau slaptažodį
              </Link>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Typography variant="subtitle2">Neturite paskyros?</Typography>
              </Grid>
              <Grid item>
                <Link
                  component={RouterLink}
                  to={"/register"}
                  variant="subtitle2"
                  onClick={() =>
                    dispatchUser({
                      type: "CHANGE_USER_STATE",
                      payload: { openLoginDialog: false },
                    })
                  }
                >
                  Susikurkite čia
                </Link>
              </Grid>
            </Grid>
          </Stack>
          {/* <FormPreview /> */}
        </Form>
      )}
    </Formik>
  );
}
