import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import CustomAvatar from "../../CustomAvatar";
import { useGlobalContext } from "../../../../context";
import axiosInstance from "../../../../utils/axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import DrawerOrMenu from "./DrawerOrMenu";

export function removeTokens() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  document.cookie =
    "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  axiosInstance.defaults.headers["Authorization"] = null;
}

export function logOutUser(
  dispatchUser,
  dispatch,
  dispatchDashboard,
  dispatchAdForm,
  dispatchRouter,
  dispatchNotification,
  history
) {
  sessionStorage.removeItem("userState");
  dispatchUser({ type: "LOGOUT_USER" });
  dispatch({ type: "LOGOUT_USER" });
  dispatchDashboard({ type: "LOGOUT_USER" });
  dispatchAdForm({ type: "LOGOUT_USER" });
  dispatchRouter({ type: "LOGOUT_USER" });
  dispatchNotification({ type: "LOGOUT_USER" });
  history.push("/");
}

export default function UserMenu() {
  const {
    dispatch,
    dispatchUser,
    dispatchDashboard,
    dispatchAdForm,
    dispatchRouter,
    dispatchNotification,
    routerState,
  } = useGlobalContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  // log off of all tabs (except the active one) if login_at is changed
  window.onstorage = function (e) {
    // console.log("listener called", e.key, e.oldValue, e.newValue);
    const isAuthenticated = sessionStorage.getItem("authenticated");
    if (
      e.key === "login_at" &&
      e.newValue !== e.oldValue &&
      isAuthenticated === "true"
    ) {
      logOutUser(
        dispatchUser,
        dispatch,
        dispatchDashboard,
        dispatchAdForm,
        dispatchRouter,
        dispatchNotification,
        history
      );
    }
  };

  // // Logout user from any tab if access_token is removed from localStorage
  // useEffect(() => {
  //   function handleStorageChange(e) {
  //     if (e.key === "refresh_token" && e.oldValue !== e.newValue) {
  //       alert("refresh_token changed");
  //       logOutUser();
  //     }
  //   }
  //   window.addEventListener("storage", handleStorageChange);
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  function handleLogoutButtonClick() {
    removeTokens();
    logOutUser(
      dispatchUser,
      dispatch,
      dispatchDashboard,
      dispatchAdForm,
      dispatchRouter,
      dispatchNotification,
      history
    );
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
      >
        <CustomAvatar />
      </IconButton>
      <DrawerOrMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "userProfile" },
            });
            history.push("/user-profile");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Vartotojo profilis
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "createAd" },
            });
            history.push("/create-ad");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <PostAddIcon fontSize="small" />
          </ListItemIcon>
          Įkelti skelbimą
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push(
              !!routerState.dashboardUrlString
                ? routerState.dashboardUrlString
                : "/dashboard/my-favorites"
            );
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon fontSize="small" />
          </ListItemIcon>
          Vartotojo sritis
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push("/dashboard/my-favorites");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2" sx={{ ml: 5 }}>
            Įsiminti skelbimai
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push("/dashboard/my-searches");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2" sx={{ ml: 5 }}>
            Įsimintos paieškos
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push("/dashboard/my-ads");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2" sx={{ ml: 5 }}>
            Mano skelbimai
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push("/dashboard/my-enquiries");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2" sx={{ ml: 5 }}>
            Mano užklausos
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push("/dashboard/my-calendar");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2" sx={{ ml: 5 }}>
            Apžiūrų kalendorius
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatchRouter({
              type: "CHANGE_ROUTER_STATE",
              payload: { from: "dashboard" },
            });
            history.push("/dashboard/my-orders");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2" sx={{ ml: 5 }}>
            Mano užsakymai
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogoutButtonClick}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Atsijungti
        </MenuItem>
      </DrawerOrMenu>
    </>
  );
}
