import React, { useEffect, useState } from "react";
import { AvatarGroup, Button, IconButton, Typography } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import { Box } from "@mui/system";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAvatar from "../../../Layout/CustomAvatar";

import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

export default function MessageController({ ad }) {
  const { notificationState, routerState, dashboardState } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  let history = useHistory();

  const [expandAd, setExpandAd] = useState(
    Number(routerState?.myAdsAd) === Number(ad?.id)
  );
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [newUniqueSenders, setNewUniqueSenders] = useState([]);

  // initialize expandAd variable
  useEffect(() => {
    if (Number(routerState?.myAdsAd) === Number(ad?.id)) {
      setExpandAd(true);
    } else {
      setExpandAd(false);
    }
  }, [routerState?.myAdsAd, ad?.id]);

  // count new messages to display the number in ad card
  useEffect(() => {
    if (!!ad?.id) {
      setNewMessageCount(
        notificationState.notifications.filter(
          (notification) =>
            notification.type === "chat_message" &&
            notification.enquiry.ad === ad.id
        ).length
      );
      let senderIds = new Set(
        notificationState.notifications.map(
          (notification) => notification.sender
        )
      );
      senderIds = [...senderIds];
      let senders = senderIds.map((sender) =>
        dashboardState.adContacts.find((contact) => contact.enquirer === sender)
      );
      setNewUniqueSenders(senders);
    }
  }, [notificationState.notifications.length, ad?.id]);

  // change dashboardState when user expands/collapses ad card
  function handleExpandClick() {
    expandAd
      ? history.replace(`/dashboard/my-ads`)
      : history.replace(`/dashboard/my-ads/${ad.id}`);
  }

  // console.log("newMessageCount", newMessageCount);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "none",
        mb: 0.5,
        justifyContent: "space-between",
        bottom: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",

          justifyContent: "left",
          alignItems: "center",
          border: "none",
        }}
      >
        <ChatIcon
          fontSize="small"
          color={newMessageCount ? "secondary" : "action"}
          sx={{ mr: 0.5 }}
        />
        <Typography
          variant="caption"
          color={newMessageCount ? "secondary" : "action"}
          sx={{
            fontWeight: newMessageCount ? "bold" : "none",
          }}
        >
          Naujos žinutės:
        </Typography>
        {!!newMessageCount ? (
          <AvatarGroup
            max={3}
            sx={{
              ml: 1,
              // mb: 1,
              ".MuiAvatarGroup-avatar": {
                width: "25px",
                height: "25px",
                fontSize: "14px",
              },
            }}
          >
            {newUniqueSenders.map((sender, index) => (
              <IconButton
                key={index}
                onClick={() =>
                  history.replace(
                    `/dashboard/my-ads/${ad.id}/${sender?.enquirer}/chat`
                  )
                }
              >
                <CustomAvatar
                  userId={sender?.enquirer}
                  name={sender?.name}
                  styleProps={{
                    width: "25px",
                    height: "25px",
                    fontSize: "14px",
                  }}
                  key={index}
                />
              </IconButton>
            ))}
          </AvatarGroup>
        ) : (
          <Typography variant="caption" sx={{ ml: 1 }}>
            0
          </Typography>
        )}
      </Box>
      <Button
        onClick={handleExpandClick}
        size="small"
        variant="outlined"
        startIcon={<ChatIcon color={expandAd ? "primary" : "action"} />}
        endIcon={
          !isMobile &&
          (expandAd ? (
            <ExpandLessIcon color="primary" />
          ) : (
            <ExpandMoreIcon color="action" />
          ))
        }
        sx={{
          m: 0.5,
          borderColor: expandAd ? "primary" : "lightgray",
          width: "120px",
        }}
      >
        <Typography
          variant="caption"
          color={expandAd ? "primary" : "InfoText"}
          // sx={{ mx: 1 }}
        >
          Žinutės
        </Typography>
      </Button>
    </Box>
  );
}
