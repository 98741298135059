import { getArray, getValue } from "./utils";

export const getLocationInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  municipality: ad.properties.municipality?.id || "",
  town: ad.properties.town?.id || "",
  suburb: ad.properties.suburb?.id || "",
  // location: -ad.properties.suburb?.id || ad.properties.town?.id || null,
  street: ad.properties.street?.id || "",
  house: ad.properties.house || "",
  // show_house: ad.properties.show_house,
  show_house: true,
  flat: ad.properties.flat || "",
  // show_flat: ad.properties.show_flat,
  show_flat: true,
  loc_x: ad.properties?.loc_x || "",
  loc_y: ad.properties?.loc_y || "",
  is_exact:
    ad.properties.is_exact === true
      ? true
      : ad.properties.is_exact === false
      ? false
      : "unknown",
});

export const getBuildingInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  construction_year: ad.properties.construction_year,
  building_type: getValue(
    ad.properties.building_type,
    adOptions["BUILDING_TYPE_CHOICES"]
  ),
  floor: ad.properties.floor,
  floors: ad.properties.floors,
  energy_efficiency: getValue(
    ad.properties.energy_efficiency,
    adOptions["ENERGY_EFFICIENCY_CHOICES"]
  ),
  heating: getArray(ad.properties.heating, adOptions["HEATING_CHOICES"]),
  heating_bill: ad.properties.heating_bill,
  renovation: ad.properties.renovation,
  renovation_year: ad.properties.renovation_year,
  renovation_paid: ad.properties.renovation_paid,
  building_renovated: getArray(
    ad.properties.building_renovated,
    adOptions["HOUSE_RENOVATED_CHOICES"]
  ),
});

export const getBuildingHouseInitialValues = (ad, adOptions) => ({
  house_type: getValue(
    ad.properties.house_type,
    adOptions["HOUSE_TYPE_CHOICES"]
  ),
  building_type: getValue(
    ad.properties.building_type,
    adOptions["BUILDING_TYPE_CHOICES"]
  ),
  rooms: ad.properties.rooms,
  area: ad.properties.area,
  construction_year: ad.properties.construction_year,
  floors: ad.properties.floors,
  energy_efficiency: getValue(
    ad.properties.energy_efficiency,
    adOptions["ENERGY_EFFICIENCY_CHOICES"]
  ),
  heating: getArray(ad.properties.heating, adOptions["HEATING_CHOICES"]),
  heating_bill: ad.properties.heating_bill,
  renovation: ad.properties.renovation,
  renovation_year: ad.properties.renovation_year,
  // renovation_paid: ad.properties.renovation_paid,
  building_renovated: getArray(
    ad.properties.building_renovated,
    adOptions["BUILDING_RENOVATED_CHOICES"]
  ),
});

export const getBuildingApartmentComplexInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  company_name: ad.properties.company_name,
  project_name: ad.properties.project_name,
  number_of_buildings: ad.properties.number_of_buildings,
  floors: ad.properties.floors,
  floors_from: ad.properties.floors_from,
  floors_to: ad.properties.floors_to,
  construction_year: ad.properties.construction_year,
  quarter: getValue(ad.properties.quarter, adOptions["QUARTER_CHOICES"]),
  building_type: getValue(
    ad.properties.building_type,
    adOptions["BUILDING_TYPE_CHOICES"]
  ),
  energy_efficiency: getValue(
    ad.properties.energy_efficiency,
    adOptions["ENERGY_EFFICIENCY_CHOICES"]
  ),
  heating: getArray(ad.properties.heating, adOptions["HEATING_CHOICES"]),
  heating_bill: ad.properties.heating_bill,
});

export const getFlatInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  floors: ad.properties.floors,
  rooms: ad.properties.rooms,
  area: ad.properties.area,
  floor: ad.properties.floor,
  flats_in_floor: ad.properties.flats_in_floor,
  installation: getValue(
    ad.properties.installation,
    adOptions["INSTALLATION_CHOICES"]
  ),
  condition: getValue(ad.properties.condition, adOptions["CONDITION_CHOICES"]),
  flat_renovated: getArray(
    ad.properties.flat_renovated,
    adOptions["FLAT_RENOVATED_CHOICES"]
  ),
  equipment: getArray(ad.properties.equipment, adOptions["EQUIPMENT_CHOICES"]),
  premises: getArray(ad.properties.premises, adOptions["PREMISES_CHOICES"]),
  other_features: getArray(
    ad.properties.other_features,
    adOptions["OTHER_FEATURES_CHOICES"]
  ),
  partial_amenities: getArray(
    ad.properties.partial_amenities,
    adOptions["PARTIAL_AMENITIES_CHOICES"]
  ),
});

export const getFlatApartmentComplexInitialValues = (
  ad,
  adOptions,
  flatOptions
) => {
  return {
    ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
    flats: ad.properties.flats,
    rooms_from: ad.properties.rooms_from,
    rooms_to: ad.properties.rooms_to,
    area_from: ad.properties.area_from,
    area_to: ad.properties.area_to,
    price_from: ad.properties.price_from,
    price_to: ad.properties.price_to,
    price_sqm_from: ad.properties.price_sqm_from,
    price_sqm_to: ad.properties.price_sqm_to,
    installation: getValue(
      ad.properties.installation,
      adOptions["INSTALLATION_CHOICES_APARTMENT_COMPLEX"]
    ),
    installation_price_sqm: ad.properties.installation_price_sqm,
    premises_in_complex: ad.properties.premises_in_complex,
    parking_in_complex: ad.properties.parking_in_complex,
  };
};

export const getPremisesInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  floors: ad.properties.floors,
  premises_purpose: getArray(
    ad.properties.premises_purpose,
    adOptions["PREMISES_PURPOSE_CHOICES"]
  ),
  number_of_premises: getValue(
    ad.properties.number_of_premises,
    adOptions["NUMBER_OF_PREMISES_CHOICES"]
  ),
  area: ad.properties.area,
  area_from: ad.properties.area_from,
  area_to: ad.properties.area_to,
  // floor: ad.properties.floor,
  floor_from: ad.properties.floor_from,
  floor_to: ad.properties.floor_to,
  price: ad.properties.price,
  price_from: ad.properties.price_from,
  price_to: ad.properties.price_to,
  min_rent_period: ad.properties.min_rent_period,
  installation: getValue(
    ad.properties.installation,
    adOptions["INSTALLATION_CHOICES"]
  ),
  condition: getValue(ad.properties.condition, adOptions["CONDITION_CHOICES"]),
  equipment: getArray(
    ad.properties.equipment,
    adOptions["EQUIPMENT_CHOICES_PREMISES"]
  ),
  premises: getArray(
    ad.properties.premises,
    adOptions["PREMISES_CHOICES_PREMISES"]
  ),
  other_features: getArray(
    ad.properties.other_features,
    adOptions["OTHER_FEATURES_CHOICES_PREMISES"]
  ),
});

export const getEquipmentHouseInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  installation: getValue(
    ad.properties.installation,
    adOptions["INSTALLATION_CHOICES"]
  ),
  condition: getValue(ad.properties.condition, adOptions["CONDITION_CHOICES"]),
  flat_renovated: getArray(
    ad.properties.flat_renovated,
    adOptions["FLAT_RENOVATED_CHOICES"]
  ),
  equipment: getArray(ad.properties.equipment, adOptions["EQUIPMENT_CHOICES"]),
  premises: getArray(
    ad.properties.premises,
    adOptions["PREMISES_CHOICES_HOUSE"]
  ),
  // other_features: getArray(
  //   ad.properties.other_features,
  //   adOptions["OTHER_FEATURES_CHOICES"]
  // ),
  partial_amenities: getArray(
    ad.properties.partial_amenities,
    adOptions["PARTIAL_AMENITIES_CHOICES_HOUSE"]
  ),
});

export const getEnvironmentInitialValues = (ad, adOptions) => ({
  security: getArray(ad.properties.security, adOptions["SECURITY_CHOICES"]),
  parking: getArray(ad.properties.parking, adOptions["PARKING_CHOICES"]),
  recreation: getArray(
    ad.properties.recreation,
    adOptions["RECREATION_CHOICES"]
  ),
});

export const getEnvironmentHouseInitialValues = (ad, adOptions) => ({
  plot_area: ad.properties.plot_area,
  water_sewage: getArray(
    ad.properties.water_sewage,
    adOptions["WATER_SEWAGE_CHOICES"]
  ),
  plot_infrastructure: getArray(
    ad.properties.plot_infrastructure,
    adOptions["PLOT_INFRASTRUCTURE_CHOICES"]
  ),
  security: getArray(
    ad.properties.security,
    adOptions["SECURITY_CHOICES_HOUSE"]
  ),
  parking: getArray(ad.properties.parking, adOptions["PARKING_CHOICES_HOUSE"]),
  recreation: getArray(
    ad.properties.recreation,
    adOptions["RECREATION_CHOICES"]
  ),
  water_body: getArray(
    ad.properties.water_body,
    adOptions["WATER_BODY_CHOICES"]
  ),
});

export const getEnvironmentPremisesInitialValues = (ad, adOptions) => ({
  plot_area: ad.properties.plot_area,
  plot_infrastructure: getArray(
    ad.properties.plot_infrastructure,
    adOptions["INFRASTRUCTURE_CHOICES_PREMISES"]
  ),
  security: getArray(
    ad.properties.security,
    adOptions["SECURITY_CHOICES_PREMISES"]
  ),
  parking: getArray(
    ad.properties.parking,
    adOptions["PARKING_CHOICES_PREMISES"]
  ),
});

export const getEnvironmentLandInitialValues = (ad, adOptions) => {
  const initialValues = {
    plot_area: ad.properties.plot_area,
    plot_purpose: getValue(
      ad.properties.plot_purpose,
      adOptions["PLOT_PURPOSE_CHOICES"] || null
    ),
    forest_group: getArray(
      ad.properties.forest_group,
      adOptions["FOREST_GROUP_CHOICES"]
    ),
    water_body: getArray(
      ad.properties.water_body,
      adOptions["WATER_BODY_CHOICES"]
    ),
    forest_type: getValue(
      ad.properties.forest_type,
      adOptions["FOREST_TYPE_CHOICES"] || null
    ),
    forest_mature: getValue(
      ad.properties.forest_mature,
      adOptions["FOREST_MATURE_CHOICES"] || null
    ),
    is_logging_forest: ad.properties.is_logging_forest,

    water_sewage: getArray(
      ad.properties.water_sewage,
      adOptions["WATER_SEWAGE_CHOICES"]
    ),
    plot_infrastructure: getArray(
      ad.properties.plot_infrastructure,
      adOptions[
        ad.properties.plot_purpose === "Sodo sklypas"
          ? "INFRASTRUCTURE_CHOICES_GARDEN_PLOT"
          : "INFRASTRUCTURE_CHOICES_HOUSE_PLOT"
      ]
    ),
    recreation: getArray(
      ad.properties.recreation,
      adOptions["RECREATION_CHOICES"]
    ),
    fertility_score: ad.properties.fertility_score,
  };
  return initialValues;
};

export const getDescriptionInitialValues = (ad) => ({
  description: ad.properties.description,
});

export const getPriceInitialValues = (ad, adOptions) => ({
  price: ad.properties.price,
  sale_type: getArray(ad.properties.sale_type, adOptions["SALE_TYPE_CHOICES"]),
  hide_valuation: !!ad.properties.hide_valuation,
});

export const getPriceRentInitialValues = (ad, adOptions) => ({
  price: ad.properties.price,
  utils_included: ad.properties.utils_included,
  min_rent_period: ad.properties.min_rent_period,
  rent_type: getArray(ad.properties.rent_type, adOptions["RENT_TYPE_CHOICES"]),
  // short_term_rent_price: ad.properties.short_term_rent_price,
  hide_valuation: !!ad.properties.hide_valuation,
});

export const getPriceRentLandInitialValues = (ad, adOptions) => ({
  price: ad.properties.price,
  min_rent_period: ad.properties.min_rent_period,
  rent_type: getArray(
    ad.properties.rent_type,
    adOptions["RENT_TYPE_CHOICES_LAND"]
  ),
});

export const getOwnerDetailsInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  name: ad.properties.name,
  owner_type:
    getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]) ===
    "apartment_complex"
      ? "developer"
      : getValue(ad.properties.owner_type, adOptions["OWNER_TYPE_CHOICES"]),
  company_name: ad.properties.company_name,
  project_name: ad.properties.project_name,
  phone: ad.properties.phone,
  show_phone: true,
  // show_phone: ad.properties.show_phone,
  // email: ad.properties.email,
  // show_email:
  //   ad.properties.show_email === "" ? false : ad.properties.show_email,
  show_owner_photo:
    ad.properties.show_owner_photo === ""
      ? false
      : ad.properties.show_owner_photo,
  show_company_logo:
    ad.properties.show_company_logo === ""
      ? false
      : ad.properties.show_company_logo,
});

export const getGarageInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  garage_installation: getArray(
    ad.properties.garage_installation,
    adOptions["GARAGE_INSTALLATION_CHOICES"]
  ),
  garage_type: getValue(
    ad.properties.garage_type,
    adOptions["GARAGE_TYPE_CHOICES"]
  ),
  garage_floor: ad.properties.garage_floor,
  area: ad.properties.area,
  garage_size: ad.properties.garage_size,
  security: getArray(
    ad.properties.security,
    adOptions["SECURITY_CHOICES_GARAGE"]
  ),
  price: ad.properties.price,
  min_rent_period: ad.properties.min_rent_period,
});

export const getParkingInitialValues = (ad, adOptions) => ({
  ad_type: getValue(ad.properties.ad_type, adOptions["AD_TYPE_CHOICES"]),
  garage_installation: getArray(
    ad.properties.garage_installation,
    adOptions["PARKING_INSTALLATION_CHOICES"]
  ),
  garage_type: getValue(
    ad.properties.garage_type,
    adOptions["PARKING_TYPE_CHOICES"]
  ),
  garage_floor: ad.properties.garage_floor,
  area: ad.properties.area,
  garage_size: ad.properties.garage_size,
  security: getArray(
    ad.properties.security,
    adOptions["SECURITY_CHOICES_GARAGE"]
  ),
  price: ad.properties.price,
  min_rent_period: ad.properties.min_rent_period,
});
