import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { fetchPaymentPlans } from "../fetchOrSendAdDraftData";
import { useGlobalContext } from "../../../context";

function DiscountCoupon({ adId, formik, durationOption }) {
  const [couponCode, setCouponCode] = useState("");
  const [couponTried, setCouponTried] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const { dispatchAdForm, adFormState } = useGlobalContext();

  useEffect(() => {
    if (adFormState.paymentPlans[0]?.type.includes("coupon")) {
      setCouponApplied(true);
    } else {
      setCouponApplied(false);
    }
  }, [adFormState.paymentPlansLoading, couponTried]);

  useEffect(() => {
    if (!!durationOption) {
      const paymentPlan = adFormState.paymentPlans.find(
        (plan) => plan.option === durationOption
      );
      formik.setFieldValue("genericPlanId", paymentPlan.id);
      formik.setFieldValue("paymentSize", paymentPlan.price);
    }
  }, [adFormState.paymentPlans]);

  function handleCouponCodeChange(e) {
    setCouponCode(e.target.value);
    formik.setFieldValue("couponCode", e.target.value);
  }

  function handleApplyCoupon() {
    fetchPaymentPlans(dispatchAdForm, adId, couponCode.toLowerCase());
    setCouponTried(true);
  }

  return (
    <Grid container sx={{ mt: 2 }} alignItems="start" spacing={1}>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Nuolaidos kodas"
          variant="outlined"
          fullWidth
          size="small"
          value={couponCode}
          onChange={handleCouponCodeChange}
          helperText={
            couponTried && !adFormState.paymentPlansLoading && !couponApplied
              ? "Kodas netinkamas"
              : ""
          }
          disabled={couponApplied}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          disabled={
            !couponCode || couponApplied || adFormState.paymentPlansLoading
          }
          onClick={handleApplyCoupon}
        >
          Pritaikyti
        </Button>
      </Grid>
    </Grid>
  );
}

export default DiscountCoupon;
